import React, {useContext} from "react"
import StoryContext from "../../context/StoryContext"

const ProgressBar = () => {
	const {stories, getCurrentIndex, info} = useContext(StoryContext)
	return (
		<div className={"position-absolute top-0 start-0 end-0"} style={{
			height: "auto"
		}}>
			<div className={"gradient-black-transparent top-down"} style={{
				height: 100
			}}/>
			<div className={"row justify-content-between px-4 py-2 g-1"}>
				{stories.map((video, index) => {
					const currentIndex = getCurrentIndex()
					return <div key={index} className={"col"}>
						<div className={"position-relative bg-light"} style={{
							height: 2
						}}>
							<div
								className={"bg-primary position-absolute top-0 start-0 bottom-0"}
								style={{
									width: info && index === currentIndex ? `${(info.currentTime / info.duration) * 100}%` : index < currentIndex ? "100%" : 0
								}}/>
						</div>
					</div>
				})}
			</div>
		</div>
	)
}

ProgressBar.propTypes = {

}

export default ProgressBar
