import {idsParams} from "../utility/Helper"

const fetchBikesByIds = async (ids, locale = "de") => {
	try {
		const url = `${process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? "https://www.mybikeplan.ch" : process.env.NEXT_PUBLIC_ENVIRONMENT === "local" ? "http://localhost:3000" : "https://staging.mybikeplan.ch"}/api/products/bikes/${idsParams(ids)}&locale=${locale}`
		const bikes = await fetch(url)
		return bikes.json()
	} catch (e) {
		console.error(e)
		return []
	}
}

export default fetchBikesByIds
