import Typography from "../reusable/Typography/Typography"
import React from "react"
import {useTranslation} from "next-i18next"

type Props = {
  numberOfPeopleView: number
}

const NumberOfView: React.FC<Props> = ({numberOfPeopleView}) => {
	const {t} = useTranslation(["common", "secondHandBikes"])

	return (
		<div className=" col-12">
			<div style={{top: 25}} className="d-flex align-items-center z-index">
				<span>
					<img src={"/assets/icons/eye-opened.svg"} height="11.5"/>
				</span>
				<Typography variant="bodySmBold" semanticTag="span"
					className="ms-2" style={{color: "#6C7074"}}>{numberOfPeopleView}</Typography>
				<Typography variant="bodySm" semanticTag="span"
					className="ms-1"
					style={{color: "#6C7074"}}>{`${numberOfPeopleView > 1 ? t("people are viewing this product now") : t("person is viewing this product now")}`}</Typography>
			</div>
		</div>
	)
}

export default NumberOfView
