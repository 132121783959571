import React, {useEffect, useState} from "react"
import ReactSlider from "react-slider"
import PropTypes from "prop-types"
import Typography from "../reusable/Typography/Typography"

type Props = {
  min: number
  max: number
  step?: number
  double?: boolean
  defaultValue: number | number[]
  onChange?: (value: number) => void
  onAfterChange?: (value: number) => void
  hasPriceTooltip?: boolean
  paymentValueOne?: any
  paymentValueTwo?: any
	showTrackOnly?: boolean
  disabled?:boolean
}

const Slider: React.FC<Props> = ({
	min,
	max,
	step,
	double,
	defaultValue,
	onChange,
	onAfterChange,
	hasPriceTooltip = false,
	paymentValueOne,
	paymentValueTwo,
	showTrackOnly,
	disabled = false
}: Props) => {
	const [InitialValue, setInitialValue] = useState<number | number[]>(0)
	useEffect(() => {
		setInitialValue(defaultValue)
	}, [defaultValue])

	return (
		<> <div style={{
			height: 24
		}}><ReactSlider
				className="horizontal-slider"
				thumbClassName="slider-thumb cursor-pointer"
				trackClassName={`${double ? "double-" : ""}slider-track`}
				disabled={disabled}
				step={step}
				min={min}
				max={max}
				renderThumb={(props, state) => <div {...props}>{hasPriceTooltip && paymentValueOne && state.index === 0 ?
					<div className="thumb-tooltip px-3 d-inline-block d-flex align-items-center justify-content-center"
					>
						<Typography variant="bodySmBold" style={{minWidth: 70}}>CHF {paymentValueOne}</Typography>
					</div> : null}
				{hasPriceTooltip && paymentValueTwo && state.index === 1 ?
					<div className="thumb-tooltip d-flex align-items-center justify-content-center"><Typography
						variant="bodyXSm" style={{minWidth: 70}}>CHF {paymentValueTwo}</Typography></div> : null}</div>}
				onChange={onChange}
				onAfterChange={onAfterChange}
				value={defaultValue}
			/>	</div>
		</>
	)
}

Slider.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	step: PropTypes.number,
	double: PropTypes.bool,
	defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	onChange: PropTypes.func,
	onAfterChange: PropTypes.func
}

export default Slider
