import React from "react"
import Image from "next/image"
import Typography from "./Typography/Typography"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"

type Props = {
    text: string
}

const SuccessMessage: React.FC<Props> = ({text}) => {
	const {t} = useTranslation()
	return (
		<>
			<div className="d-none d-md-block">
				<section className="container my-5">
					<div className="col-12">
						<div className="row">
							<div className="col-auto">
								<Image src="/assets/icons/yellow-success-icon.svg" alt="Yellow success icon" width={50} height={50} ></Image>
							</div>
							<div className="col-8 mt-2">
								<Typography variant="heading2" semanticTag="h3" className="text-success" >
									{t("Nachricht gesendet!")}
								</Typography>
								<Typography variant="bodyLg" semanticTag="p" className="mt-4">
									{t(text)}
								</Typography>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div className="d-block d-md-none">
				<section className="container my-5">
					<div className="col-12">
						<div className="row">
							<div className="col-auto">
								<Image src="/assets/icons/yellow-success-icon.svg" alt="Yellow success icon" width={50} height={50} ></Image>
							</div>
							<div className="col-8 mt-2">
								<Typography variant="heading2" semanticTag="h3" className="text-success" >
									{t("Nachricht gesendet!")}
								</Typography>
							</div>
						</div>
					</div>
					<div className="col-12">
						<Typography variant="bodyLg" semanticTag="p" className="mt-4">
							{t(text)}
						</Typography>
					</div>
				</section>
			</div>
		</>
	)
}

SuccessMessage.propTypes = {
	text: PropTypes.string.isRequired
}

export default SuccessMessage
