import React from "react"
import Typography from "../reusable/Typography/Typography"

type Props = {
  title: String
  isActive: Boolean
  onClick: () => void
}

const AccordionHeaderWithPlusIcon = ({title, isActive, onClick}: Props): React.ReactElement => {
	return (
		<div onClick={onClick} style={{cursor: "pointer"}} className="row p-3">
			<i className={`bi ${isActive ? "bi-dash-lg" : "bi-plus-lg"} col-1 col-md-auto pt-1`}></i>
			<div className="col-11 overflow-hidden"><Typography variant="bodyLgBold" semanticTag="span">{title}</Typography>
			</div>
		</div>
	)
}

export default AccordionHeaderWithPlusIcon
