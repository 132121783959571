import React, {useContext, useState} from "react"
import Typography from "../reusable/Typography/Typography"
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik"
import {fetchPost} from "../../firebaseAdmin/fetchPost"
import {useTranslation} from "next-i18next"
import FirebaseContext from "../../context/FirebaseContext"
import Button from "../reusable/components/Button"
import * as Yup from "yup"

interface Values {
  email: string;
}

type props = {
  magicLinkSent: boolean | null;
  error: any;
  setError: React.Dispatch<any>;
  setMagicLinkSent
};

function FormPartnersLogin({
	magicLinkSent,
	error,
	setError,
	setMagicLinkSent
}: props) {
	const {t} = useTranslation()
	const {user} = useContext(FirebaseContext)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.email("Invalid email address")
			.required("Email is required"),

		optIn: Yup.boolean().oneOf([true], t("optin_required"))

	})

	return (
		<>
			{!magicLinkSent && <div className="d-flex flex-column align-items-start mb-4 gap-4">
				{error ? (
					<>
						<Typography className="text-danger" variant={"bodySmBold"}>
                Oops, Something went wrong.. {console.log(t(error.message))}
						</Typography>
					</>
				) : (
					<></>
				)}
				<Formik
					initialValues={{
						email: "",
						optIn: false
					}}
					validationSchema={validationSchema}
					onSubmit={async (
						values: Values,
						{setSubmitting}: FormikHelpers<Values>
					) => {
						setIsSubmitting(true)
						try {
							const idToken = await user.getIdToken()
							const res = await fetchPost("/api/auth/sendMagicLink", {
								idToken,
								email: values.email
							})
							if (res.status === 201) {
								setMagicLinkSent(true)
							} else {
								setError(await res.json())
							}
						} catch (error) {
							console.log(error)
							setError(true)
						} finally {
							setIsSubmitting(false)
						}
					}}
				>
					{({touched, errors}) => (
						<Form className="d-flex flex-column align-items-start gap-4">
							<Typography variant={"bodySmBoldMdBodyLgBold"}>
								{t("work_email")} <sup className="text-danger -mb-1" style={{fontSize: 20}}>*</sup>
							</Typography>
							<Field
								style={{
									width: "368px",
									height: "50px",
									border: touched.email && errors.email ? "2px solid red" : "2px solid #D2D7DC",
									borderRadius: "4px",
									outline: "none",

									padding: "3px"
								}}
								id="email"
								name="email"
								placeholder=""
								type="email"
							/>
							<ErrorMessage name="email" component="div" className="text-danger small" />
							<div className="d-flex align-items-center gap-2 px-2 justify-content-start">
								<Field name="optIn" id="optIn" type="checkbox" /><label htmlFor="optIn">{t("optIn") }</label>
							</div>
							<ErrorMessage
								name="optIn"
								component="div"
								className="text-danger small"
							/>
							<Button
								className="w-50 d-none d-md-block"
								label={t("Login")}
								color={"white"}
								type={"submit"}
								bgColor={"black"}
								textColor={"white"}
								isLoading={isSubmitting}
								spinnerColor={"white"}
							/>
							<Button
								className="w-100  d-md-none"
								label={t("Log in")}
								color={"white"}
								type={"submit"}
								bgColor={"black"}
								textColor={"white"}
								isLoading={isSubmitting}
								spinnerColor={"white"}
							/>
						</Form>)}
				</Formik>
			</div>
			}
		</>
	)
}

export default FormPartnersLogin
