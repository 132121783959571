import React, {useContext} from "react"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {ModalContext} from "../../../context/ModalContext"
import AskAQuestion from "../../bikeDetails/AskAQuestion"
import Button from "../components/Button"
import NavLink from "../components/NavLink/NavLink"
import AnalyticsContext from "../../../context/AnalyticsContext"

type Props = { label: string, hasIcon?: boolean, button?: boolean }

const AskAQuestionModalButton = ({label, hasIcon, button}: Props): React.ReactElement => {
	const icon = <img src="/assets/icons/share/mail.svg" alt=""/>
	const {setModalContent, openModal, closeModal} = useContext(ModalContext)
	const {reactGA} = useContext(AnalyticsContext)
	const handleClick = () => {
		reactGA?.event({
			category: gaCategories.feedback,
			action: gaEvents.suggestProduct,
			label: gaEvents.suggestProduct,
			nonInteraction: false
		})
		setModalContent(<AskAQuestion setAskAQuestion={closeModal}/>)
		openModal()
	}

	return (
		<>
			{button ?
				<Button icon={hasIcon ? icon : null} textColor={"#212529"} hoverColor="#212529" color="#fff"
					outline
					className="w-100"
					onClick={handleClick}
					label={label}/> :
				<NavLink btn onClick={handleClick} className="mb-3 col-12 text-start" borderBottom label={label}/>}
		</>

	)
}

export default AskAQuestionModalButton
