import React from "react"
import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"
import {CheckoutData} from "../../../context/CheckoutContext"
import {customPlanLengthMap} from "../plan/customPlanLengthMap"

interface Props {
    checkoutData: any|CheckoutData
    resultingPrice: number
    setIsOpen: (arg0: boolean) => void
    isOpen: boolean
    t: (arg0: string) => string
}

const DurationSlideHeader:React.FC<Props> = ({
	checkoutData,
	resultingPrice,
	setIsOpen,
	isOpen,
	t
}) => {
	const customPlanLength = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = customPlanLength ? customPlanLength[checkoutData?.planLength] : checkoutData?.planLength || 36
	return (
		<div className="d-flex justify-content-between">
			<div className="d-flex">
				{/* new variant */}
				<Typography className="" variant="bodyLgBold"
					semanticTag="span">{t("Laufzeit")}</Typography>
				<Typography className="ms-1" variant="bodyLg"
					semanticTag="span" style={{color: "#6C7074"}}>({t("Monate")})</Typography>

			</div>
			{/* new variant */}
			<div className="d-flex align-items-center">
				{planLength !== 1 && <Typography className="me-3" semanticTag="span" variant="bodyLg">CHF {Math.ceil(resultingPrice / (planLength || 36))}/m</Typography>}
				<Image onClick={() => setIsOpen(!isOpen)}
					src={`${isOpen ? "/assets/icons/info-active.svg" : "/assets/icons/info-transparent.svg"}`}
					height={20}
					width={20} className="pointer"
				/>
			</div>
		</div>
	)
}

export default DurationSlideHeader
