import React, {useContext, useState} from "react"
import LayoutContext from "../../../context/LayoutContext"
import Modal from "../components/Modal/Modal"
import Image from "next/image"
import CustomerQuestionForm from "../../forms/CustomerQuestionForm"
import Typography from "../Typography/Typography"
import {useTranslation} from "next-i18next"
import AnimationComponent from "../components/AnimationComponent/AnimationComponent"
import Button from "../components/Button"

interface Props {
  name: string
  sku: string
  closeModal: () => void
  isModalOpen: boolean
}

const CustomerQuestionModal: React.FC<Props> = ({name, sku, closeModal, isModalOpen}) => {
	const {t} = useTranslation("common")
	const {isMobile} = useContext(LayoutContext)
	const [isSubmitError, setIsSubmitError] = useState<boolean>(false)
	const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false)

	return (
		<>
			<div className="d-none d-md-block">
				<Modal closeModal={closeModal} isOpen={isModalOpen}>
					<div style={{maxWidth: isMobile ? "90vw" : "100vw", width: isMobile ? "90vw" : "100%"}}
						className=" bg-white pe-md-0 overflow-hidden">
						<div className={"row justify-content-end"} style={{
							zIndex: 999999
						}}>
							<div className={"col-auto"}>
								<div className={"py-2 px-0"}>
									<button type={"button"} className={"btn px-0"} onClick={() => {
										closeModal()
										setIsSubmitError(false)
										setIsSubmitSuccess(false)
									}}>
										<img src="/assets/icons/close-icon.svg" width="15.5" height="15.5"/>
									</button>
								</div>
							</div>
						</div>
						{
							isSubmitError ?
								<div className="row justify-content-center align-items-center mx-5 pb-5">
									<div className="col-12 text-center">
										<Typography semanticTag="span" variant={"bodyLg"}>{t("customer-question-form-error")}</Typography>
									</div>
								</div> :
								isSubmitSuccess ?
									<div className="row justify-content-center align-items-center mx-5 pb-5">
										<div className="col-12 text-center">
											<Typography semanticTag="span"
												variant={"bodyLg"}>{t("customer-question-form-success")}</Typography>
										</div>
									</div> :
									<div className="row position-relative gx-5 mt-3 mx-0">
										<div className="col-12 col-md-7 mb-5">
											<CustomerQuestionForm
												name={name}
												sku={sku}
												closeModal={closeModal}
												isSubmitError={isSubmitError}
												isSubmitSuccess={isSubmitSuccess}
												setIsSubmitError={setIsSubmitError}
												setIsSubmitSuccess={setIsSubmitSuccess}
											/>
										</div>
										<div style={{
											marginTop: "-10%",
											zIndex: 0
										}} className="col-5 p-0 d-sm-none d-md-block position-relative overflow-hidden">
											<div className={"position-absolute top-0 end-0"} style={{
												marginTop: "5%",
												marginRight: "5%",
												zIndex: 1
											}}>
												<button type={"button"} className={"btn px-0"} onClick={() => {
													closeModal()
												}}>
													<img src="/assets/icons/close-icon.svg" width="15.5" height="15.5"/>
												</button>
											</div>
											<Image src="/assets/images/header-slider-img-two.jpg"
												alt="bike" layout="fill" objectFit="cover" objectPosition="top"
											/>
										</div>

									</div>

						}

					</div>
				</Modal>
			</div>
			<div className="d-block d-md-none">

				<AnimationComponent type="slideUp" inProp={isModalOpen}>
					<div className={"position-fixed px-3 py-4 bg-white start-0 bottom-0 end-0"} style={{
						backgroundColor: "rgba(0,0,0,0.5)",
						zIndex: 999999999,
						borderRadius: "16px 16px 0px 0px",
						border: "1px solid #f4f4f4"
					}}>
						<div
							className=" bg-white pe-md-0">
							{
								isSubmitError ?
									<div className="row justify-content-center align-items-center mx-5 pb-5">
										<div className="col-12 text-center">
											<Typography semanticTag="span"
												variant={"bodyLg"}>{t("customer-question-form-error")}</Typography>
										</div>
									</div> :
									isSubmitSuccess ?
										<AnimationComponent type="slideUp" inProp={isSubmitSuccess}>
											<div className={"position-fixed px-3 py-4 bg-white start-0 bottom-0 end-0"} style={{
												backgroundColor: "rgba(0,0,0,0.5)",
												zIndex: 999999999,
												borderRadius: "16px 16px 0px 0px",
												border: "1px solid #f4f4f4"
											}}>
												<div className={"row m-0 justify-content-end"} style={{}}>
													<div className={"col-auto px-0 cursor-pointer mb-3"}
														style={{height: "15.5px", width: "15.5px"}}
														onClick={() => {
															closeModal()
															setIsSubmitError(false)
															setIsSubmitSuccess(false)
														}}>
														<img src="/assets/icons/close-icon.svg" width="15.5" height="15.5"/>
													</div>
												</div>
												<div className="d-flex flex-column align-items-center mb-4">
													<img className="mb-3" src="/assets/icons/mail-success.svg" alt="success"/>
													<Typography variant="bodyLgBlack" semanticTag="h2"
														style={{fontSize: 40, color: "#2CA58D"}}>Success</Typography>
												</div>
												<Typography semanticTag="span"
													variant={"bodyLg"}>{t("customer-question-form-success")}</Typography>
												<Button
													className="col-12 mt-4"
													textColor="#fff"
													bgColor="#212529"
													hoverColor="#212529"
													color="#fff"
													label={"CONTINUE SHOPPING"}
													onClick={() => {
														closeModal()
														setIsSubmitError(false)
														setIsSubmitSuccess(false)
													}}
												/>
											</div>
										</AnimationComponent> :
										<div className="row position-relative mt-3 mx-0">
											<div className="col-12 col-md-7">
												<CustomerQuestionForm
													name={name}
													sku={sku}
													closeModal={closeModal}
													isSubmitError={isSubmitError}
													isSubmitSuccess={isSubmitSuccess}
													setIsSubmitError={setIsSubmitError}
													setIsSubmitSuccess={setIsSubmitSuccess}
												/>
											</div>
										</div>
							}
						</div>
					</div>
				</AnimationComponent>
			</div>
		</>
	)
}

export default CustomerQuestionModal
