import React from "react"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"

type Props = {
    items: any[],
    label: string
}
const RelatedProducts = ({items, label}: Props) => {
	const {t} = useTranslation()
	return (items.length ?
		<>
			<Typography variant="bodySm" className="col-12 mb-3 mt-4" semanticTag="div">
				{label}
			</Typography>
			<div className="col-12">
				<div className={"row g-1"}>
					{items.map((rel:any) => <div key={rel.slug} className={"col-auto"}>
						<Link key={rel.slug} href={`/${"produkt"}/${rel.slug}`}>
							<a className="d-inline-block link-reset cursor-pointer filter-item-box" style={{
								padding: "10px 16px",
								borderRadius: 16,
								border: "1px solid #DEE2E6",
								color: "#212529"
							}}>
								<Typography variant={"bodySm"} semanticTag={"span"}>
									{t(rel.label)}
								</Typography>
							</a>
						</Link>
					</div>)}
				</div>
			</div>
		</> : null
	)
}

RelatedProducts.propTypes = {

}

export default RelatedProducts
