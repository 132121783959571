export const countryList = [
	{label: "Andorra", value: "Andorra", code: "AD"},
	{label: "دولة الإمارات العربية المتحدة", value: "United Arab Emirates", code: "AE"},
	{label: "افغانستان", value: "Afghanistan", code: "AF"},
	{label: "Antigua and Barbuda", value: "Antigua and Barbuda", code: "AG"},
	{label: "Anguilla", value: "Anguilla", code: "AI"},
	{label: "Shqipëria", value: "Albania", code: "AL"},
	{label: "Հայաստան", value: "Armenia", code: "AM"},
	{label: "Angola", value: "Angola", code: "AO"},
	{label: "Antarctica", value: "Antarctica", code: "AQ"},
	{label: "Argentina", value: "Argentina", code: "AR"},
	{label: "American Samoa", value: "American Samoa", code: "AS"},
	{label: "Österreich", value: "Austria", code: "AT"},
	{label: "Australia", value: "Australia", code: "AU"},
	{label: "Aruba", value: "Aruba", code: "AW"},
	{label: "Åland", value: "Aland", code: "AX"},
	{label: "Azərbaycan", value: "Azerbaijan", code: "AZ"},
	{label: "Bosna i Hercegovina", value: "Bosnia and Herzegovina", code: "BA"},
	{label: "Barbados", value: "Barbados", code: "BB"},
	{label: "Bangladesh", value: "Bangladesh", code: "BD"},
	{label: "België", value: "Belgium", code: "BE"},
	{label: "Burkina Faso", value: "Burkina Faso", code: "BF"},
	{label: "България", value: "Bulgaria", code: "BG"},
	{label: "‏البحرين", value: "Bahrain", code: "BH"},
	{label: "Burundi", value: "Burundi", code: "BI"},
	{label: "Bénin", value: "Benin", code: "BJ"},
	{label: "Saint-Barthélemy", value: "Saint Barthélemy", code: "BL"},
	{label: "Bermuda", value: "Bermuda", code: "BM"},
	{label: "Negara Brunei Darussalam", value: "Brunei", code: "BN"},
	{label: "Bolivia", value: "Bolivia", code: "BO"},
	{label: "Bonaire", value: "Bonaire", code: "BQ"},
	{label: "Brasil", value: "Brazil", code: "BR"},
	{label: "Bahamas", value: "Bahamas", code: "BS"},
	{label: "ʼbrug-yul", value: "Bhutan", code: "BT"},
	{label: "Bouvetøya", value: "Bouvet Island", code: "BV"},
	{label: "Botswana", value: "Botswana", code: "BW"},
	{label: "Белару́сь", value: "Belarus", code: "BY"},
	{label: "Belize", value: "Belize", code: "BZ"},
	{label: "Canada", value: "Canada", code: "CA"},
	{label: "Cocos (Keeling) Islands", value: "Cocos [Keeling] Islands", code: "CC"},
	{
		label: "République démocratique du Congo",
		value: "Democratic Republic of the Congo",
		code: "CD"
	},
	{label: "Ködörösêse tî Bêafrîka", value: "Central African Republic", code: "CF"},
	{label: "République du Congo", value: "Republic of the Congo", code: "CG"},
	{label: "Switzerland", value: "Switzerland", code: "CH"},
	{label: "Schweiz", value: "Switzerland", code: "CH"},
	{label: "Suisse", value: "Switzerland", code: "CH"},
	{label: "Côte d'Ivoire", value: "Ivory Coast", code: "CI"},
	{label: "Cook Islands", value: "Cook Islands", code: "CK"},
	{label: "Chile", value: "Chile", code: "CL"},
	{label: "Cameroon", value: "Cameroon", code: "CM"},
	{label: "中国", value: "China", code: "CN"},
	{label: "Colombia", value: "Colombia", code: "CO"},
	{label: "Costa Rica", value: "Costa Rica", code: "CR"},
	{label: "Cuba", value: "Cuba", code: "CU"},
	{label: "Cabo Verde", value: "Cape Verde", code: "CV"},
	{label: "Curaçao", value: "Curacao", code: "CW"},
	{label: "Christmas Island", value: "Christmas Island", code: "CX"},
	{label: "Κύπρος", value: "Cyprus", code: "CY"},
	{label: "Česká republika", value: "Czech Republic", code: "CZ"},
	{label: "Deutschland", value: "Germany", code: "DE"},
	{label: "Djibouti", value: "Djibouti", code: "DJ"},
	{label: "Danmark", value: "Denmark", code: "DK"},
	{label: "Dominica", value: "Dominica", code: "DM"},
	{label: "República Dominicana", value: "Dominican Republic", code: "DO"},
	{label: "الجزائر", value: "Algeria", code: "DZ"},
	{label: "Ecuador", value: "Ecuador", code: "EC"},
	{label: "Eesti", value: "Estonia", code: "EE"},
	{label: "مصر‎", value: "Egypt", code: "EG"},
	{label: "الصحراء الغربية", value: "Western Sahara", code: "EH"},
	{label: "ኤርትራ", value: "Eritrea", code: "ER"},
	{label: "España", value: "Spain", code: "ES"},
	{label: "ኢትዮጵያ", value: "Ethiopia", code: "ET"},
	{label: "Suomi", value: "Finland", code: "FI"},
	{label: "Fiji", value: "Fiji", code: "FJ"},
	{label: "Falkland Islands", value: "Falkland Islands", code: "FK"},
	{label: "Micronesia", value: "Micronesia", code: "FM"},
	{label: "Føroyar", value: "Faroe Islands", code: "FO"},
	{label: "France", value: "France", code: "FR"},
	{label: "Gabon", value: "Gabon", code: "GA"},
	{label: "United Kingdom", value: "United Kingdom", code: "GB"},
	{label: "Grenada", value: "Grenada", code: "GD"},
	{label: "საქართველო", value: "Georgia", code: "GE"},
	{label: "Guyane française", value: "French Guiana", code: "GF"},
	{label: "Guernsey", value: "Guernsey", code: "GG"},
	{label: "Ghana", value: "Ghana", code: "GH"},
	{label: "Gibraltar", value: "Gibraltar", code: "GI"},
	{label: "Kalaallit Nunaat", value: "Greenland", code: "GL"},
	{label: "Gambia", value: "Gambia", code: "GM"},
	{label: "Guinée", value: "Guinea", code: "GN"},
	{label: "Guadeloupe", value: "Guadeloupe", code: "GP"},
	{label: "Guinea Ecuatorial", value: "Equatorial Guinea", code: "GQ"},
	{label: "Ελλάδα", value: "Greece", code: "GR"},
	{label: "South Georgia", value: "South Georgia and the South Sandwich Islands", code: "GS"},
	{label: "Guatemala", value: "Guatemala", code: "GT"},
	{label: "Guam", value: "Guam", code: "GU"},
	{label: "Guiné-Bissau", value: "Guinea-Bissau", code: "GW"},
	{label: "Guyana", value: "Guyana", code: "GY"},
	{label: "香港", value: "Hong Kong", code: "HK"},
	{
		label: "Heard Island and McDonald Islands",
		value: "Heard Island and McDonald Islands",
		code: "HM"
	},
	{label: "Honduras", value: "Honduras", code: "HN"},
	{label: "Hrvatska", value: "Croatia", code: "HR"},
	{label: "Haïti", value: "Haiti", code: "HT"},
	{label: "Magyarország", value: "Hungary", code: "HU"},
	{label: "Indonesia", value: "Indonesia", code: "ID"},
	{label: "Éire", value: "Ireland", code: "IE"},
	{label: "יִשְׂרָאֵל", value: "Israel", code: "IL"},
	{label: "Isle of Man", value: "Isle of Man", code: "IM"},
	{label: "भारत", value: "India", code: "IN"},
	{label: "British Indian Ocean Territory", value: "British Indian Ocean Territory", code: "IO"},
	{label: "العراق", value: "Iraq", code: "IQ"},
	{label: "ایران", value: "Iran", code: "IR"},
	{label: "Ísland", value: "Iceland", code: "IS"},
	{label: "Italia", value: "Italy", code: "IT"},
	{label: "Jersey", value: "Jersey", code: "JE"},
	{label: "Jamaica", value: "Jamaica", code: "JM"},
	{label: "الأردن", value: "Jordan", code: "JO"},
	{label: "日本", value: "Japan", code: "JP"},
	{label: "Kenya", value: "Kenya", code: "KE"},
	{label: "Кыргызстан", value: "Kyrgyzstan", code: "KG"},
	{label: "Kâmpŭchéa", value: "Cambodia", code: "KH"},
	{label: "Kiribati", value: "Kiribati", code: "KI"},
	{label: "Komori", value: "Comoros", code: "KM"},
	{label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis", code: "KN"},
	{label: "북한", value: "North Korea", code: "KP"},
	{label: "대한민국", value: "South Korea", code: "KR"},
	{label: "الكويت", value: "Kuwait", code: "KW"},
	{label: "Cayman Islands", value: "Cayman Islands", code: "KY"},
	{label: "Қазақстан", value: "Kazakhstan", code: "KZ"},
	{label: "ສປປລາວ", value: "Laos", code: "LA"},
	{label: "لبنان", value: "Lebanon", code: "LB"},
	{label: "Saint Lucia", value: "Saint Lucia", code: "LC"},
	{label: "Liechtenstein", value: "Liechtenstein", code: "LI"},
	{label: "śrī laṃkāva", value: "Sri Lanka", code: "LK"},
	{label: "Liberia", value: "Liberia", code: "LR"},
	{label: "Lesotho", value: "Lesotho", code: "LS"},
	{label: "Lietuva", value: "Lithuania", code: "LT"},
	{label: "Luxembourg", value: "Luxembourg", code: "LU"},
	{label: "Latvija", value: "Latvia", code: "LV"},
	{label: "‏ليبيا", value: "Libya", code: "LY"},
	{label: "المغرب", value: "Morocco", code: "MA"},
	{label: "Monaco", value: "Monaco", code: "MC"},
	{label: "Moldova", value: "Moldova", code: "MD"},
	{label: "Црна Гора", value: "Montenegro", code: "ME"},
	{label: "Saint-Martin", value: "Saint Martin", code: "MF"},
	{label: "Madagasikara", value: "Madagascar", code: "MG"},
	{label: "M̧ajeļ", value: "Marshall Islands", code: "MH"},
	{label: "Северна Македонија", value: "North Macedonia", code: "MK"},
	{label: "Mali", value: "Mali", code: "ML"},
	{label: "မြန်မာ", value: "Myanmar [Burma]", code: "MM"},
	{label: "Монгол улс", value: "Mongolia", code: "MN"},
	{label: "澳門", value: "Macao", code: "MO"},
	{label: "Northern Mariana Islands", value: "Northern Mariana Islands", code: "MP"},
	{label: "Martinique", value: "Martinique", code: "MQ"},
	{label: "موريتانيا", value: "Mauritania", code: "MR"},
	{label: "Montserrat", value: "Montserrat", code: "MS"},
	{label: "Malta", value: "Malta", code: "MT"},
	{label: "Maurice", value: "Mauritius", code: "MU"},
	{label: "Maldives", value: "Maldives", code: "MV"},
	{label: "Malawi", value: "Malawi", code: "MW"},
	{label: "México", value: "Mexico", code: "MX"},
	{label: "Malaysia", value: "Malaysia", code: "MY"},
	{label: "Moçambique", value: "Mozambique", code: "MZ"},
	{label: "Namibia", value: "Namibia", code: "NA"},
	{label: "Nouvelle-Calédonie", value: "New Caledonia", code: "NC"},
	{label: "Niger", value: "Niger", code: "NE"},
	{label: "Norfolk Island", value: "Norfolk Island", code: "NF"},
	{label: "Nigeria", value: "Nigeria", code: "NG"},
	{label: "Nicaragua", value: "Nicaragua", code: "NI"},
	{label: "Nederland", value: "Netherlands", code: "NL"},
	{label: "Norge", value: "Norway", code: "NO"},
	{label: "नपल", value: "Nepal", code: "NP"},
	{label: "Nauru", value: "Nauru", code: "NR"},
	{label: "Niuē", value: "Niue", code: "NU"},
	{label: "New Zealand", value: "New Zealand", code: "NZ"},
	{label: "عمان", value: "Oman", code: "OM"},
	{label: "Panamá", value: "Panama", code: "PA"},
	{label: "Perú", value: "Peru", code: "PE"},
	{label: "Polynésie française", value: "French Polynesia", code: "PF"},
	{label: "Papua Niugini", value: "Papua New Guinea", code: "PG"},
	{label: "Pilipinas", value: "Philippines", code: "PH"},
	{label: "Pakistan", value: "Pakistan", code: "PK"},
	{label: "Polska", value: "Poland", code: "PL"},
	{label: "Saint-Pierre-et-Miquelon", value: "Saint Pierre and Miquelon", code: "PM"},
	{label: "Pitcairn Islands", value: "Pitcairn Islands", code: "PN"},
	{label: "Puerto Rico", value: "Puerto Rico", code: "PR"},
	{label: "فلسطين", value: "Palestine", code: "PS"},
	{label: "Portugal", value: "Portugal", code: "PT"},
	{label: "Palau", value: "Palau", code: "PW"},
	{label: "Paraguay", value: "Paraguay", code: "PY"},
	{label: "قطر", value: "Qatar", code: "QA"},
	{label: "La Réunion", value: "Réunion", code: "RE"},
	{label: "România", value: "Romania", code: "RO"},
	{label: "Србија", value: "Serbia", code: "RS"},
	{label: "Россия", value: "Russia", code: "RU"},
	{label: "Rwanda", value: "Rwanda", code: "RW"},
	{label: "العربية السعودية", value: "Saudi Arabia", code: "SA"},
	{label: "Solomon Islands", value: "Solomon Islands", code: "SB"},
	{label: "Seychelles", value: "Seychelles", code: "SC"},
	{label: "السودان", value: "Sudan", code: "SD"},
	{label: "Sverige", value: "Sweden", code: "SE"},
	{label: "Singapore", value: "Singapore", code: "SG"},
	{label: "Saint Helena", value: "Saint Helena", code: "SH"},
	{label: "Slovenija", value: "Slovenia", code: "SI"},
	{label: "Svalbard og Jan Mayen", value: "Svalbard and Jan Mayen", code: "SJ"},
	{label: "Slovensko", value: "Slovakia", code: "SK"},
	{label: "Sierra Leone", value: "Sierra Leone", code: "SL"},
	{label: "San Marino", value: "San Marino", code: "SM"},
	{label: "Sénégal", value: "Senegal", code: "SN"},
	{label: "Soomaaliya", value: "Somalia", code: "SO"},
	{label: "Suriname", value: "Suriname", code: "SR"},
	{label: "South Sudan", value: "South Sudan", code: "SS"},
	{label: "São Tomé e Príncipe", value: "São Tomé and Príncipe", code: "ST"},
	{label: "El Salvador", value: "El Salvador", code: "SV"},
	{label: "Sint Maarten", value: "Sint Maarten", code: "SX"},
	{label: "سوريا", value: "Syria", code: "SY"},
	{label: "Swaziland", value: "Swaziland", code: "SZ"},
	{label: "Turks and Caicos Islands", value: "Turks and Caicos Islands", code: "TC"},
	{label: "Tchad", value: "Chad", code: "TD"},
	{
		label: "Territoire des Terres australes et antarctiques fr",
		value: "French Southern Territories",
		code: "TF"
	},
	{label: "Togo", value: "Togo", code: "TG"},
	{label: "ประเทศไทย", value: "Thailand", code: "TH"},
	{label: "Тоҷикистон", value: "Tajikistan", code: "TJ"},
	{label: "Tokelau", value: "Tokelau", code: "TK"},
	{label: "Timor-Leste", value: "East Timor", code: "TL"},
	{label: "Türkmenistan", value: "Turkmenistan", code: "TM"},
	{label: "تونس", value: "Tunisia", code: "TN"},
	{label: "Tonga", value: "Tonga", code: "TO"},
	{label: "Türkiye", value: "Turkey", code: "TR"},
	{label: "Trinidad and Tobago", value: "Trinidad and Tobago", code: "TT"},
	{label: "Tuvalu", value: "Tuvalu", code: "TV"},
	{label: "臺灣", value: "Taiwan", code: "TW"},
	{label: "Tanzania", value: "Tanzania", code: "TZ"},
	{label: "Україна", value: "Ukraine", code: "UA"},
	{label: "Uganda", value: "Uganda", code: "UG"},
	{
		label: "United States Minor Outlying Islands",
		value: "U.S. Minor Outlying Islands",
		code: "UM"
	},
	{label: "United States", value: "United States", code: "US"},
	{label: "Uruguay", value: "Uruguay", code: "UY"},
	{label: "O‘zbekiston", value: "Uzbekistan", code: "UZ"},
	{label: "Vaticano", value: "Vatican City", code: "VA"},
	{
		label: "Saint Vincent and the Grenadines",
		value: "Saint Vincent and the Grenadines",
		code: "VC"
	},
	{label: "Venezuela", value: "Venezuela", code: "VE"},
	{label: "British Virgin Islands", value: "British Virgin Islands", code: "VG"},
	{label: "United States Virgin Islands", value: "U.S. Virgin Islands", code: "VI"},
	{label: "Việt Nam", value: "Vietnam", code: "VN"},
	{label: "Vanuatu", value: "Vanuatu", code: "VU"},
	{label: "Wallis et Futuna", value: "Wallis and Futuna", code: "WF"},
	{label: "Samoa", value: "Samoa", code: "WS"},
	{label: "Republika e Kosovës", value: "Kosovo", code: "XK"},
	{label: "اليَمَن", value: "Yemen", code: "YE"},
	{label: "Mayotte", value: "Mayotte", code: "YT"},
	{label: "South Africa", value: "South Africa", code: "ZA"},
	{label: "Zambia", value: "Zambia", code: "ZM"},
	{label: "Zimbabwe", value: "Zimbabwe", code: "ZW"}
]
