import React, {forwardRef} from "react"
import {useTranslation} from "next-i18next"
import Price from "./Price"
import TotalPrice from "./TotalPrice"
import Typography from "../../reusable/Typography/Typography"
import CollectionItemBadge from "./CollectionItemBadge"
import AboutThisBike from "../../bikeDetails/AboutThisBike"
import BikeCollectionItemLoadingOverlay from "../BikeCollectionItemLoadingOverlay"
import {isOccasionBike} from "../../../utility/Helper"
import BikeName from "./BikeName"
import ComparisonCheckboxButton from "./ComparisonCheckboxButton"
import Image from "next/image"
import Link from "next/link"
import StockWarning from "./StockWarning"
import useHandleDownPayment from "../../../hooks/handleDownPayment"
import YourDownPayment from "../../reusable/YourDownPayment"
import ProductAvailableStatus from "./ProductAvailabelStatus"
import ProductColors from "./ProductColors"

type BageProps = {
	isBikeOfTheMonth: boolean
	is2023: boolean
	is2022: boolean
	isNew: boolean
	kilometers: boolean
	occasion: boolean
	onSale: boolean
	isInStock: boolean
	isNonEBike: boolean
	discountKey: string
	isFeatured:boolean
}

type Props = {
	productUrl: string
	selected: boolean
	soldOut: boolean
	variations: any
	dImageSlides: boolean
	attachments: string[]
	bikeWeight: number
	brand: string
	stockInfo: string
	loadingVariations: boolean
	loading: boolean
	regularPrice: string
	bike: any
	badgeProps: BageProps
	setDImageSlides: React.Dispatch<React.SetStateAction<boolean>>
	handleMouseEnter: React.MouseEventHandler<HTMLElement>
	handleMouseLeave: React.MouseEventHandler<HTMLElement>
	handleClick?: React.MouseEventHandler<HTMLElement>
	secondHandPrice?: number
	withArrows?:boolean
	mainCategory?: string
}

const CollectionItemListView = forwardRef<HTMLDivElement, Props>(({
	secondHandPrice,
	productUrl,
	selected,
	brand,
	soldOut,
	loading,
	regularPrice,
	bike,
	badgeProps,
	handleMouseEnter,
	handleMouseLeave,
	mainCategory
}, ref) => {
	const {isBikeOfTheMonth, isFeatured, is2023, is2022, isNew, kilometers, occasion, onSale, isNonEBike, discountKey} = badgeProps
	const {t} = useTranslation()
	const isSecondHand = isOccasionBike(bike)
	const isMyHomePlan = process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan"
	const {totalPhysicalStock} = bike
	const {downPayment} = useHandleDownPayment(parseFloat(bike.price))
	const afterDownPaymentPrice = bike.price - downPayment < 0 ? 0 : bike.price - downPayment
	const getPriceForPartners = () => {
		if (process.env.NEXT_PUBLIC_ORGANIZATION) {
			return parseFloat(bike.price) - parseFloat(bike?.customInstanceDiscount) - downPayment < 0 ? 0 : parseFloat(bike.price) - parseFloat(bike?.customInstanceDiscount) - downPayment || null
		}

		return null
	}

	return (
		<>
			<Link href={productUrl} passHref={true}>
				<a
					className={`row d-block d-md-none link-reset cursor-pointer px-2 pt-2 pb-3 position-relative justify-content-between position-relative card-item ${selected ? "card-shadow" : null}`}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					style={{border: "1px solid #DEE2E6", borderRadius: 16}}
				>
					<div className="col-12 bg-white   mb-2 " style={{height: 96, borderRadius: 8}}
					>
						<div className="row align-items-center p-2">
							<div className="px-0 col-auto">
								<div
									className="position-absolute col-12 d-flex align-items-start z-index"
									style={{top: -8, left: 8}}>
									<CollectionItemBadge
										isBikeOfTheMonth={isBikeOfTheMonth}
										isNonEBike={isNonEBike}
										is2023={is2023}
										isFeatured={isFeatured}
										is2022={is2022}
										isNew={isNew}
										kilometers={kilometers}
										occasion={occasion}
										onSale={onSale}
										discountKey={discountKey}
										soldOut={soldOut}/>
								</div>
								<div style={{height: 72}}>
									<Image src={bike.images[0]?.src} height={72} width={72}/>
								</div>
								<div className={"mt-2"}>
									<StockWarning amount={totalPhysicalStock as number} />
								</div>

							</div>

							<div className="col-8 ms-3">
								<BikeName bike={bike}/>
								<Price price={afterDownPaymentPrice} salePrice={afterDownPaymentPrice} customInstanceDiscountPrice={getPriceForPartners()} regularPrice={parseFloat(regularPrice)} onSale={onSale} priceFontSize={24}/>
								<TotalPrice price={afterDownPaymentPrice} onSale={onSale}
									customInstanceDiscount={bike?.customInstanceDiscount || null}
									regularPrice={regularPrice} listView isSecondHand={isSecondHand}
									secondHandPrice={secondHandPrice} priceVariant={ bike?.customInstanceDiscount || isSecondHand || onSale ? "bodyXSmBoldMdBodySmBold" : "bodySm" } />
								{/* Show DownPayment in the product card: */}
								<YourDownPayment onSale={onSale} downPayment={downPayment} isSecondHand={isSecondHand}
									variant={ "bodyXSm" }/>
							</div>
						</div>
					</div>
					<div className="col-12  mt-3 mb-2 border-bottom" />
					<div className="d-flex col-12 justify-content-between mb-4 px-3">
						<Typography variant="bodyXSm" semanticTag="span"
							className={"col-auto"}>
							{brand}
						</Typography>
						<ProductAvailableStatus stockStatus={bike?.stock_status} withHoverEffect={true}/>
					</div>
					<div className="d-flex flex-wrap"
					>
						{isBikeOfTheMonth && <Typography variant="bodySmBold" semanticTag="span" style={{backgroundColor: "#000"}} className="badge text-white text-uppercase py-2 px-3 mb-1 me-2">
							{t("bike-of-th-month")}
						</Typography>}

					</div>
					<div className="col-12">
						<div className="col-6 d-flex flex-column gap-2   mt-2">
							<Typography
								variant="bodyXSmBold">
								Colors
							</Typography>
							<ProductColors colors={bike.colorData} />
						</div>					</div>
					<div className="col-12  mt-3 mb-2 border-bottom"/>
					<div className="d-flex px-0 justify-content-between">
						<ComparisonCheckboxButton slug={bike.slug}/>
					</div>
					{loading ? <BikeCollectionItemLoadingOverlay/> : null}
				</a>
			</Link>
			<Link href={productUrl} passHref={true}>
				<a
					className={`link-reset d-none d-md-flex row p-3 cursor-pointer mb-4 pb-3 position-relative justify-content-between position-relative card-item ${selected ? "card-shadow" : null}`}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					style={{border: "1px solid #DEE2E6", borderRadius: 16, minHeight: 288, maxWidth: 1096}}
				>
					<div className="col-7 bg-white" style={{minHeight: 96, borderRadius: 8}}
					>
						<div className="d-flex justify-content-between flex-nowrap align-items-center p-2">
							<div className="px-0 col-6" style={{
								height: 248,
								maxWidth: 256,
								position: "relative",
								backgroundPosition: "center",
								backgroundSize: "cover"
							}}>
								<Image src={bike.images[0]?.src} layout="fill" className="rounded-3" />
								<div
									className="position-absolute col-12 d-flex align-items-start z-index"
									style={{top: -8, left: 8}}>
									<CollectionItemBadge isFeatured={isFeatured} isBikeOfTheMonth={isBikeOfTheMonth} isNonEBike={isNonEBike} is2023={is2023} is2022={is2022}
										isNew={isNew}
										kilometers={kilometers} occasion={occasion} onSale={onSale} soldOut={soldOut}/>
								</div>
								<StockWarning islistView amount={totalPhysicalStock as number} />
							</div>

							<div className="col-5 ms-4 px-0">
								<BikeName bike={bike} />
								{process.env.NEXT_PUBLIC_ORGANIZATION && bike?.customInstanceDiscount && <div className="d-flex align-items-start gap-2"> <Image src={"/assets/icons/sbb_vector.png"} width={28.68} height={18}/><Typography style={{marginTop: -2}} variant={ "bodyXSm"} semanticTag="span">
									{t("sbb_exclusive")}
								</Typography></div>}
								<div className="d-flex col-12 justify-content-between mt-1 mb-4 px-0">
									<Typography variant="bodyXSm" semanticTag="span"
										className={"col-auto"}>
										{isMyHomePlan ? brand : mainCategory}
									</Typography>
									<ProductAvailableStatus stockStatus={bike?.stock_status} withHoverEffect={true}/>
								</div>
								<Price price={afterDownPaymentPrice} salePrice={afterDownPaymentPrice} customInstanceDiscountPrice={getPriceForPartners()} regularPrice={parseFloat(regularPrice)} onSale={onSale}/>
								<TotalPrice price={afterDownPaymentPrice} onSale={onSale}
									customInstanceDiscount={bike?.customInstanceDiscount || null}
									regularPrice={regularPrice} listView isSecondHand={isSecondHand}
									secondHandPrice={secondHandPrice} priceVariant={ bike?.customInstanceDiscount || isSecondHand || onSale ? "bodyXSmBoldMdBodySmBold" : "bodySm" } />
								{/* Show DownPayment in the product card: */}
								<YourDownPayment onSale={onSale} downPayment={downPayment} isSecondHand={isSecondHand}
									variant={ "bodyXSm" }/>
							</div>
						</div>
					</div>
					<div className="col-5 ps-4">
						<div style={{marginLeft: 4}} className="col-6 row  mt-2">
							<Typography
								variant="bodySmBold">
								Colors
							</Typography>
							<ProductColors colors={bike.colorData} />
						</div>
						<div className="col-12  mt-3 mb-2 border-bottom"/>
						<div className="d-flex px-0 justify-content-between">
							<ComparisonCheckboxButton slug={bike.slug}/>
						</div>
					</div>

					{loading ? <BikeCollectionItemLoadingOverlay/> : null}
				</a>
			</Link>
		</>
	)
})

export default CollectionItemListView

CollectionItemListView.displayName = "CollectionItemListView"
