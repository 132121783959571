import React, {useEffect} from "react"
import {useRouter} from "next/router"

const Chat: React.FC = () => {
	const router = useRouter()

	const onConversationsAPIReady = () => {
		// @ts-ignore
		window.HubSpotConversations.widget?.refresh()
	}

	useEffect(() => {
		// @ts-ignore
		if (window.HubSpotConversations) {
			onConversationsAPIReady()
		} else {
			// @ts-ignore
			window.hsConversationsOnReady = [onConversationsAPIReady]
		}
	}, [router.locale])

	return null
}

export default Chat
