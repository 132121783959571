import React, {useEffect, useState} from "react"
import {useTranslation} from "next-i18next"
import PropTypes from "prop-types"
import fetchBikesByIds from "../../fetch/FetchBikesByIds"
import BikesCollectionItem from "../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import {StockStatus} from "./BikeDetails"
import Typography from "../reusable/Typography/Typography"
import Button from "../reusable/components/Button"
import {filterUrl} from "../filter/FilterUrl"
import {colors} from "../../colors/Colors"

type Props = {
  upsellIds: string[]
}

const SoldOut: React.FC<Props> = ({upsellIds}) => {
	const {t} = useTranslation()
	const [bikes, setBikes] = useState([])

	useEffect(() => {
		fetchBikesByIds(upsellIds).then(response => {
			setBikes(response.filter(bike => {
				return bike.stock_status === StockStatus.inStock || bike.stock_status === StockStatus.onBackOrder
			}))
		})
	}, [upsellIds])

	return (
		<div className={"position-absolute top-0 bottom-0 start-0 end-0"} style={{
			zIndex: 9998,
			top: "4.5rem",
			backgroundColor: "rgba(0,0,0,0.5)"
		}}>
			<div className={"container"}>
				<div className={"row bikes-collection"}>
					<div className={"col-12"}>
						<div className={"bg-white mt-5 p-4"} >
							<div className={"text-center"}>
								<Typography variant="heading2" semanticTag="h1">
									{t("Dieses Modell ist leider ausverkauft")}
								</Typography>
								<Typography variant="heading3" semanticTag="h2" className={"mt-4"}>
									{t("Ähnliche Möbel")}{":"}
								</Typography>
								<div className={"row mt-5 justify-content-center position-relative"} style={{minHeight: 510}}>
									{bikes.map(bike => {
										return (
											<>
												<div key={bike.id} className={"col-12 mb-3 d-block d-md-none"}>
													<BikesCollectionItem bike={bike} cardWidth={"100%"} isRowForMobile />
												</div>
												<div key={bike.id} className={"col-12 mb-3 col-md-6 col-lg-3 d-none d-md-block"}>
													<BikesCollectionItem bike={bike} withArrows />
												</div>
											</>
										)
									})}
								</div>
								<div className={"row justify-content-center mt-4"}>
									<div className={"col-12 col-md-4 col-lg-3"}>
										<div className={"d-grid"}>
											<Button label={t("Mehr anzeigen")} href={filterUrl({}, null, null, t)} bgColor={colors.primary}
												color={colors.white} textColor={colors.white}
												spinnerColor={colors.white}/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

SoldOut.propTypes = {
	upsellIds: PropTypes.array.isRequired
}

export default SoldOut
