import React, {useContext} from "react"
import Button from "../../reusable/components/Button"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import styles from "./styles.module.scss"
import {ModalContext} from "../../../context/ModalContext"
import OurReviewModalContent from "../../reusable/ModalsContent/OurReviewModalContent"
import {findBikeMetaFieldByName} from "../../../utility/Helper"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

interface Props {
	bike: any
}

const OurReview = ({bike} :Props) => {
	const {t} = useTranslation()
	const {setModalContent, openModal} = useContext(ModalContext)
	const {reactGA} = useContext(AnalyticsContext)

	const visualsRate = findBikeMetaFieldByName(bike, "visuals-rate")
	const practicalityRate = findBikeMetaFieldByName(bike, "practicality-rate")
	const functionalityRate = findBikeMetaFieldByName(bike, "functionality-rate")
	const rangeRate = findBikeMetaFieldByName(bike, "range-rate")
	const reviewLabel = findBikeMetaFieldByName(bike, "review-label")

	return (
		<>
			{(visualsRate || practicalityRate || functionalityRate || rangeRate) && <div className="d-flex align-items-center">
				<img className="me-2" width={20} height={20} src="/assets/images/hot-pepper-chili.png"/>
				<Button
					paddingX="0"
					isNoUppercase
					icon={<img width={23} height={23} src="/assets/icons/chevron-right-bg-grey.svg"/>}
					iconOnHover={<img width={23} height={23} src="/assets/icons/chevron-right-bg-grey.svg"/>}
					color=""
					spinnerColor="#000"
					className={`rounded-3 d-flex reset col-auto shadow-none ${styles.buttonHover}`}
					opacity={1}
					onClick={() => {
						setModalContent(<OurReviewModalContent visualsRate={visualsRate} practicalityRate={practicalityRate} functionalityRate={functionalityRate} rangeRate={rangeRate} ourReviewQuoteText={reviewLabel}/>)
						openModal()
						reactGA?.event({
							category: gaCategories.pdp,
							action: gaEvents.pdpExpertReviewFeature,
							label: gaEvents.pdpExpertReviewFeature,
							nonInteraction: false
						})
					}}
				>
					<Typography className="me-2 ms-2" style={{fontSize: "16px"}} semanticTag="span" variant="bodySm">{t("see-our-review")}</Typography>
				</Button>
			</div>}
		</>
	)
}

export default OurReview
