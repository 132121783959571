import React from "react"

export type DataContextProviderState = {
	bikeFilters: any[],
	accessoryFilters: any[],
    data: any,
    setData: (data: any) => void,
    bikes: any[],
	collectionBikes: any[],
	setCollectionBikes: (bikesList: any[] | null) => void,
	accessories:any[],
	setAccessories:(accessoriesList:any[] | null)=> void,
	collections:any[],
	setCollections:(collections:any[] | null)=> void,
	brands:any[],
	setBrands:(collections:any[] | null)=> void,
	accessoriesCountByOption: any[],
	bikesCountByOption: any[],
	localisedSlugs: any[],
	isCollectionOrBrandPage: boolean,
	pageProps: any,
	maxAmountBlackFridayBikes: number,
	soldAmountBlackFridayBikes: number,
	isBlackFriday: boolean,
	eventListeners: any,
	productHSList: any[],
	setProductHSList: (productHSList: any[] | null) => void,
	setEventListeners: (eventListeners: any) => void,
	comparisonProducts: string[],
	setComparisonProducts:(comparisonProducts: string[]) => void
	productsCount: number,
	deals: number,
	productsPriceRange: any,
	organization: string | null
}

const DataContext = React.createContext<DataContextProviderState>({
	organization: null,
	productsCount: 0,
	bikeFilters: [],
	accessoryFilters: [],
	data: null,
	setData: () => {
		// Do nothing
	},
	collectionBikes: null,
	setCollectionBikes: () => {
		// Do nothing
	},
	bikes: null,
	accessories: null,
	setAccessories: () => {

	},
	collections: null,
	setCollections: () => {

	},
	brands: null,
	setBrands: () => {
	},
	accessoriesCountByOption: [],
	bikesCountByOption: [],
	localisedSlugs: [],
	isCollectionOrBrandPage: false,
	pageProps: null,
	maxAmountBlackFridayBikes: 300,
	soldAmountBlackFridayBikes: 0,
	isBlackFriday: false,
	eventListeners: null,
	setEventListeners: () => {},
	productHSList: [],
	setProductHSList: () => {},
	comparisonProducts: [],
	setComparisonProducts: () => {
	},
	deals: 0,
	productsPriceRange: null
})

export default DataContext
