import {isProduction} from "../../utility/Helper"

export const firebaseConfig = {
	apiKey: isProduction() ? "AIzaSyAhLfaj5si8sQKbqr708AJ4NMpwAOl9WhE" : "AIzaSyDHS5XjcDpT-aYRNgpBrX5KjWIj_qNXpNM",
	authDomain: isProduction() ? "mybikeplan-305de.firebaseapp.com" : "mybikeplan-staging.firebaseapp.com",
	projectId: isProduction() ? "mybikeplan-305de" : "mybikeplan-staging",
	storageBucket: isProduction() ? "mybikeplan-305de.appspot.com" : "mybikeplan-staging.appspot.com",
	messagingSenderId: isProduction() ? "926895983213" : "374592453115",
	appId: isProduction() ? "1:926895983213:web:fbf600ba16eecdac9e4d64" : "1:374592453115:web:b81d839e7ccb5906d1335e"
}
