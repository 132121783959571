export enum VisibleFilters {
  category = "categories",
  frameVariants = "Rahmenvarianten",
  speed = "Geschwindigkeit",
  brand = "Marke",
  motorBrand = "Motor Marke",
  filtersFarbe = "Filters Farbe",
  bikerHeight = "Körpergrösse",
  availability = "stock_status",
  type = "type",
  accessoryBrand = "accessoryBrand",
  batterieSize = "batterie_size",
  attachments = "Attachments",
  "maxGewicht" = "Max. Gewicht"
}

export enum VisibleFiltersByName {
  "categories" = "category",
  "Marke" = "brand",
  "Filters Farbe" = "filtersFarbe",
  "stock_status" = "availability",
  "instock" = "instock",
  "onbackorder" = "order",
  "Attachments" = "attachments",
  "Max. Gewicht" = "maxGewicht",
  "hex_color" = "hex_color",
  "Sofa" = "sofas",
  "2 Seater" = "zweisitzer",
  "3 Seater" = "dreisitzer",
  "4 Seater" = "viersitzer",
  "6 seater" = "sechssitzer",
  "L Seater" = "grosse-sofas",
  "Outdoor" = "balkon-and-garten",
  "Table" = "tische",
  "Coffee Table" = "couchtisch",
  "Desk" = "schreibtische",
  "Dinning Table" = "esstische",
  "Round Table" = "runde-tische",
  "Bed" = "betten",
  "Dekoration" = "dekoration",
  "Bed Frame" = "bettrahmen",
  "Mattress" = "mattress",
  "Room Type" = "raumtyp",
  "Bathroom" = "bathroom",
  "Bed Room" = "schlafzimmer",
  "Living Room" = "wohnzimmer",
  "Office" = "buro",
  "Add ons" = "zubehor",
  "Arm chair" = "sessel",
  "Chairs" = "stuhle",
  "Deko" = "deko",
  "Light" = "lampen",
  "Small Shelf Add On" = "kleine-regale",
  "Small Table Add On" = "kleine-tische",
  "Aufbewahrung" = "aufbewahrung",
  "Stühle" = "stuhle",
  "Sessel" = "sessel",
  "Bettgestelle" = "bettgestelle",
  "Lampen" = "lampen",
  "Kleine Regale" = "kleine-regale",
  "Kleine Tische" = "kleine-tische",
  "Ecksofas" = "ecksofas",
  "L-Form" = "l-form",
  "Ledersofas" = "ledersofas",
  "Bettsofas" = "bettsofas",
  "Modularsofas" = "modularsofas",
  "Sofa-Set" = "sofa-set",
  "Esstische" = "esstische",
  "Ausziehtische" = "ausziehtische",
  "Couchtische" = "couchtische",
  "Nachttische" = "nachttische",
  "Beistelltische" = "beistelltische",
  "Schreibtische" = "schreibtische",
  "Tisch-Set" = "tisch-set",
  "Esszimmerstühle" = "esszimmerstuhle",
  "Bürostühle" = "burostuhle",
  "Barhocker" = "barhocker",
  "Hocker" = "hocker",
  "Doppelbetten" = "doppelbetten",
  "Einzelbetten" = "einzelbetten",
  "Matratzen" = "matratzen",
  "Bett-Set" = "bett-set",
  "Schränke" = "schranke",
  "Kommoden" = "kommoden",
  "Regale" = "shelf-2",
  "TV-Möbel" = "tv-moebel",
  "Teppiche" = "teppiche",
  "Spiegel" = "spiegel",
  "Gartensofas" = "gartensofas",
  "Gartensessel" = "gartensessel",
  "Gartentische" = "gartentische",
  "Outdoor-Set" = "outdoor-set",
  "Bundle"= "bundle",
}

export enum FilterSlugValues {
  "bikes-normaux" = "bikes",
  "normale-bikes" = "bikes",
  "2023-modelle" = "2023",
  "trekking-e-bike" = "e-trekking",
  "mountain-e-bike" = "e-mountain",
  "city-e-bike" = "e-urban",
  "direkt-verfugbar" = "instock",
  "per-bestellung" = "onbackorder",
  "disponible-maintenant" = "instock",
  "sur-commande" = "onbackorder",
  "kindersitz-halterung" = "child-seat",
  "anhanger-kompatibel" = "trailer-connection",
  "Siège enfant possible" = "child-seat",
  "siege-enfant-possible" = "child-seat",
  "Remorque possible" = "trailer-connection",
  "remorque-possible" = "trailer-connection",
  "kindersitz" = "child-seat-(accessory)",
  "siege-enfant" = "child-seat-(accessory)",
  "schwarz" = "black",
  "noir" = "black",
  "blau" = "blue",
  "bleu" = "blue",
  "grun" = "green",
  "vert" = "green",
  "grau" = "grey",
  "gris" = "grey",
  "rot" = "red",
  "rouge" = "red",
  "jaune" = "yellow",
  "gelb" = "yellow",
  "braun" = "brown",
  "marron" = "brown",
  "weiss" = "white",
  "blanc" = "white"
}
export enum FilterValueBySlug {
  // "city-e-bike" = "E-Urban",
  "auf-lager" = "instock",
  "per-bestellung" = "onbackorder",
  "en-stock" = "instock",
  "sur-commande" = "onbackorder",
  "kindersitz-halterung" = "Child seat",
  "siege-enfant-possible" = "Child seat",
  "anhanger-kompatibel" = "Trailer connection",
  "remorque-possible" = "Trailer connection",
  "kindersitz" = "Child seat (accessory)",
  "siege-enfant" = "Child seat (accessory)"
}
export const accessoriesfilters = ["accessory_type", "accessory_brand"]
