/* eslint-disable no-unused-vars */
import React from "react"
import {AnimationType} from "../components/reusable/components/AnimationComponent/AnimationComponent"

export type ModalContextState = {
    isModalOpen: boolean
    isStoryOpen: boolean
    toggleModal:() => void
    closeModal: () => void
    closeStory: () => void
    openModal: () => void
    openStory: () => void
    startTimeout: (duration?:number) => void
    cancelTimeout: () => void
    setModalContent: (content: React.ReactNode) => void
    setStoryContent: (content: React.ReactNode) => void
    setModalModalAnimationType: (type: keyof typeof AnimationType) => void
}

export const ModalContext = React.createContext<ModalContextState>({
	isModalOpen: false,
	isStoryOpen: false,
	toggleModal: () => {},
	closeModal: () => {},
	closeStory: () => {},
	openModal: () => {},
	openStory: () => {},
	startTimeout: () => {},
	cancelTimeout: () => {},
	setModalContent: () => {},
	setStoryContent: () => {},
	setModalModalAnimationType: () => {}
})
