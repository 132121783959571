import {useEffect, useState} from "react"

function useHandleDownPayment(originalPrice: number) {
	const [downPayment, setDownPayment] = useState(0)

	const listenStorageChange = () => {
		const downPayment = parseFloat(localStorage.getItem("totalDownPayment")) || 0
		if (downPayment >= 0 && downPayment <= originalPrice) {
			setDownPayment(downPayment)
		} else if (downPayment > originalPrice) {
			setDownPayment(originalPrice)
		}
	}

	useEffect(() => {
		if (typeof window !== "undefined") {
			listenStorageChange()
			window.addEventListener("storage", listenStorageChange)
		}

		return () => window.removeEventListener("storage", listenStorageChange)
	}, [])

	return {downPayment}
}

export default useHandleDownPayment
