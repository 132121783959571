import {getBaseUrl} from "../api/getBaseUrl"

const fetchVariations = async (productId, locale = "de") => {
	try {
		const variations = await fetch(`${getBaseUrl()}/api/products/variations?productId=${productId}&locale=${locale}&status=publish`)
		return await variations.json()
	} catch (e) {
		console.error(e)
		return []
	}
}

export default fetchVariations
