/* eslint-disable camelcase */
/* eslint-disable complexity */
/* eslint-disable camelcase */
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import Slider from "../../slider/Slider"
import CheckoutContext from "../../../context/CheckoutContext"
import Typography from "../../reusable/Typography/Typography"
import {useRouter} from "next/router"
import {useTranslation} from "next-i18next"
import {ChevronDown, ChevronUp} from "react-bootstrap-icons"
import AnalyticsContext from "../../../context/AnalyticsContext"
import CartContext from "../../../context/CartContext"
import RegularDurationLabels from "./RegularDurationLabels"
import OnSaleDurationLabels, {SaleTypes} from "./OnSaleDurationLabels"
import {getResultingOnSalePrice} from "../../../utility/helpers/getResultingOnSalePrice"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {getDiscountData} from "../../../config/discountsConfig"
import {customPlanLengthMap} from "./customPlanLengthMap"
import Checkbox from "../../reusable/components/Checkbox/Checkbox"

// The plan is to add an inital price then creates the logic witht he slider in case of on sale,
// then it updates in checkout data the prices
// then it updates the price in the bike details page
// then it updates the price in the bike listings page ?

type Props = {
    isSecondHand?: boolean;
	isMobile?: boolean,
	lockOnOneTimePayment?: boolean,
    hasHeader?: boolean,
    showByDefault?: boolean,
    payOneTime?: boolean,
    setBikePrice?: Dispatch<SetStateAction<number>>
    isOnSale?: boolean,
	initialPrice?: number,
	hidePayOneTimeLabel?: boolean
    saleType?: keyof typeof SaleTypes
}

enum PathNameValue {
  produkt = "produkt",
  checkout = "checkout"
}

const Plan: React.FC<Props> = ({saleType, initialPrice, isOnSale, hidePayOneTimeLabel = false, setBikePrice, isSecondHand = false, hasHeader = false, isMobile, lockOnOneTimePayment = false, showByDefault = true, payOneTime}) => {
	const {data, setData} = useContext(CheckoutContext)
	const {items, updateItem} = useContext(CartContext)
	const {t} = useTranslation(["common", "filters"])
	const checkoutData = data
	const setCheckoutData = setData
	const router = useRouter()
	const {pathname} = router
	const isProductPage = pathname.includes(PathNameValue.produkt)
	const isCheckoutPage = pathname.includes(PathNameValue.checkout)
	const durationInMonth = isSecondHand ? ["pay-one-time", 3, 6, 9, 12] : (process.env.NEXT_PUBLIC_MAX_PLAN_LENGTH === "36" ? ["pay-one-time", 12, 24, 36] : ["pay-one-time", 12, 24, 36, 48])
	const [isOpen, setIsOpen] = useState(showByDefault)
	const {reactGA} = useContext(AnalyticsContext)
	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]

	useEffect(() => {
		if (checkoutData && !payOneTime) {
			if (isSecondHand) {
				if (checkoutData?.planLength > 12) {
					setCheckoutData(prevData => {
						return ({
							...prevData,
							planLength: 12
						})
					})
				}
			}

			if (!isSecondHand) {
				if (checkoutData?.planLength < 12) {
					setCheckoutData(prevData => {
						return ({
							...prevData,
							planLength: 48
						})
					})
				}
			}
		}
	}, [checkoutData, isSecondHand])

	const handleGAaction = (value:any) => {
		reactGA.event({
			category: gaCategories.planLength,
			action: isSecondHand ? (value === 3 ? (isProductPage ? gaEvents.planLength3 : isCheckoutPage ? gaEvents.setPlanLengthCart : gaEvents.planLengthProductListingsPage3) :
				value === 6 ? (isProductPage ? gaEvents.planLength6 : isCheckoutPage ? gaEvents.setPlanLengthCart : gaEvents.planLengthProductListingsPage6) :
					value === 9 ? (isProductPage ? gaEvents.planLength9 : isCheckoutPage ? gaEvents.setPlanLengthCart : gaEvents.planLengthProductListingsPage9) :
						(isProductPage ? gaEvents.planLength12ForUsed : isCheckoutPage ? gaEvents.setPlanLengthCart : gaEvents.planLengthProductListingsPage12ForUsed)) :
				(value === 12 ? (isProductPage ? gaEvents.planLength12 : isCheckoutPage ? gaEvents.planLengthCart12 : gaEvents.planLengthProductListingsPage12) :
					value === 24 ? (isProductPage ? gaEvents.planLength24 : isCheckoutPage ? gaEvents.planLengthCart24 : gaEvents.planLengthProductListingsPage24) :
						value === 36 ? (isProductPage ? gaEvents.planLength36 : isCheckoutPage ? gaEvents.planLengthCart36 : gaEvents.planLengthProductListingsPage36) :
							(isProductPage ? gaEvents.planLength48 : isCheckoutPage ? gaEvents.planLengthCart48 : gaEvents.planLengthProductListingsPage48)),
			label: `Set Plan Length ${value}${isProductPage ? "Product Page" : isCheckoutPage ? "Checkout Page" : "Listing Page"}`,
			value,
			nonInteraction:
              false
		})
	}

	return (
		<div className={"mb-md-0 pb-3 pb-md-0"}>
			{hasHeader ?
				<div className="d-flex d-block d-md-none cursor-pointer align-items-start justify-content-between" onClick={() => {
					setIsOpen(prevState => !prevState)
				}}>
					<div>
						<Typography variant="bodyLgBold" semanticTag="span" className="mb-1">{t("plan-duration")}</Typography>
						<Typography variant="bodyXSm" semanticTag="span" style={{color: "#6C7074", marginLeft: 2}}
							className="mb-1">({t("Monate")})</Typography>
						<Typography variant="bodyXSm" semanticTag="div" style={{color: "#6C7074"}}
							className="mt-1">{planLengthMap[data.planLength]} {t("Monate")}</Typography>
					</div>
					<div className={"col-auto"}>
						{isOpen ? <ChevronUp/> : <ChevronDown/>}
					</div>
				</div> : null
			}

			{isOnSale ? <OnSaleDurationLabels
				setBikePrice={setBikePrice}
				t={t}
				isMobile={isMobile}
				isOpen={isOpen}
				payOneTime={payOneTime}
				durationInMonth={durationInMonth}
				isOnSale={isOnSale}
				initialPrice={initialPrice}
				hidePayOneTimeLabel={hidePayOneTimeLabel}
				isSecondHand={isSecondHand}
				isCheckoutPage={isCheckoutPage}
				saleType={saleType}
			/> : <RegularDurationLabels
				checkoutData={checkoutData}
				setCheckoutData={setCheckoutData}
				t={t}
				hidePayOneTimeLabel={hidePayOneTimeLabel}
				isMobile={isMobile}
				isOpen={isOpen}
				payOneTime={payOneTime}
				durationInMonth={durationInMonth}
			/>
			}
			<div className={isMobile && `accordion-collapse collapse ${isOpen ? "show" : null}`}>
				<Slider defaultValue={isSecondHand ? checkoutData?.planLength || 12 : checkoutData?.planLength || 36}
					step={isSecondHand ? 3 : 12}
					disabled={checkoutData?.planLength === 1 && hidePayOneTimeLabel}
					min={payOneTime && !hidePayOneTimeLabel ? 0 : isSecondHand ? 3 : 12}
					max={isSecondHand ? 12 : (process.env.NEXT_PUBLIC_MAX_PLAN_LENGTH ? parseInt(process.env.NEXT_PUBLIC_MAX_PLAN_LENGTH, 10) : 48)}
					onChange={value => {
						handleGAaction(value)
						setCheckoutData(prevData => {
							return ({
								...prevData,
								deals: prevData.deals.map((deal, i) => {
									if ((isOnSale && !isSecondHand) ||
										(deal.isOnSale && !isSecondHand)) {
										const item = items.find(item => item.uniqueId === deal.uniqueId)

										if (item) {
											const individualSaleType = getDiscountData(item.product)?.discountKey
											updateItem(item, {
												...item,
												product: {
													...item.product,
													price: getResultingOnSalePrice(isOnSale ? initialPrice ? initialPrice : deal.initialPrice : deal.initialPrice, value, individualSaleType)
												}
											})
										}

										return ({
											...deal,
											e_bike_price: getResultingOnSalePrice(isOnSale ? initialPrice ? initialPrice : deal.initialPrice : deal.initialPrice, value, saleType)
										})
									}

									return deal
								}),
								planLength: value === 0 ? 1 : value
							})
						})

						if (isOnSale && !isSecondHand && !isCheckoutPage && setBikePrice) {
							setBikePrice(getResultingOnSalePrice(initialPrice, value, saleType))
						}
					}} onAfterChange={value => {
						setCheckoutData(prevData => {
							return ({
								...prevData,
								deals: prevData.deals.map((deal, i) => {
									if ((isOnSale && !isSecondHand) ||
									(deal.isOnSale && !isSecondHand)) {
										const item = items.find(item => item.uniqueId === deal.uniqueId)

										if (item) {
											const individualSaleType = getDiscountData(item.product)?.discountKey
											updateItem(item, {
												...item,
												product: {
													...item.product,
													price: getResultingOnSalePrice(isOnSale ? initialPrice ? initialPrice : deal.initialPrice : deal.initialPrice, value, individualSaleType)
												}
											})
										}

										return ({
											...deal,
											e_bike_price: getResultingOnSalePrice(isOnSale ? initialPrice ? initialPrice : deal.initialPrice : deal.initialPrice, value, saleType)
										})
									}

									return deal
								}),
								planLength: value === 0 ? 1 : value
							})
						})
						if (isOnSale && !isSecondHand && !isCheckoutPage && setBikePrice) {
							setBikePrice(getResultingOnSalePrice(initialPrice, value, saleType))
						}
					}}/>
			</div>
			{hidePayOneTimeLabel && <label data-cyperess="cp-slider" className="mt-3">
				<div data-cypress-checked={`${checkoutData?.planLength}`} />
				<Checkbox label={t("pay-one-time")} isActive={checkoutData?.planLength === 1} onChange={() => setCheckoutData(prevData => {
					return ({
						...prevData,
						planLength: prevData.planLength === 1 && !lockOnOneTimePayment ? 12 : 1
					})
				})
				} /></label>}
		</div>

	)
}

Plan.propTypes = {}

export default Plan
