export type Category = {
    id: number
    name: string
    slug: string
}

export const mainCategories = [
	{
		name: "E-Trekking",
		slug: "e-trekking"
	},
	{
		name: "E-Mountain",
		slug: "e-mountain"
	},
	{
		name: "E-Urban",
		slug: "e-urban"
	},
	{
		name: "Add-ons",
		slug: "add-ons"
	},
	{
		name: "Accessories",
		slug: "accessories-fr"
	},
	{
		name: "Bikes",
		slug: "bikes"
	},
	{
		name: "Bikes",
		slug: "bikes-fr"
	}
]

const getMainCategory = categories => {
	for (let i = 0; i < mainCategories.length; i++) {
		const _mainCategory = categories.find(e => e.slug === mainCategories[i].slug)
		if (_mainCategory) {
			return _mainCategory
		}
	}

	return null
}

export default getMainCategory
