import {JSONHeaders} from "./fetchConfig"

export const fetchPost = async (url:string, body:any, apiKey = null, headers = null) => {
	const _headers = apiKey ? {
		...JSONHeaders,
		"x-api-key": apiKey
	} : JSONHeaders

	const __headers = headers ? {
		..._headers,
		...headers
	} : _headers

	return fetch(url, {
		method: "post",
		headers: __headers,
		body: body ? JSON.stringify(body) : null
	})
}
