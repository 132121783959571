/* eslint-disable capitalized-comments */
/* eslint-disable camelcase */
import {CartItem} from "../context/CartContext"
import {Deal, DealPipelines, DealStages} from "../context/CheckoutContext"
import {findBikeAttributeByName, findBikeMetaFieldByName, isOccasionBike} from "../utility/Helper"
import {getBikeCategoriesSlugs} from "../components/filter/config/Categories"
import {generateUUID} from "../utility/UUID"

export const cartItemToDeal = (cartItem: CartItem, planLength: number, discountCode: string) => {
	const {name, price, sku, expected_delivery_date__website_, id} = cartItem.product
	const frame = findBikeAttributeByName(cartItem.product, "Rahmenvarianten", true)
	const color = findBikeAttributeByName(cartItem.product, "Farbe", true)
	const isOccasion = isOccasionBike(cartItem.product)
	const frameNumber = findBikeMetaFieldByName(cartItem.product, "frame_number")

	const mainCategoriesSlugs = getBikeCategoriesSlugs()
	let category = null
	cartItem.product.categories.forEach(bikeCategory => {
		if (mainCategoriesSlugs.includes(bikeCategory.slug)) {
			category = bikeCategory
		}
	})
	const e_bike_stock_information = cartItem.product.stock_status === "instock" ? "In stock" : "On order"
	const bikeId = id
	const brand = findBikeAttributeByName(cartItem.product, "Marke", true) || findBikeAttributeByName(cartItem.product, "accessory_brand", true)
	const logitics_company = logisticCompany(brand)

	return {
		/* eslint-disable camelcase */
		e_bike_frame: frame,
		e_bike_choice: name,
		e_bike_size: cartItem.selectedSize,
		e_bike_color: color,
		e_bike_price: price,
		plan_length: planLength,
		article_number_2: sku,
		amount: price,
		dealname: null,
		pipeline: DealPipelines.default,
		dealstage: DealStages.appointmentscheduled,
		discount_code: discountCode,
		e_bike_category: category?.name,
		expected_delivery_date__website_,
		// hs_analytics_source_data_1,
		// hs_analytics_source,
		e_bike_stock_information,
		logitics_company,
		bikeId,
		brand,
		isOccasion: isOccasion ? "true" : "false",
		frame_number: isOccasion ? frameNumber : null,
		selected_add_on: cartItem.selectedAddOn,
		insurance_selected: "false",
		insurance_price: 0,
		free_insurance: "false",
		down_payment_amount: cartItem?.downPayment,
		checkout_deal_id: generateUUID(),
		uniqueId: cartItem.uniqueId || null,
		isOnSale: cartItem.isOnSale || false,
		initialPrice: cartItem.initialPrice || null
		/* eslint-enable camelcase */
	} as Deal
}

const sportToGoBrands = [
	""
]

const egoMovementBrands = [
	""
]

export const zibatraBrands = [
	"Bergstrom",
	"Liv",
	"Giant",
	"Viking",
	"Kalkhoff",
	"Focus",
	"Allegro",
	"Cilo",
	"Stromer",
	"Ruff Cycles",
	"Ruff Cycle",
	"Simpel",
	"Ghost",
	"Haibike",
	"Benno",
	"Flyer",
	"Fischer",
	"Bird",
	"R Raymon",
	"HNF Nicolai",
	"Electra",
	"Winora"
]

const logisticCompany = (marke: string): string => {
	if (sportToGoBrands.includes(marke)) {
		return "Sport2Go"
	}

	if (egoMovementBrands.includes(marke)) {
		return "EgoMovement"
	}

	if (zibatraBrands.includes(marke)) {
		return "Zibatra"
	}

	return "Zibatra"
}
