/* eslint-disable camelcase */
import React, {useState} from "react"
import {Field, Form, Formik} from "formik"
import * as Yup from "yup"
import {formSuggestHandler} from "../../utility/postHandler"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"

interface MyFormValues {
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    requested_bike_brand: string,
    requested_bike_deadline: string,
}

const schema = Yup.object().shape({
	firstname: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	lastname: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	email: Yup.string()
		.email("Invalid email")
		.required("Required"),
	phone: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	requested_bike_brand: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	requested_bike_deadline: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required")
})

const SuggestionForm = () => {
	const {t} = useTranslation()
	const [formSubmitted, setFormSubmitted] = useState(false)
	const initialValues: MyFormValues = {
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		requested_bike_brand: "",
		requested_bike_deadline: ""
	}

	const router = useRouter()
	const {locale} = router
	const instanceId = locale === "de" ? process.env.NEXT_PUBLIC_FORM_SUGGESTION_DE :
		locale === "fr" ? process.env.NEXT_PUBLIC_FORM_SUGGESTION_FR :
			process.env.NEXT_PUBLIC_FORM_SUGGESTION_DE

	return (
		<>
			{formSubmitted ?
				<h1>{t("Vielen Dank für deine Nachricht! Unser Team wird so schnell wie möglich mit dir Kontakt aufnehmen, damit ihr zusammen das für dich beste E-Bike finden könnt.")}{"😎"}</h1> : (
					<Formik
						initialValues={initialValues}
						validationSchema={schema}
						onSubmit={async (values, actions) => {
							const obj = {
								formId: process.env.NEXT_PUBLIC_FORM_ID,
								instanceId,
								data: values
							}
							const response = await formSuggestHandler(obj)

							if (response.status === 200) {
								setFormSubmitted(true)
							}

							actions.setSubmitting(false)
						}}
					>
						{({errors, touched}) => (

							<Form className="col-12 m-0 p-0 p-md-3 m-md-5">
								<div className="row">
									<div className="col-12">
										<label className="col-12 bg-white text-dark"
											htmlFor="requested_bike_brand">{t("Welches E-Bike suchst du?")}</label>
										<div className="border-bold rounded mb-3 pt-3">
											<Field className="col-12 transparent-input text-dark"
												id="requested_bike_brand"
												name="requested_bike_brand"></Field>
											{errors.requested_bike_brand && touched.requested_bike_brand ? (
												<div>{errors.requested_bike_brand}</div>
											) : null}
										</div>
									</div>
									<div className="col-12">
										<label className="col-12 bg-white text-dark"
											htmlFor="requested_bike_deadline">{t("Wann brauchst du dein E-Bike?")}</label>
										<div className="border-bold rounded mb-3 pt-3">
											<Field className="col-12 transparent-input text-dark"
												id="requested_bike_deadline" name="requested_bike_deadline"></Field>
											{errors.requested_bike_deadline && touched.requested_bike_deadline ? (
												<div>{errors.requested_bike_deadline}</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-12">
										<h2 className="text-reg text-bold">
											{t("Wo dürfen wir uns melden, wenn dein E-Bike verfügbar ist.")}
										</h2>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-12 col-lg-6">
										<div className="border-bold rounded mb-3 pt-3 position-relative">
											<label className="label-absolute top-0 col-12 bg-white text-dark text-center"
												htmlFor="firstname">{t("Vorname")}</label>
											<Field className="col-12 transparent-input text-dark" id="firstname"
												name="firstname"></Field>
											{errors.firstname && touched.firstname ? (
												<div>{errors.firstname}</div>
											) : null}
										</div>
									</div>
									<div className="col-12 col-lg-6">
										<div className="border-bold position-relative rounded mb-3 pt-3">
											<label className="label-absolute top-0 col-12 bg-white text-dark text-center"
												htmlFor="lastname">{t("Nachname")}</label>
											<Field className="col-12 transparent-input text-dark" id="lastname"
												name="lastname"></Field>
											{errors.lastname && touched.lastname ? (
												<div>{errors.lastname}</div>
											) : null}
										</div>
									</div>
								</div>
								<div className="row mt-0">
									<div className="col-12 col-lg-6">
										<div className="border-bold position-relative rounded mb-3 pt-3">
											<label className="label-absolute top-0 col-12 bg-white text-dark text-center"
												htmlFor="email">{t("email")}</label>
											<Field className="col-12 transparent-input text-dark" id="email"
												name="email"></Field>
											{errors.email && touched.email ? (
												<div>{errors.email}</div>
											) : null}
										</div>
									</div>
									<div className="col-12 col-lg-6">
										<div className="border-bold position-relative rounded mb-3 pt-3">
											<label className="label-absolute top-0 col-12 bg-white text-dark text-center"
												htmlFor="phone">{t("phone")}</label>
											<Field className="col-12 transparent-input text-dark" id="phone"
												name="phone"></Field>
											{errors.phone && touched.phone ? (
												<div>{errors.phone}</div>
											) : null}
										</div>
									</div>
								</div>
								<div className={"row mt-3"}>
									<div className={"col-12"}>
										<div className={"d-grid"}>
											<button type="submit"
												className="btn btn-primary btn-lg">{t("Absenden")}</button>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>

				)}
		</>
	)
}

export default SuggestionForm
