export const handleGAInitialize = async (analytics, uuid, setAnalytics, setClientId) => {
	const ReactGA = (await import("react-ga4")).default
	const {isInitialized} = analytics
	if (!isInitialized) {
		ReactGA.initialize([
			{
				trackingId: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
				gaOptions: {
					userId: uuid
				}
			}
		])

		setAnalytics(prev => ({
			...prev,
			isInitialized: true
		}))

		// In case we dont have the user initially,
		// we handle setting a user in our tracker
	} else if (isInitialized) {
		setAnalytics(prev => ({
			...prev
		}))
	}

	return ReactGA
}
