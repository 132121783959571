import BikeDescriptionSection from "./BikeDescriptionSection"
import React from "react"

type Props = {
  variant:any
accordionContent:any
slug:any
tab:any
t:any
active:any
setActive:any
isSecondHand:any
bike:any
isAccessory:any
}

const ProductInfo: React.FC<Props> = ({variant,
	accordionContent,
	slug,
	tab,
	t,
	active,
	setActive,
	isSecondHand,
	bike,
	isAccessory}) => {
	return (
		<>
			<BikeDescriptionSection bike={bike} multiple noDividerBeforeBody variant={"B"} accordionContent={accordionContent} slug={bike.slug} tab={tab}
				t={t} active={active} setActive={setActive} isForPDP/>
		</>
	)
}

export default ProductInfo
