import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import AnalyticsContext from "../context/AnalyticsContext"
import {generateUUID} from "../utility/UUID"
import {handleGAInitialize} from "../googleAnalytics/dynamicImportHandlers"

type Props = {
    children: React.ReactNode
}

const AnalyticsItems = {
	uuid: "uuid"
}

const retrieveUuid = (): string | null => {
	const uuid = localStorage.getItem(AnalyticsItems.uuid)
	if (uuid) {
		return uuid
	}

	const newUuid = generateUUID()
	storeUuid(newUuid)
	return newUuid
}

const storeUuid = (uuid: string | null) => {
	return localStorage.setItem(AnalyticsItems.uuid, uuid)
}

const AnalyticsContextProvider: React.FC<Props> = ({children}) => {
	const [uuid, setUuid] = useState<string | null>()
	const [clientId, setClientId] = useState<string | null>()
	const [analytics, setAnalytics] = useState({
		isInitialized: false
	})
	const [reactGA, setReactGA] = useState(null)

	useEffect(() => {
		setUuid(retrieveUuid())
	}, [])

	useEffect(() => {
		if (!uuid) {
			handleGAInitialize(analytics, uuid, setAnalytics, setClientId).then(ReactGA => {
				setReactGA(ReactGA)

				// Track pageviews
				ReactGA.send({hitType: "pageview", page: `${window.location.pathname}${window.location.search}`})

				// Store client ID
				ReactGA.ga(tracker => {
					setClientId(tracker.get("clientId"))
				})
			})
		}
	}, [uuid])

	return (
		<AnalyticsContext.Provider value={{uuid, setUuid, clientId, reactGA}}>
			{children}
		</AnalyticsContext.Provider>
	)
}

AnalyticsContextProvider.propTypes = {
	children: PropTypes.node
}

export default AnalyticsContextProvider
