import React, {useContext} from "react"
import PropTypes from "prop-types"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import slugify from "slugify"
import Link from "next/link"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import {useRouter} from "next/router"
import AnalyticsContext from "../../context/AnalyticsContext"

type Props = {
    label: string,
	slug?: string,
	isActive: boolean
	setActive: (tabName: string)=>void
	urlBase: string
}

const TabItem: React.FC<Props> = ({label, slug, isActive, urlBase, setActive}) => {
	const {t} = useTranslation()
	const router = useRouter()
	const {pathname} = router
	const {reactGA} = useContext(AnalyticsContext)

	const handleClick = () => {
		setActive(slugify(slug || t(label)).toLowerCase())
	}

	const handleGAEvent = () => {
		if (pathname.includes("produkt")) {
			reactGA?.event({
				category: gaCategories.pdp,
				action: gaEvents.descriptionSection,
				label,
				nonInteraction: false
			})
		}
	}

	return (
		<li className="nav-item text-center text-dark">
			<Link href={{pathname: urlBase, query: {tab: slugify(slug || label).toLowerCase()}}} scroll={false} shallow = {true}>
				{/* <Link href={`${urlBase}/${slugify(`${label.toLowerCase()}`)}`} scroll={false} shallow = {true}> */}
				<a onClick={() => {
					handleClick()
					handleGAEvent()
				}} className={`nav-link border-0 text-dark ps-0 pe-0 ${isActive ? "active border-primary border-bottom border-4" : null}`}>
					<Typography variant="heading3" semanticTag="span" className={`${isActive ? "text-dark" : "text-dark"}`}>
						{label}
					</Typography>
				</a>
			</Link>
		</li>
	)
}

TabItem.propTypes = {
	label: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	setActive: PropTypes.func.isRequired,
	urlBase: PropTypes.string.isRequired
}

export default TabItem
