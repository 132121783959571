import {useRouter} from "next/router"
import React, {useContext} from "react"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {ModalContext} from "../../../context/ModalContext"
import Button, {Color} from "../components/Button"
import BookAppointmentModalContent from "../ModalsContent/BookAppointmentModalContent"
import Image from "next/image"
import Typography from "../Typography/Typography"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {colors} from "../../../colors/Colors"

type Props = {
  label?: string
  color?: keyof typeof Color | string
  textColor?: keyof typeof Color | string
  hoverColor?: keyof typeof Color | string
  bgColor?: keyof typeof Color | string
  fontSizeSmall?: boolean,
  outline?: boolean,
  high?: boolean,
  padding?: string,
  header?: boolean,
  plp?: boolean,
  icon?: React.ReactNode,
  className?: string,
  onlyIcon?: boolean,
  linkWithIcon?: boolean,
  render?: (onClick: () => void) => React.ReactElement,
	isListView?: boolean
	iconFirst?: boolean
}

const BookAppointmentButton = ({
	onlyIcon,
	className,
	plp,
	header,
	label,
	render,
	color = "#212529",
	textColor = "#fff",
	bgColor,
	hoverColor,
	fontSizeSmall,
	outline,
	high,
	padding,
	linkWithIcon = false,
	icon,
	isListView,
	iconFirst
}: Props): React.ReactElement => {
	const {setModalContent, openModal, closeModal} = useContext(ModalContext)

	const router = useRouter()
	const pathName = router.pathname.split("/")[1] ? router.pathname.split("/")[1] : ""
	const {reactGA} = useContext(AnalyticsContext)
	const {primary} = colors

	const handleClick = () => {
		if (header) {
			if (pathName === "produkt") {
				reactGA?.event({
					category: gaCategories.consultation,
					action: gaEvents.pdp,
					label: `${gaCategories.consultation} - ${gaEvents.pdp}`,
					nonInteraction: false
				})
			} else if (pathName === "blog") {
				reactGA?.event({
					category: gaCategories.consultation,
					action: gaEvents.blog,
					label: `${gaCategories.consultation} - ${gaEvents.blog}`,
					nonInteraction: false
				})
			} else {
				reactGA?.event({
					category: gaCategories.consultation,
					action: gaEvents.headers,
					label: `${gaCategories.consultation} - ${gaEvents.headers}`,
					nonInteraction: false
				})
			}
		}

		if (plp) {
			reactGA?.event({
				category: gaCategories.consultation,
				action: gaEvents.plp,
				label: `${gaCategories.consultation} - ${gaEvents.plp}`,
				nonInteraction: false
			})
		}

		setModalContent(<BookAppointmentModalContent closeModal={closeModal}/>)
		openModal()
	}

	return (render ?
		render(handleClick) : onlyIcon ?
			<div className={"btn d-flex align-items-center p-2 cursor-pointer"} onClick={handleClick}>
				<Image src={"/assets/icons/calendar-consultation.svg"} height={24} width={24} style={{
					top: 3
				}}/>
			</div> :
			linkWithIcon ?
				<div className="d-flex mt-1 align-items-center cursor-pointer" onClick={handleClick}>
					<Typography variant={isListView ? "bodyLgBold" : "heading3"} semanticTag={isListView ? "span" : "h3"}>{label}</Typography>
					<div className="d-flex justify-content-center align-items-center"
						style={{width: 20, height: 20, background: primary, borderRadius: "50%", marginLeft: 10}}>
						<Image src={"/assets/icons/arrow-right-mob.svg"} width={6.5} height={12}/>
					</div>
				</div> :
				<Button className={`w-100 ${className ? className : ""}`} icon={icon} padding={padding} high={high}
					onClick={handleClick} bgColor={bgColor} fontSizeSmall={fontSizeSmall}
					textColor={textColor}
					color={color}
					outline={outline} iconFirst={iconFirst}
					label={label} />

	)
}

export default BookAppointmentButton
