import React from "react"

interface toastMessageProps {
    setToastMessageContent: React.Dispatch<React.SetStateAction<null|React.ReactNode>>
    isToastMessageDisplayed: boolean
    setIsToastMessageDisplayed: React.Dispatch<React.SetStateAction<boolean>>
}

const ToastMessageContext = React.createContext<toastMessageProps>({
	setToastMessageContent: () => {},
	isToastMessageDisplayed: false,
	setIsToastMessageDisplayed: () => {}

})

export default ToastMessageContext
