import React from "react"
import PropTypes from "prop-types"
import Link from "next/link"

type Props = {
    item: any
}

const RichTextHyperlink = ({item}: Props) => {
	const {content, data} = item
	const text = content.find(item => item.nodeType === "text")?.value

	return (
		<Link href={data.uri}>
			{text}
		</Link>
	)
}

RichTextHyperlink.propTypes = {
	item: PropTypes.any.isRequired
}

export default RichTextHyperlink
