import React, {useState} from "react"
import AccordionItem from "./AccordionItem"
import PropTypes from "prop-types"
import {useRouter} from "next/router"
import slugify from "slugify"
import {useTranslation} from "next-i18next"

const Accordion = ({
	accordionItemsContent,
	multiple,
	noDividerBeforeBody,
	current,
	urlBase,
	plusIcon,
	grey,
	accItemsClasses,
	translate,
	activeHighlighted,
	bike,
	isForPDP,
	defaultActiveTitles
}) => {
	const t = translate ? translate : useTranslation().t
	const router = useRouter()

	// Changed to handle multiple default open tabs, defined in parent
	const [defaultActive, setDefaultActive] = useState(() => {
		const defaultActiveMap = {}
		if (defaultActiveTitles.length > 0) {
			defaultActiveTitles.forEach(title => {
				defaultActiveMap[slugify(title)] = true
			})
			return defaultActiveMap
		}

		defaultActiveMap[slugify(accordionItemsContent[0]?.slug || t(accordionItemsContent[0]?.title)).toLowerCase()] = true
		return defaultActiveMap
	})

	const onToggle = title => {
		if (defaultActive[slugify(title)]) {
			// If the item is currently active, close it
			if (urlBase) {
				router.push({pathname: urlBase}, undefined, {
					shallow: true,
					scroll: false
				})
			}
		} else if (urlBase) {
			// If the item is not currently active, open it
			router.push(
				{pathname: urlBase, query: {tab: slugify(title)}},
				undefined,
				{shallow: true, scroll: false}
			)
		}
	}

	return (
		<div className="accordion accordion-flush d-grid gap-3" id="accordionFlush">
			{accordionItemsContent.map(({title, slug, content, titleContent}) => {
				const contentToRender =
					typeof content === "string" ? t(content) : content

				return (
					<AccordionItem
						key={title}
						active={defaultActive[slugify(title)]}
						title={t(title)}
						slug={slug}
						onToggle={onToggle}
						multiple={multiple}
						plusIcon={plusIcon}
						grey={grey}
						noShadow={true}
						accItemsClasses={accItemsClasses}
						titleContent={titleContent}
						activeHighlighted={activeHighlighted}
						noDividerBeforeBody={noDividerBeforeBody}
						bike={bike}
						isForPDP
					>
						{contentToRender}
					</AccordionItem>
				)
			})}
		</div>
	)
}

Accordion.propTypes = {
	accordionItemsContent: PropTypes.array.isRequired,
	current: PropTypes.any,
	noDividerBeforeBody: PropTypes.any,
	multiple: PropTypes.bool,
	activeHighlighted: PropTypes.bool,
	urlBase: PropTypes.string,
	plusIcon: PropTypes.bool,
	grey: PropTypes.bool,
	accItemsClasses: PropTypes.string,
	translate: PropTypes.func,
	bike: PropTypes.any,
	isForPDP: PropTypes.bool,
	defaultActiveTitles: PropTypes.array
}

export default Accordion
