import React, {useContext, useState} from "react"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import PropTypes from "prop-types"
import styles from "./bikeDetails.module.scss"
import PDPTooltip from "./PDPTooltip"
import {ModalContext} from "../../context/ModalContext"
import ConsultBookingModalContent from "../reusable/ModalsContent/ConsultBookingModalContent"

type Props = {
  setAdditionalInfoDrawer: (state: boolean) => void
}
const InfoBox: React.FC<Props> = ({setAdditionalInfoDrawer}) => {
	const {t} = useTranslation("productDescrPage")
	const {setModalContent, openModal, closeModal} = useContext(ModalContext)
	const [isOpen, setIsOpen] = useState(false)
	const [activeTooltip, setActiveTooltip] = useState("")
	const handleTooltipClick = label => {
		setIsOpen(!isOpen)
		setActiveTooltip(label)
	}

	return (
		<>
			<div className={"col-12"}>
				<div className={"border-top mb-3 mb-md-0"}/>
			</div>
			<div className={"pt-sm-4 pb-sm-3 py-4 py-md-0 rounded py-md-4  pe-md-4 mb-3"}>

				<div className="row px-2 px-md-2">
					<div className="col-12 mb-1">
						<div className="d-md-row d-flex gx-3 align-items-center justify-content-md-start">
							<div className="col-11 d-flex align-items-center">
								<div className=" col-auto me-2 d-md-none d-flex">
									<Image className="cursor-pointer" src={process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "/assets/icons/delivery-myhomeplan.svg" : "/assets/icons/usp-delivery.svg"} alt="car icon" width={24} height={24}/>
								</div>
								<div className=" col-2 d-none d-md-block">
									<Image className="cursor-pointer" src={process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "/assets/icons/delivery-myhomeplan.svg" : "/assets/icons/usp-delivery.svg"} alt="car icon" width={40} height={40}/>
								</div>
								<div className="col-auto d-flex align-items-center gap-3 justify-content-evenly">
									<Typography variant="bodySm" semanticTag="span">{t("free-delivery")} </Typography>
									{isOpen && activeTooltip === "service-shipping" ?
										<Image className="cursor-pointer d-none d-md-block" onClick={() => handleTooltipClick("service-shipping")}
											src={process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "/assets/icons/info-active-brown.svg" : "/assets/icons/info-active.svg"} alt="tools icon" width={24} height={24}/> :
										<Image className="cursor-pointer d-none d-md-block" onClick={() => handleTooltipClick("service-shipping")}
											src={"/assets/icons/info-transparent.svg"} alt="tools icon" width={24} height={24}/>
									}
								</div>
							</div>
							{isOpen && activeTooltip === "service-shipping" ?
								<Image className="cursor-pointer d-block d-md-none" onClick={() => handleTooltipClick("service-shipping")}
									src={process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "/assets/icons/info-active-brown.svg" : "/assets/icons/info-active.svg"} alt="tools icon" width={24} height={24}/> :
								<Image className="cursor-pointer d-block d-md-none" onClick={() => handleTooltipClick("service-shipping")}
									src={"/assets/icons/info-transparent.svg"} alt="tools icon" width={24} height={24}/>
							}

						</div>
					</div>
					<div className="col-12 mt-2 mb-1">
						<div className="row gx-3 align-items-center ">
							<div className="col-11 d-flex align-items-center">
								<div className="col-auto me-2 d-flex d-md-none">
									<Image className="cursor-pointer" src={process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "/assets/icons/return-myhomeplan.svg" : "/assets/icons/usp-return.svg"} alt="tools icon" width={24} height={24}/>
								</div>
								<div className="col-2 d-flex d-none d-md-block">
									<Image className="cursor-pointer" src={process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "/assets/icons/return-myhomeplan.svg" : "/assets/icons/usp-return.svg"} alt="tools icon" width={40} height={40}/>
								</div>
								<div className="col-10">
									<Typography variant="bodySm" semanticTag="span">{t("cancel-and-exchange-policy")} </Typography>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 mt-2 mb-1">
						<div className="d-md-row d-flex gx-3 align-items-start justify-content-md-start">
							<div className="col-11 d-flex align-items-center">
								<div className="col-auto me-2 d-flex align-items-center d-md-none">
									<Image className="cursor-pointer" src={process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "/assets/icons/repair-myhomeplan.svg" : "/assets/icons/usp-service.svg"} alt="tools icon" width={24}height={24}/>
								</div>
								<div className="col-2 d-flex align-items-center d-none d-md-flex">
									<Image className="cursor-pointer" src={process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "/assets/icons/guarantee-myhomeplan.svg" : "/assets/icons/usp-service.svg"} alt="tools icon" width={40}height={40}/>
								</div>
								<div className="col-10">
									<Typography variant="bodySm" semanticTag="span">{t("guarantee-and-service-stores")} </Typography>
								</div>
							</div>
							{isOpen && activeTooltip === "guarantee" ?
								<Image className="cursor-pointer col-1" onClick={() => handleTooltipClick("guarantee")}
									src={process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "/assets/icons/info-active-brown.svg" : "/assets/icons/info-active.svg"} alt="tools icon" width={24} height={24}/> :
								<Image className="cursor-pointer col-2" onClick={() => handleTooltipClick("guarantee")}
									src={"/assets/icons/info-transparent.svg"} alt="tools icon" width={24} height={24}/>
							}
						</div>
					</div>

					<div className="position-relative ">
						<PDPTooltip isOpen={isOpen} setIsOpen={setIsOpen} desktop label={activeTooltip}/>
					</div>
				</div>
				<button className={`d-flex align-items-center justify-content-between reset border-0 mt-4 py-3 py-md-2 px-4 px-md-3 col-12 col-md-auto ${styles.infoBoxQuestionButton}`}
					onClick={() => {
						setModalContent(<ConsultBookingModalContent closeModal={closeModal}/>)
						openModal()
					}}>
					<div className="d-flex">
						<div className="d-flex d-md-none">
							<Image src={"/assets/icons/person.svg"} layout={"intrinsic"} objectFit={"contain"}
								width={20} height={20}/>
						</div>
						<Typography className="me-3 py-md-1 ms-3 ms-md-0" style={{fontSize: "16px"}} variant="bodyLgBold">{t("have-a-question")}</Typography>
					</div>
					<Image className="cursor-pointer col-2" style={{transform: "rotate(90deg)"}}
						src={"/assets/icons/chevron-up-icon.svg"} alt="arrow icon" width={14} height={14}/>
				</button>

			</div>
		</>
	)
}

InfoBox.propTypes = {
	setAdditionalInfoDrawer: PropTypes.func.isRequired
}

export default InfoBox
