import React from "react"

const BikeCollectionItemLoadingOverlay = () => {
	return (
		<div className={"position-absolute top-0 start-0 bottom-0 end-0"} style={{
			backgroundColor: "rgba(255,255,255,0.8)",
			zIndex: 4
		}}>
			<div className={"row justify-content-center align-items-center"} style={{
				height: "100%"
			}}>
				<div className={"col-auto"}>
					<div className="spinner-border text-primary" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BikeCollectionItemLoadingOverlay
