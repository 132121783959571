/* eslint-disable camelcase */
import React, {useState} from "react"
import Button from "../../reusable/components/Button"
import {useTranslation} from "next-i18next"
import Typography from "../../reusable/Typography/Typography"
import {StockStatus} from "../BikeDetails"
import {findBikeAttributeByName, findBikeMetaFieldByName} from "../../../utility/Helper"
import Image from "next/image"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"
import {colors} from "../../../colors/Colors"
import NumberButton from "../../button/NumberButton"

interface Props {
	disabled: boolean
	isLoading: boolean
	handleClick: () => void
	variations: any
	setSelectedVariation: (variation: any) => void
	setSelectedSize: (size: any) => void
	selectedSize: any
	selectedVariation: any
	iconSize?: number
	setNumberOfItemsToAdd: (value:number)=>void
}

export const CheckoutFixedBtn = ({
	disabled,
	isLoading,
	handleClick,
	iconSize = 28,
	variations,
	selectedVariation,
	setSelectedVariation,
	setSelectedSize,
	selectedSize,
	setNumberOfItemsToAdd
}: Props) => {
	const {t} = useTranslation(["common", "productDescrPage"])
	const inStockSizes = variations?.filter(variation => variation.stock_status === StockStatus.inStock || StockStatus.onBackOrder)
	const isOneSize = Boolean(inStockSizes.length === 1)
	const [isNoSelectSizeWarning, setNoSelectSizeWarning] = useState<boolean>(false)
	const [isShaking, setIsShaking] = useState<boolean>(false)
	const [isShowDetails, setShowDetails] = useState(false)
	const bikerHeight = findBikeMetaFieldByName(selectedVariation, "biker_height")
	const {primary, white} = colors

	const handleShowDetails = () => setShowDetails(prev => !prev)

	const startShake = () => {
		setIsShaking(true)
		setTimeout(() => setIsShaking(false), 1000)
	}

	return (
		<>
			<div className={"d-md-none position-fixed container bg-white shadow-lg pt-2"}
				style={{bottom: 0, left: 0, right: 0, zIndex: 20000}}>
				{!isNoSelectSizeWarning &&
        <Typography className="mb-2" variant="bodyLgBold" semanticTag="p">{`${t("Grösse")}:`}</Typography>}

				{isNoSelectSizeWarning && <div className={`d-flex mb-2 py-2 ${isShaking ? "shake" : ""}`}>
					<Image src={"/assets/icons/alert.svg"} width="24" height="24"/>
					<Typography className="ms-2 text-uppercase"
						variant="bodyLgBold">{t("Bitte eine Grösse auswählen.")}</Typography>
				</div>}

				{inStockSizes && inStockSizes.length && <div className="d-flex">
					{inStockSizes.map((item, idx) => {
						const {stock_status} = item
						const sizeAttribute = findBikeAttributeByName(item, "Grösse", false, true)
						const [size, sizeLabel] = sizeAttribute.split(": ")
						const isMarginRight = inStockSizes.length - 1 !== idx
						return (
							<div key={item.id} className={`col-auto ${isMarginRight && "me-2"} `}>
								<button
									disabled={stock_status === StockStatus.outOfStock}
									onClick={() => {
										setSelectedVariation(item)
										setSelectedSize(size)
										setNoSelectSizeWarning(false)
									}} type={"button"}
									className={`btn text-start overflow-hidden border-2 position-relative ${isOneSize || selectedSize === size ? "btn-outline-primary bg-orangeLight shadow-none text-dark" : "btn-outline-dark"}`}
								>
									<Typography variant="bodyLgBold"
										semanticTag="span">{sizeLabel ? `${t(sizeLabel)}: ` : ""}</Typography>
									<Typography className="" variant="bodyLg" semanticTag="span">{t(size)}</Typography>
								</button>
							</div>
						)
					})
					}
				</div>}

				{bikerHeight && <div className="col-auto mt-2 mb-3">
					<Typography variant="bodyLg" semanticTag="span">
						{`${t("Körpergrösse")}: ${bikerHeight}`}
					</Typography>

					<div onClick={handleShowDetails} className="col-12 mt-2 d-flex align-items-center">
						<div className="me-2">
							<Typography variant="bodyLgBold" semanticTag="span">
								{t("sticky-cta-delivery-info-title")}
							</Typography>
						</div>
						{isShowDetails ? <Image src={"/assets/icons/chevron-up-icon.svg"} height={iconSize} width={iconSize}/> :
							<Image src={"/assets/icons/chevron-down.svg"} height={iconSize} width={iconSize}/>}
					</div>
					<AccordionAnimation duration={500} inProp={isShowDetails} transTimingFn="ease-out">
						<>
							{selectedVariation?.stock_status === StockStatus.inStock ?
								<div className="col-auto">
									{selectedVariation?.erpStockInfo.isDropshipping ?
										<Typography variant="bodyLgBold" semanticTag="span">
											{t(selectedVariation?.erpStockInfo.deliveryTime)}
										</Typography> :
										<Typography variant="bodyLgBold" semanticTag="span">
											{t("10-days-delivery")}
										</Typography>}
									<Typography className="mt-1" variant="bodyLg"
										semanticTag="div">{t("Die Lieferfrist kann sich ändern")}</Typography>
								</div> : selectedVariation?.stock_status === StockStatus.onBackOrder ?
									<div className="col-10 col-md-auto">
										<Typography variant="bodyLgBold" semanticTag="span">
											{t(selectedVariation?.erpStockInfo.deliveryTime)}
										</Typography>
										<Typography className="mt-1" variant="bodyLg"
											semanticTag="div">{t("Die Lieferfrist kann sich ändern")}</Typography>
									</div> : null}

						</>
					</AccordionAnimation>
				</div>}

				<div className={"row align-items-center g-2 mt-1 mb-2"}>
					<div className={"col-4"}>
						<NumberButton size={"sm"} defaultValue={1} min={1} max={null} onChange={value => setNumberOfItemsToAdd(value)}/>
					</div>
					<div className={"col"}>{disabled ?
						<Button bgColor={primary} textColor={white} color={white} className="w-100 mt-2" spinnerColor="white"
							label={t("In den Warenkorb")}
							isLoading={isLoading}
							onClick={() => {
								setNoSelectSizeWarning(true)
								startShake()
							}}
						/> :
						<Button bgColor={primary} textColor={white} color={white} className="w-100" spinnerColor="white"
							label={t("In den Warenkorb")}
							disabled={disabled} isLoading={isLoading}
							onClick={handleClick}
						/>
					}
					</div>
				</div>
			</div>
		</>
	)
}
