import React from "react"

export type StoryItem = {
    id: string
    imageUrl: string
    videoUrl: string
    duration?: number
    label?: string
}

export type Info = {
    duration: number,
    currentTime: number
}

interface StoryContextProps {
    stories: StoryItem[]
    info: Info
    next: () => void
    previous: () => void
    playFromStart: () => void
    getCurrentIndex: () => number | null
}

const StoryContext = React.createContext<StoryContextProps>({
	stories: [],
	info: null,
	next: () => {},
	previous: () => {},
	playFromStart: () => {},
	getCurrentIndex: () => null
})

export default StoryContext
