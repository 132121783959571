import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import {X} from "react-bootstrap-icons"
import {ModalContext} from "../../../context/ModalContext"
import styles from "./OurReviewModalContent.module.scss"

interface Props {
	visualsRate: number,
	practicalityRate: number,
	functionalityRate: number,
	rangeRate: number,
	ourReviewQuoteText: string
}

const OurReviewModalContent = ({visualsRate, practicalityRate, functionalityRate, rangeRate, ourReviewQuoteText}: Props) => {
	const {t} = useTranslation()
	const {closeModal} = useContext(ModalContext)
	const rate = [1, 2, 3, 4, 5]
	return (
		<div className={`${styles.fixedWrapper}`}>
			<div className={`${styles.modalWrapper} position-relative px-md-0 `}>

				<div onClick={() => closeModal()}
					className={"position-absolute bg-white rounded-circle top-0 end-0 pt-md-4"} style={{
						marginRight: "3%",
						zIndex: 1
					}}>
					<X size={30} color={"black"}/>
				</div>

				<div className="row position-relative mx-0 justify-content-between">
					<div className={`${styles.contentWrapper} col-12  bg-white py-md-4 ps-md-4 pe-md-5 d-flex justify-content-center align-items-center pb-5`}>
						<div className="container px-0 ps-md-0">
							<div className="row mb-md-1">
								<Typography className="col-12 mb-2 mt-4 mt-md-0 " variant="bodyLgBlack" semanticTag="h2">{t("our-review-title")}</Typography>
							</div>

							<div className="d-flex mt-3">
								<div style={{maxWidth: 85, minWidth: 85}} className="me-5 position-relative">
									<img className="mt-2" src="/assets/images/our-review-vale-photo.png"/>
									<img className="position-absolute top-0 end-0 border border-white border-4 rounded-circle" src="/assets/images/our-review-ribbon-star.png"/>
									<Typography className="text-center mt-1" variant="bodySm">Vale</Typography>
								</div>
								<div className="">
									<img className="me-1" src="/assets/icons/quote.svg"/>
									<img src="/assets/icons/quote.svg"/>
									<Typography className="mt-1" variant="bodyLgBold">{t(ourReviewQuoteText)}</Typography>
								</div>
							</div>

							<hr/>

							<div className="col-12 d-flex flex-wrap pb-4">
								{visualsRate && <div className="col-6 mb-3">
									<Typography className=" mt-1" variant="bodyLg">{t("visuals")}</Typography>
									{
										rate.map(item => {
											if (visualsRate >= item) {
												return (<img key={item} className="me-2" width={20} height={20} src="/assets/images/hot-pepper-chili.png"/>)
											}

											return (<img key={item} style={{opacity: "0.5"}} className="me-2" width={20} height={20} src="/assets/images/hot-pepper-chili.png"/>)
										})
									}
								</div>}

								{practicalityRate && <div className="col-6 mb-3">
									<Typography className=" mt-1" variant="bodyLg">{t("practicality")}</Typography>
									{
										rate.map(item => {
											if (practicalityRate >= item) {
												return (<img key={item} className="me-2" width={20} height={20} src="/assets/images/hot-pepper-chili.png"/>)
											}

											return (<img key={item} style={{opacity: "0.5"}} className="me-2" width={20} height={20} src="/assets/images/hot-pepper-chili.png"/>)
										})
									}
								</div>}

								{functionalityRate && <div className="col-6">
									<Typography className=" mt-1" variant="bodyLg">{t("functionality")}</Typography>
									{
										rate.map(item => {
											if (functionalityRate >= item) {
												return (<img key={item} className="me-2" width={20} height={20} src="/assets/images/hot-pepper-chili.png"/>)
											}

											return (<img key={item} style={{opacity: "0.5"}} className="me-2" width={20} height={20} src="/assets/images/hot-pepper-chili.png"/>)
										})
									}
								</div>}

								{rangeRate && <div className="col-6">
									<Typography className=" mt-1" variant="bodyLg">{t("range")}</Typography>
									{
										rate.map(item => {
											if (rangeRate >= item) {
												return (<img key={item} className="me-2" width={20} height={20} src="/assets/images/hot-pepper-chili.png"/>)
											}

											return (<img key={item} style={{opacity: "0.5"}} className="me-2" width={20} height={20} src="/assets/images/hot-pepper-chili.png"/>)
										})
									}
								</div>}
							</div>

						</div>
					</div>

				</div>

			</div>

		</div>
	)
}

export default OurReviewModalContent
