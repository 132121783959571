import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import LayoutContext, {devices} from "../context/LayoutContext"
import {gaCategories, gaEvents} from "../config/googleAnalytics/events"
import AnalyticsContext from "../context/AnalyticsContext"

type Props = {
  children: React.ReactNode
}

const isMobile = () => {
	if (typeof window !== undefined) {
		return window.innerWidth < 768
	}

	return false
}

const getDevice = () => {
	if (typeof window !== undefined) {
		if (window.innerWidth < 768) {
			return devices.phone
		}

		if (window.innerWidth < 992) {
			return devices.tablet
		}

		return devices.desktop
	}

	return devices.node
}

const LayoutContextProvider: React.FC<Props> = ({children}) => {
	const [mobile, setMobile] = useState<boolean | undefined>(true)
	const [device, setDevice] = useState<string | undefined>(devices.phone)
	const [isListView, setIsListView] = useState<boolean>(false)
	const [isGridView, setIsGridView] = useState<boolean>(false)
	const {reactGA} = useContext(AnalyticsContext)

	const handleResize = () => {
		setMobile(isMobile())
		setDevice(getDevice())
	}

	const setDefaultViewCollectionPage = () => {
		setIsGridView(false)
		setIsListView(false)
		reactGA?.event({
			category: gaCategories.plp,
			action: gaEvents.plpLayout,
			label: "Default selected",
			nonInteraction: false
		})
	}

	const setListViewCollectionPage = () => {
		setIsGridView(false)
		setIsListView(true)
		reactGA?.event({
			category: gaCategories.plp,
			action: gaEvents.plpLayout,
			label: "List selected",
			nonInteraction: false
		})
	}

	const setGridViewCollectionPage = () => {
		setIsListView(false)
		setIsGridView(true)
		reactGA?.event({
			category: gaCategories.plp,
			action: gaEvents.plpLayout,
			label: "Grid selected",
			nonInteraction: false
		})
	}

	useEffect(() => {
		setMobile(isMobile())
		setDevice(getDevice())
		window.addEventListener("resize", handleResize, false)
	}, [])

	const value = {
		isMobile: mobile,
		device,
		isListView,
		isGridView,
		setDefaultViewCollectionPage,
		setListViewCollectionPage,
		setGridViewCollectionPage
	}
	return (
		<LayoutContext.Provider value={value}>
			{children}
		</LayoutContext.Provider>
	)
}

LayoutContextProvider.propTypes = {
	children: PropTypes.node
}

export default LayoutContextProvider
