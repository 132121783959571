/* eslint-disable camelcase */
import {VisibleFiltersByName} from "./../../components/filter/SelectedFilters/visibleFilters"
import {accessoriesfilters} from "../../components/filter/SelectedFilters/visibleFilters"
import slugify from "slugify"
import {StockStatus} from "../../components/bikeDetails/BikeDetails"

const getAccessoriesCountByOption = accessories => {
	if (accessories) {
		return accessories.reduce((acc, accessory) => {
			const {attributes} = accessory
			const _attr = attributes.filter(attr => accessoriesfilters.includes(attr.name)).flatMap(attr => attr.options)

			_attr.forEach(option => {
				const optionSlug = slugify(option)
				if (acc[optionSlug]) {
					acc[optionSlug]++
				} else {
					acc[optionSlug] = 1
				}
			})
			return acc
		}, {})
	}

	return {}
}

const getBikesCountByOption = bikes => {
	if (bikes) {
		return bikes.filter(item => item.stock_status !== StockStatus.outOfStock && item.status === "publish").reduce((acc, bike) => {
			const {attributes, categories, stock_status} = bike
			const _attr = attributes.filter(attr => VisibleFiltersByName[attr.name]).flatMap(attr => attr.name === "Motor Marke" && attr.options[0] === "Stromer" ? [attr.options[0] + " " + attr.name] : attr.options)
			const _cat = categories.filter(cat => VisibleFiltersByName[cat.name]).map(cat => cat.name)
			const allOptions = [..._attr, ..._cat, stock_status]
			allOptions.forEach(option => {
				const optionSlug = slugify(option)
				if (acc[optionSlug]) {
					acc[optionSlug]++
				} else {
					acc[optionSlug] = 1
				}
			})
			return acc
		}, {})
	}

	return {}
}

export {getAccessoriesCountByOption, getBikesCountByOption}
