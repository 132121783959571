import React, {useContext} from "react"
import Image from "next/image"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"

type Props = {
  isOpen: boolean
  iconColor?: string
}

const PlusDash: React.FC<Props> = ({isOpen, iconColor = "black"}) => {
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<>
			{isOpen ? <Image
				src={`${iconColor === "white" ? "/assets/icons/chevron-up-white.svg" : "/assets/icons/chevron-up-icon.svg"}`}
				height={6.5}
				width={12}/> : <Image onClick={() => {
				reactGA.event({
					category: gaCategories.homePage,
					action: gaEvents.faq,
					label: gaEvents.faq,
					nonInteraction: false
				})
			}}
			src={`${iconColor === "white" ? "/assets/icons/chevron-down-white.svg" : "/assets/icons/chevron-down-icon.svg"}`}
			height={6.5}
			width={12}/>}
		</>
	)
}

export default PlusDash
