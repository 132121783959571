/* eslint-disable camelcase */
/* eslint-disable capitalized-comments */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import {useTranslation} from "next-i18next"
import slugify from "slugify"
import {StockStatus} from "../components/bikeDetails/BikeDetails"
import {getFaqData} from "../components/faqComponents/FaqContent"
import {getBikeCategoriesSlugs} from "../components/filter/config/Categories"
import React from "react"
import Image from "next/image"
import fetchBike from "../fetch/FetchBike"
import Typography from "../components/reusable/Typography/Typography"
import {delimiter} from "../components/filter/Config"
import getImageUrl from "../contentful/image/image"
import {countryList} from "../components/forms/data/countryList"
import {Collections} from "../firebaseAdmin/collections"
import {customPlanLengthMap} from "../components/bikeDetails/plan/customPlanLengthMap"

export const generateAwsUrl = (bucket: string, fileName: string) => {
	return `https://s3.eu-central-1.amazonaws.com/${bucket}/${fileName}`
}

export const downloadBikePDFfromAWS = async (bucket:string, fileName:string) => {
	const url = generateAwsUrl(bucket, fileName)

	const link = document.createElement("a")
	link.href = url
	link.download = fileName
	link.target = "_blank"

	document.body.appendChild(link)

	link.click()

	link.parentNode.removeChild(link)
}

export const toHHMMSS = (secs: string) => {
	const sec_num = parseInt(secs, 10)
	const hours = Math.floor(sec_num / 3600)
	const minutes = Math.floor(sec_num / 60) % 60
	const seconds = sec_num % 60

	return [hours, minutes, seconds]
		.map(v => v < 10 ? "0" + v : v)
		.filter((v, i) => v !== "00" || i > 0)
		.join(":")
}

export const countryFilterSearch = (search: string, options: any) => options.filter((option: any) => option.value.toLowerCase().includes(search.toLowerCase()) || option.label.toLowerCase().includes(search.toLowerCase()))

export const getCountryBy = (searchTerm: string, searchBy: string) => {
	return countryList.find(country => country[searchBy] === searchTerm)
}

export const getCountryValueByLabel = (label: string) => {
	return countryList.find(country => country.label === label)?.value
}

export const phonePrefixSearch = (search: string, options: any) => options.filter((option: any) => option.code.toLowerCase().includes(search.toLowerCase()) ||
  option.dial_code.toLowerCase().includes(search.toLowerCase()) ||
  option.dial_code_zero.toLowerCase().includes(search.toLowerCase()) ||
  option.label.toLowerCase().includes(search.toLowerCase()))

export const isOccasionBike = (bike: any) => Boolean(bike?.categories?.filter((category: any) => category.name === "Occasion")?.length > 0)

export type MenuTitlesArray = {
  name: string,
  items: MenuTitlesItem[]
}

export enum MenuTitlesItemType {
  category = "category",
  brand = "brand"
}

export const clampValue = (value, min, max) => {
	if (value < min) {
		return min
	}

	if (value > max) {
		return max
	}

	return value
}

type MenuTitlesItem = {
  name: string,
  href: string,
  src: string,
  imgDimensions: any
  type: MenuTitlesItemType
}
type MenuTitles = {
  desktop: MenuTitlesArray[],
  mobile: MenuTitlesArray[],
  brands: MenuTitlesItem[],
  collections: MenuTitlesItem[]
}
export const getMenuTitles = (collections: any[], brands: any[]): MenuTitles => {
	const _collections = transformContentfulCollections(collections)
	const _brands = transformContentfulBrands(brands)
	const collectionsArrays = splitArray(_collections)
	const brandsArray = splitArray(_brands)

	return {
		desktop:
      [
      	{
      		name: "Kategorien",
      		items: collectionsArrays.firstHalf as MenuTitlesItem[]
      	},
      	{
      		name: "blank",
      		items: collectionsArrays.secondHalf as MenuTitlesItem[]
      	},
      	{
      		name: "Marken",
      		items: brandsArray.firstHalf as MenuTitlesItem[]
      	},
      	{
      		name: "blankMarken",
      		items: brandsArray.secondHalf as MenuTitlesItem[]
      	}
      ],
		mobile:
      [
      	{
      		name: "Kategorien",
      		items: _collections
      	},
      	{
      		name: "Marken",
      		items: _brands
      	}
      ],
		brands: _brands,
		collections: _collections
	}
}

type SplitArray = {
  firstHalf: any[],
  secondHalf: any[]
}
export const splitArray = (array: any[]): SplitArray => {
	const half = Math.ceil(array.length / 2)
	return {
		firstHalf: array?.slice(0, half),
		secondHalf: array?.slice(half)
	}
}

export const transformContentfulCollections = (collections: any[]): any[] => {
	return collections.sort((a, b) => b.fields.menuOrder || 0 - a.fields.menuOrder || 0).map(item => {
		const {menuLabel, slug, icon} = item.fields
		return {
			name: menuLabel,
			href: `/${slug}`,
			src: getImageUrl(icon) || "/assets/icons/bike.svg",
			type: MenuTitlesItemType.category
		}
	})
}

export const transformContentfulBrands = (brands: any[]): any[] => {
	return brands.sort((a, b) => b.fields.menuOrder || 0 - a.fields.menuOrder || 0).map(item => {
		const {menuLabel, slug, icon} = item.fields
		const image = icon?.fields?.file?.details?.image
		return {
			name: menuLabel,
			href: `/${slug}`,
			src: getImageUrl(icon) || "/assets/icons/bike.svg",
			imgDimensions: image ? {
				width: image.width,
				height: image.height
			} : null,
			type: MenuTitlesItemType.brand
		}
	})
}

export const getQueryParams = (asPath: string): any => {
	const checkArr = asPath.split("?")

	const paramObj = {}

	if (checkArr[1]) {
		const paramsArr = checkArr[1].split("&")

		paramsArr.forEach(param => {
			const key = param.split("=")[0]
			const value = param.split("=")[1]

			paramObj[key] = value
		})

		return paramObj
	}

	return {
		at_gd: undefined
	}
}

export const fetchToken = async (): Promise<string> => {
	const res = await fetch("/api/getToken")
	const data = await res.json()
	return data.token
}

export const rotateArray = (arr, val, pos) => {
	// Set pos to 0 if moving val to first position, or 1 for last position
	arr = arr.concat(arr.splice(0, arr.indexOf(val) + pos))
	return arr
}

export const getImgDescriptionFormat = (description, t) => {
	const strArr = description.split(" ")
	const isValidArray = strArr.length >= 3
	const modelName = isValidArray ? strArr[0] : null
	const modelSizeStr = isValidArray ? strArr[2] : null
	const modelSize = modelSizeStr?.split("c")[0]
	const bikeSize = isValidArray ? strArr[strArr.length - 1] : null

	return modelSizeStr && isValidArray ? <div style={{zIndex: 999999}}
		className="text-center text-lg-start mb-2 mb-lg-3 py-1 py-lg-2 px-lg-2 d-flex align-items-center">
		<Typography variant={"bodyXSm"} semanticTag={"span"} style={{color: "#6C7074"}}>
			{modelName} {t("img-label-text-1")} <b className="text-bold">{modelSize} cm </b>{t("img-label-text-2")}<b
				className="text-bold"> {bikeSize}</b>
		</Typography>
	</div> : null
}

export const getReferrerType = referrer => {
	if (referrer !== "") {
		return "SOCIAL_MEDIA"
	}

	return "DIRECT_TRAFFIC"
}

export const isBikeOnStock = async (slug, locale) => {
	const bike = await fetchBike(slug, locale)

	return bike.stock_status
}

export const getBikesOnStock = bikes => {
	return bikes.filter(bike => bike.stock_status !== StockStatus.outOfStock)
}

export const isCategory = (bike, catName) => {
	return Boolean(bike.categories.find(cat => cat.name === catName))
}

export const getStars = (number, starSize = 18) => {
	const componentArr = []
	const starsAmount = Number((number)?.toFixed(0)) || 0
	let i = 0
	while (i < starsAmount) {
		const starComponent = (
			<div className="col-auto d-flex align-items-center" key={i}>
				<Image key={i} src={"/assets/icons/google-star-icon.svg"} width={starSize} height={starSize}/>
			</div>
		)
		componentArr.push(starComponent)
		i++
	}

	return componentArr
}

export const getNumberOfStars = starData => {
	if (starData === "ONE") {
		return 1
	}

	if (starData === "TWO") {
		return 2
	}

	if (starData === "THREE") {
		return 3
	}

	if (starData === "FOUR") {
		return 4
	}

	if (starData === "FIVE") {
		return 5
	}
}

export const filterFaqByCategory = (data, category) => {
	return data.filter(element => element.slug === category)
}

export const getFaqTitleSlugified = locale => {
	const data = getFaqData(locale)
	return data.map(element => {
		return slugify(element.title)
	})
}

export const getFaqSlugCategory = locale => {
	const data = createFaqSlugs(locale)
	const arr = data.map(element => element.slug)
	const uArr = new Set(arr)
	return Array.from(uArr)
}

export const createFaqSlugs = locale => {
	const data = getFaqData(locale)
	return data.map(element => {
		const {category} = element
		const slug = slugify(category).toLowerCase()
		return {...element, slug}
	})
}

export const getFaqCategory = locale => {
	const data = getFaqData(locale)
	const arr = data.map(element => element.category)
	const uArr = new Set(arr)
	return Array.from(uArr)
}

export const isEmpty = object => {
	return Object.keys(object).length === 0
}

export const getCategoryBySlug = (bike, slug) => {
	return bike.categories.find(e => e.slug === slug)
}

export const findBikeAttributeByName = (bike, attributeName, single = false, singularOption = false) => {
	if (single) {
		return bike?.attributes?.find(e => e.name === attributeName)?.options[0]
	}

	if (singularOption) {
		if (bike?.attributes.find(e => e.name === attributeName)?.option) {
			return bike.attributes.find(e => e.name === attributeName).option
		}

		return "No Data"
	}

	return bike?.attributes.find(e => e.name === attributeName)?.options
}

export const findBikeMetaFieldByName = (bike, fieldName) => bike?.meta_data.find(e => e.key === fieldName)?.value

export const getFirstValidBikeCategory = (bike, t): any => {
	let validCategory = null
	bike.categories.forEach(category => {
		if (getBikeCategoriesSlugs(t).includes(category.slug)) {
			validCategory = category
		}

		if (validCategory) {
			return validCategory
		}
	})
	return validCategory
}

export const idsParams = (ids: any) => {
	return ids.join(",")
}

const productPageTabsTranslate = {
	ubersicht: "vue-d'ensemble",
	einzelheiten: "details",
	beschreibung: "description",
	"vue-d'ensemble": "ubersicht",
	details: "einzelheiten",
	description: "beschreibung"
}
const consultationPageTabsTranslate = {
	motor: "le-moteur",
	"le-moteur": "motor",
	akku: "la-batterie",
	"la-batterie": "akku",
	"e-bike-rahmen": "le-cadre",
	"le-cadre": "e-bike-rahmen",
	"display-bordcomputer": "l'ordinateur-de-bord",
	"l'ordinateur-de-bord": "display-bordcomputer",
	federung: "les-suspensions",
	"les-suspensions": "federung",
	gangschaltung: "le-levier-de-vitesse",
	"le-levier-de-vitesse": "gangschaltung"
}
const kundendienstTabsTranslate = {
	"allgemeine-fragen": "general-questions",
	"general-questions": "allgemeine-fragen",
	"bike-zurückgeben": "return-bike",
	"return-bike": "bike-zurückgeben",
	schaden: "bike-damage",
	"bike-damage": "schaden"
}
export const localisedPath = (url, t, path, paramItemsOverride = null, isContentful = false, contentfulSlugs = []) => {
	const pathParts = path.split("/")
	const translationHash = pathParts[1] === "beratung" ? consultationPageTabsTranslate : pathParts[1] === "kundendienst" ? kundendienstTabsTranslate : productPageTabsTranslate
	const localisedParts = []

	pathParts.forEach(item => {
		if (item !== "") {
			const itemIsRouteParam = isContentful ? contentfulSlugs.includes(item) : item[0] === "[" && item[item.length - 1] === "]"
			if (itemIsRouteParam) {
				const overrideItem = Array.isArray(paramItemsOverride) ? paramItemsOverride?.shift() : paramItemsOverride
				const key = translationHash[overrideItem] ? translationHash[overrideItem] : overrideItem
				localisedParts.push(key || "-")
			} else {
				localisedParts.push(item.split(delimiter).map(el => {
					if (el.includes("?")) {
						const queryParts = el.split("?")
						if (queryParts.length === 2) {
							return [
								isContentful ? paramItemsOverride : queryParts[0],
								queryParts[1].split("&").map(el => {
									const params = el.split("=")
									if (params.length === 2) {
										return [
											params[0],
											t(params[1], {ns: "URL"})
										].join("=")
									}

									return el
								}).join("&")
							].join("?")
						}

						return t(el, {ns: "URL"})
					}

					return t(el, {ns: "URL"})
				}).join(delimiter))
			}
		}
	})

	const resultingUrl = `/${localisedParts.join("/")}`

	return resultingUrl
}

export const waitFor = conditionFunction => {
	let timeout = null
	const poll = resolve => {
		if (conditionFunction()) {
			resolve()
			clearTimeout(timeout)
		} else {
			timeout = setTimeout(_ => poll(resolve), 10)
		}
	}

	return new Promise(poll)
}

export const dealPlanLengthFormater = planLength => {
	console.log("PLAN LENGTH Formatting", planLength)
	if (typeof planLength !== "number") {
		const length = planLength.split(" ")[0]

		return length + " Months"
	}

	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const correctPlanLength = planLength ? planLengthMap ? planLengthMap[planLength] : planLength : 48

	return correctPlanLength + " Months"
}

export const getTitle = category => {
	const {t} = useTranslation()
	const title = category === t("e-mountain") ?
		t("Mountain E-Bikes") :
		category === t("e-trekking") ?
			t("Trekking E-Bikes") :
			category === t("e-urban") ?
				t("Urban E-Bikes") :
				category === t("damen-e-bikes") ?
					t("Damen E-Bikes") :
					category === t("herren-e-bikes") ?
						t("Herren E-Bikes") :
						category === t("wave-e-bikes") ?
							t("Die Rahmenform Wave") :
							category === t("trapez-e-bikes") ?
								t("Die Rahmenform Trapez") :
								category === t("diamant-e-bikes") ?
									t("Die Rahmenform Diamant") :
									category === t("25-km-e-bikes") ?
										t("S PEdelecs bis 25 km/h") :
										category === t("45-km-e-bikes") ?
											t("S Pedelecs bis 45 km/h") :
											t("Alle E-Bikes")

	return title
}

export const getBackgroundImage = category => {
	const {t} = useTranslation()
	const backgroundImage = category === t("e-mountain") ?
		"url('/assets/images/focus-bikes-bosch-e-mtb-e-is-for-everyone-jam_-sun_1-1.png')" :
		category === t("e-trekking") ?
			"url('/assets/images/cyclist-riding-bike-sunset-mountain-road-1-3.png')" :
			category === t("e-urban") ?
				"url('/assets/images/wolfram-bolte-yqCRZzc49h8-unsplash-1-1.png')" :
				category === t("damen-e-bikes") ?
					"url('/assets/images/damen-cover.png')" :
					category === t("herren-e-bikes") ?
						"url('/assets/images/herren-cover.png')" :
						category === t("wave-e-bikes") ?
							"url('/assets/images/wave-cover.png')" :
							category === t("trapez-e-bikes") ?
								"url('/assets/images/trapez-cover.png')" :
								category === t("diamant-e-bikes") ?
									"url('/assets/images/diamant-cover.png')" :
									category === t("25-km-e-bikes") ?
										"url('/assets/images/25-km-cover.png')" :
										category === t("45-km-e-bikes") ?
											"url('/assets/images/45-km-cover.png')" :
											"url('/assets/images/header-inventory.png')"

	return backgroundImage
}

export const isDevelopment = () => {
	return process.env.NEXT_PUBLIC_ENVIRONMENT === "development" || process.env.NEXT_PUBLIC_ENVIRONMENT === "local"
}

export const isProduction = () => {
	return process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
}

export const calculateInsurance = (totalAmount, planLength = 12) => {
	const pricing = [
		{
			level: 1000,
			amount: 73
		},
		{
			level: 1500,
			amount: 73
		},
		{
			level: 2000,
			amount: 93
		},
		{
			level: 2500,
			amount: 111
		},
		{
			level: 3000,
			amount: 126
		},
		{
			level: 3500,
			amount: 144
		},
		{
			level: 4000,
			amount: 160
		},
		{
			level: 4500,
			amount: 173
		},
		{
			level: 5000,
			amount: 188
		},
		{
			level: 5500,
			amount: 219
		},
		{
			level: 6000,
			amount: 237
		},
		{
			level: 6500,
			amount: 253
		},
		{
			level: 7000,
			amount: 274
		},
		{
			level: 7500,
			amount: 285
		},
		{
			level: 8000,
			amount: 299
		},
		{
			level: 8500,
			amount: 317
		},
		{
			level: 9000,
			amount: 330
		},
		{
			level: 9500,
			amount: 333
		},
		{
			level: 10000,
			amount: 336
		}
	]
	let pricingLevel = pricing[0]
	const targetAmount = Math.round(totalAmount / 500) * 500
	pricing.forEach(item => {
		if (item.level.toFixed() === targetAmount.toFixed()) {
			pricingLevel = item
		}
	})
	return pricingLevel.amount / 12
}

export const removeUndefined = object => Object.keys(object).reduce((result, key) => (
	// eslint-disable-next-line no-negated-condition
	![undefined, null].includes(object[key]) ?
		{...result, [key]: object[key]} :
		result
), {})

export const fetchCheckoutDoc = async (db, checkoutDocId) => {
	try {
		const {doc, getDoc} = await import("@firebase/firestore/lite")
		const docRef = doc(db, Collections.checkOuts, checkoutDocId as string)
		const docData = await getDoc(docRef)
		return {
			...docData.data(),
			id: docData.id
		} as any
	} catch (e) {
		console.error(e)
		return null
	}
}

// Delete duplications in array
export const removeDuplicationsInArr = (arr: any[]) => {
	const uniqueIds = []

	const unique = arr.filter(element => {
		const isDuplicate = uniqueIds.includes(element.id)

		if (!isDuplicate) {
			uniqueIds.push(element.id)

			return true
		}

		return false
	})

	return unique
}

export const getSortedOrderLabel = (params, t) => {
	if (!params.sortOrder) {
		return t("Günstigste")
	}

	const sortOrderMap = {
		aufsteigend: t("Günstigste"),
		"-": t("Günstigste"),
		[t("gunstigste")]: t("Günstigste"),
		[t("teuerste")]: t("Teuerste"),
		[t("sale")]: t("Sale"),
		[t("neuestes")]: t("Neuestes"),
		[t("relevanz")]: t("Relevanz"),
		[t("pertinence")]: t("Relevanz")
	}

	return sortOrderMap[String(params.sortOrder)] || params.sortOrder || t("Günstigste")
}

export const sortByRelevance = products => {
	const customSort = (a, b) => {
		const relevanceA = a.relevance === undefined || a.relevance === "" ? Number.MIN_SAFE_INTEGER : a.relevance
		const relevanceB = b.relevance === undefined || b.relevance === "" ? Number.MIN_SAFE_INTEGER : b.relevance

		return relevanceB - relevanceA // Sort in descending order
	}

	const sortedProducts = products.sort(customSort)

	return sortedProducts
}

export const getColorData = (bike: any, isAccessory: boolean, bikes: any[]):any[] => {
	const colors = findBikeAttributeByName(bike, "hex_color", false, false)
	let colorData = []

	if (colors && !isAccessory) {
		const arr = []

		for (const data of colors) {
			const color = data.split("|")[0]
			const slug = data.split("|")[1]

			if (slug) {
				let splitSlug = slug.split("/").filter(item => item)
				splitSlug = splitSlug.length > 1 ? splitSlug[1] : slug
				const bikeBySlug = bikes.find(bike => bike.slug === splitSlug)
				arr.push({
					color,
					slug: slug || null,
					onStock: bikeBySlug?.stock_status || StockStatus.outOfStock,
					selected: splitSlug === bike.slug
				})
				colorData = arr
			} else {
				arr.push({
					color,
					slug: slug || null,
					onStock: StockStatus.inStock
				})
				colorData = arr
			}
		}
	}

	return colorData
}

export const injectBikesCategoryToTheTop = (bikes:any[], categorySlug:string) => {
	const featuredBikes = []
	let updatedBikesList = []
	updatedBikesList = bikes.filter(bike => {
		if (bike.categories.some(category => category.slug === categorySlug)) {
			featuredBikes.push(bike)
			return false
		}

		return true
	})

	const result = featuredBikes.concat(updatedBikesList)

	return result
}
