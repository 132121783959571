import {RefObject, useEffect, useState} from "react"

export default function useOnScreen(ref: RefObject<HTMLElement>, changingState:any[] = []) {
	const [isIntersecting, setIntersecting] = useState<boolean>(false)

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

		try {
			if (ref.current) {
				observer.observe(ref.current)
			}
		} catch (e) {
			console.warn(e.message)
		}

		// Remove the observer as soon as the component is unmounted
		return () => {
			observer.disconnect()
		}
	}, [ref, isIntersecting, ...changingState])

	return isIntersecting
}
