import {isCategory} from "../utility/Helper"

interface DiscountResponse {
    isOnSale: boolean,
    discountKey: string
}

export const discountsTypes = {
	onSale: {
		name: "On Sale",
		key: "on-sale"
	},
	sale10: {
		name: "Sale 10",
		key: "sale_10"
	},
	sale20: {
		name: "Sale 20",
		key: "sale_20"
	},
	sale30: {
		name: "Sale 30",
		key: "sale_30"
	},
	fixed: {
		name: "fixed",
		key: "fixed"
	}
}

// This code will send back neccessary data for maping dynamic discount slider and badge

export const getDiscountData = (bike:any):DiscountResponse => {
	if (process.env.NEXT_PUBLIC_FIXED_DISCOUNT) {
		return {
			isOnSale: true,
			discountKey: discountsTypes.fixed.key
		}
	}

	if (isCategory(bike, discountsTypes.sale30.name)) {
		return {
			isOnSale: true,
			discountKey: discountsTypes.sale30.key
		}
	}

	if (isCategory(bike, discountsTypes.sale20.name)) {
		return {
			isOnSale: true,
			discountKey: discountsTypes.sale20.key
		}
	}

	if (isCategory(bike, discountsTypes.sale10.name)) {
		return {
			isOnSale: true,
			discountKey: discountsTypes.sale10.key
		}
	}

	if (isCategory(bike, discountsTypes.onSale.name)) {
		return {
			isOnSale: true,
			// Assigning sale_20 as default discount
			discountKey: discountsTypes.sale20.key
		}
	}

	return {
		isOnSale: false,
		discountKey: null
	}
}
