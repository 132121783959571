import React, {useContext, useEffect} from "react"
import {useTranslation} from "next-i18next"
import PropTypes from "prop-types"
import BikesCollectionItem from "../bikesCollection/BikesCollectionItem/BikesCollectionItem"
import Typography from "../reusable/Typography/Typography"
import {Slides} from "../reusable/components/Slides"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import ComparisonPLPFixedComponent from "../bikesCollection/ComparisonPLPFixedComponent"
import AnalyticsContext from "../../context/AnalyticsContext"

type Props = {
  similarBikes: any[],
  isAccessory?: boolean
}

const SimilarBikes: React.FC<Props> = ({similarBikes, isAccessory
}) => {
	const {t} = useTranslation()
	const tCommon = useTranslation().t
	const {reactGA} = useContext(AnalyticsContext)

	useEffect(() => {
		if (JSON.parse(localStorage.getItem("pdpViewed"))) {
			const pageView = JSON.parse(localStorage.getItem("pdpViewed"))
			pageView.times++

			localStorage.setItem("pdpViewed", JSON.stringify(pageView))
		} else {
			localStorage.setItem("pdpViewed", JSON.stringify({viewed: true, times: 0}))
		}
	}, [])

	return (
		<>
			{!similarBikes || similarBikes.length < 1 ? null :
				<div className={"container"}>
					<div className={"row bikes-collection"}>
						<div className={"col-12 pt-5 pt-md-3"}>
							<div className="text-md-center">
								{isAccessory ? <Typography variant="bodyLgBlack" semanticTag="h2" style={{fontSize: 28}}>
									{t("Kompatible E-Bikes")}
								</Typography> :
									<>
										<Typography variant="bodyLgBlack" className="d-block d-md-none pb-2" semanticTag="h2" style={{fontSize: 28}}>
											{tCommon("Ähnliche Möbel")}
										</Typography>
										<Typography variant="heading2Black" className="d-none d-md-block pb-2" semanticTag="h2">
											{tCommon("Ähnliche Möbel")}
										</Typography>
									</>}
								<div className="d-none d-md-block mb-3">
									<ComparisonPLPFixedComponent sticky={false}/>
								</div>
								<div className="row mt-4 mt-md-5 mb-md-5 justify-content-center">

									<div className="bikes-slider-wrapper d-block d-md-none mb-0 pb-3" style={{minHeight: 545, maxHeight: 755}}>
										<Slides centerMode centerModePadding={{left: 0, bottom: 0, right: 25}} >
											{similarBikes.map((bike, idx) => {
												return (
													<div style={{paddingLeft: "12px"}} onClick={() => {
														reactGA?.event({
															category: gaCategories.homePage,
															action: gaEvents.topModels,
															label: bike.name,
															nonInteraction: false
														})
													}} key={idx} className={"col col-md-6 my-md-0"}>
														<BikesCollectionItem isCarouselElement cardWidth="93%" bike={bike} withArrows withOneImage />
													</div>)
											})}
										</Slides>
									</div>
									<div style={{height: 500, width: "100%"}} className="d-none d-md-flex justify-content-center">
										{similarBikes.map(bike => {
											return (
												<div key={bike.id} className={"col-12 col-md-3"}>
													<BikesCollectionItem bike={bike}
													/>
												</div>
											)
										})}
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="d-md-none">
						<ComparisonPLPFixedComponent sticky={false}/>
					</div>
				</div>}
		</>
	)
}

SimilarBikes.propTypes = {
	similarBikes: PropTypes.array.isRequired
}

export default SimilarBikes

