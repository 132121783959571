/* eslint-disable camelcase */
import {dealPlanLengthFormater, getCountryBy, getCountryValueByLabel, removeUndefined} from "./Helper"
import {gaCategories, gaEvents} from "../config/googleAnalytics/events"

export const uploadAws = async (file: any, tag: string) => {
	const url = process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? "/production/v1/uploadAws" : "/dev/v1/uploadAws"
	const baseURL = process.env.NEXT_PUBLIC_EP_HP_BASE || "https://jzkpl3lyg7.execute-api.eu-central-1.amazonaws.com"

	const reader = new FileReader()

	const data = JSON.stringify({
		name: file.name,
		type: file.type,
		tag
	})

	const response = await fetch(`${baseURL}${url}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"x-api-key": process.env.NEXT_PUBLIC_SERVERLESS_API_KEY
		},
		body: data
	}).then(response => response.json())
		.then(async response => {
			console.log("CheckoutHelpers response==>", response)
			reader.addEventListener("loadend", async e => {
				const fileData = new Blob([reader.result], {type: file.type})

				await fetch(`${response.data.uploadURL}`, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify(fileData)
				}).then(response => {
					console.log("uploadResponse==>", response)
					return	response.json()
				}).catch(e => {
					console.error(e)
				})
			})
			reader.readAsArrayBuffer(file)
			return response
		}).catch(e => {
			console.error(e)
			return null
		})

	return response?.data.imageURL
}

export const formatDeals = (deals: any[], contact: any, uid: string, checkoutId: string, discountCode: string) => {
	return deals.map(deal => {
		return {
			...removeUndefined(deal),
			dealname: `${contact.firstname} ${contact.lastname}, ${deal.e_bike_choice}`,
			plan_length: dealPlanLengthFormater(deal.plan_length),
			amount: deal.amount * 0.25,
			google_user_id: contact.google_user_id,
			google_client_id: contact.google_client_id,
			all_agb: contact.all_agb ? "true" : "false",
			uid,
			checkout_id: checkoutId,
			discount_code: discountCode
		}
	})
}

export const formatContact = contactData => {
	const contact = {...contactData}
	delete contact.onShipAddress
	delete contact.prefix
	delete contact.id_type
	delete contact.permit_valid_until_month
	delete contact.permit_valid_until_day
	delete contact.permit_valid_until_year
	delete contact.date_of_entry_month
	delete contact.date_of_entry_day
	delete contact.date_of_entry_year
	delete contact.date_of_birth_month
	delete contact.date_of_birth_day
	delete contact.date_of_birth_year
	delete contact.bob_agb
	delete contact.heidipay_agb
	delete contact.all_agb

	// Check country choice field and reformat if necessary
	if (!getCountryBy(contact.country_choice, "value")) {
		contact.country_choice = getCountryValueByLabel(contact.country_choice) || "Switzerland"
	}

	// Generate referral code
	contact.unique_referral_code = (contact.lastname + Math.floor((Math.random() * 90) + 10)).toUpperCase()
	return contact
}

export const reformatPhoneNumber = async (prefix: string, phoneNumber: string | number) => {
	const phone = typeof phoneNumber === "string" ? phoneNumber : phoneNumber.toString()
	const _prefix = prefix ? prefix.split(" ")[0] : null
	const {isPossiblePhoneNumber, parsePhoneNumber} = await import("libphonenumber-js")
	// @ts-ignore
	const phoneFormatted = _prefix && phone && isPossiblePhoneNumber(phone, _prefix) ? parsePhoneNumber(phone, _prefix) : null
	return phoneFormatted || null
}

export const events = (data, reactGA) => {
	reactGA?.event({
		category: gaCategories.checkout,
		action: gaEvents.checkoutFormSubmitted,
		label: gaEvents.checkoutFormSubmitted,
		nonInteraction: false,
		value: data.planLength
	})

	// Log event
	reactGA?.event({
		category: gaCategories.checkout,
		action: gaEvents.checkoutStepCompleted,
		label: gaEvents.checkoutStepCompleted,
		nonInteraction: false,
		value: 2
	})

	// Log event
	reactGA?.event({
		category: gaCategories.checkout,
		action: gaEvents.checkoutStep2Completed,
		label: gaEvents.checkoutStep2Completed,
		nonInteraction: false
	})
}
