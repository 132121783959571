import React, {FC, useContext} from "react"
import {CheckLg} from "react-bootstrap-icons"
import Typography from "../../Typography/Typography"
import styles from "./Checkbox.module.scss"
import PropTypes from "prop-types"
import LayoutContext from "../../../../context/LayoutContext"

type Props = {
  label: string
  className?: string
  isActive: boolean
  isLoading?: boolean
  disabled?: boolean
  id?: string
  onChange: () => void,
  type?: string,
  isForm?: boolean
  isBoxShape?: boolean
	labelOnly?: boolean
}

const Checkbox: FC<Props> = ({
	isForm,
	onChange,
	label,
	className,
	isActive,
	isLoading,
	disabled,
	id,
	type = "checkbox",
	isBoxShape = false,
	labelOnly
}) => {
	const {isMobile} = useContext(LayoutContext)

	const handleChange = () => {
		if (!disabled) {
			onChange()
		}
	}

	return (
		<div className={className}>
			<label className={`${disabled ? "pe-none" : "pointer"} d-flex align-items-center`}
				htmlFor={`checkbox-${id ? id + label : label}`}>
				<div className={isBoxShape || labelOnly ? "d-none" : ""} style={{height: 20}}>
					<input disabled={disabled} onChange={handleChange} defaultChecked={isActive}
						className={styles.hideVisually}
						id={`checkbox-${id ? id + label : label}`} type={type}/>
					<span
						className={`${isForm ? styles.checkbox_grey : styles.checkbox} ${isActive ? styles.checkboxActive : ""} border-grey ${disabled ? styles.disabled : ""}`}>
						{isActive && <CheckLg style={{color: "#fff"}}/>}
					</span>
				</div>
				<Typography variant={"bodySm"}
					style={{cursor: isBoxShape ? "pointer" : ""}}
					semanticTag="span">
					{label}
				</Typography>
				{isLoading && <span className="ms-2 spinner-border spinner-border-sm text-primary" role="status"
					aria-hidden="true"/>}
				<span>
				</span>
			</label>
		</div>
	)
}

Checkbox.propTypes = {
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
	isActive: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	onChange: PropTypes.func.isRequired
}
export default Checkbox
