import React, {useState} from "react"
import AnimationComponent from "../components/reusable/components/AnimationComponent/AnimationComponent"
import ToastMessageContext from "../context/ToastMessageContext"

interface Props {
    children: React.ReactNode
}

const ToastMessageContestProvider:React.FC<Props> = ({children}) => {
	const [isToastMessageDisplayed, setIsToastMessageDisplayed] = useState(false)
	const [toastMessageContent, setToastMessageContent] = useState(null)
	const type = "fade"

	return (
		<ToastMessageContext.Provider value={{
			isToastMessageDisplayed,
			setIsToastMessageDisplayed,
			setToastMessageContent
		}}>
			{children}
			<AnimationComponent inProp={isToastMessageDisplayed} type={type}>
				<div className="position-fixed container" style={{
					marginBottom: "5rem",
					bottom: 0,
					left: 0,
					right: 0,
					zIndex: 20000
				}}>

					{toastMessageContent}

				</div>
			</AnimationComponent>
		</ToastMessageContext.Provider>
	)
}

export default ToastMessageContestProvider
