import {useTranslation} from "next-i18next"
import React, {useState} from "react"
import {StockStatus} from "../../bikeDetails/BikeDetails"
import Typography from "../../reusable/Typography/Typography"

type Props = {
    stockStatus: string,
	withHoverEffect?: boolean
	expressPickup?: boolean
}

const ProductAvailableStatus: React.FC<Props> = ({stockStatus, withHoverEffect = false, expressPickup = false}) => {
	const {t} = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	const handleMouseEnter = () => {
		setIsOpen(true)
	}

	const handleMouseLeave = () => {
		setIsOpen(false)
	}

	return (
		<>
			{(stockStatus === StockStatus.inStock || stockStatus === StockStatus.onBackOrder || stockStatus === StockStatus.outOfStock) && <div className="d-flex align-items-center position-relative">
				<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
					style={{width: 8, height: 8, borderRadius: "50%",
						backgroundColor: `${stockStatus === StockStatus.inStock ? "#1FE184" : stockStatus === StockStatus.outOfStock ? "#F72C25" : "#2D4AE4"}`
					}}/>
				{isOpen && withHoverEffect && <div
					className="position-absolute d-flex align-items-center  ms-2 col-auto">
					<Typography variant="bodySm" className="ms-2 col-auto bg-white p-1 rounded-3">
						{t(`${stockStatus === StockStatus.inStock ? "instock" : stockStatus === StockStatus.outOfStock ? "out-of-stock" : "onbackorder-status"}`)}
					</Typography>
				</div>}
			</div>
			}
		</>

	)
}

export default ProductAvailableStatus
