import React from "react"
import Image from "next/image"

type props ={
    label:string
    amount: number
    className?:string
    theme?:string
	isMobile?: boolean
}
function NumberOfVisitors({label, amount, className, theme, isMobile}:props) {
	// In this component we use a string label with a variable in it called x, we replace that x with a number to showcase the number of visitors
	// Example labes: "We have x visitors." will display as: "we have {amount} of visitors.""
	const StringToSHow = label.replace("x", `<b>${amount}</b>`)
	if (amount && amount > 10) {
		return (
			<div className={`alert  d-flex flex-column flex-sm-row align-items-center justify-content-center gap-2 ${theme ? "bg-" + theme : "alert-primary"} ${className}`} style={{marginTop: isMobile ? "4rem" : 0}} role="alert">
				<Image src="/assets/icons/fire.svg" width={20} height={20}/>
				<div dangerouslySetInnerHTML={{__html: StringToSHow}} />
			</div>
		)
	}

	return <></>
}

export default NumberOfVisitors
