import React, {useEffect, useState} from "react"
import getMobileOperatingSystem from "../../../utility/getMobileOperationSystem"

type Props = {
  title?: string
  className?: string
}

const MobileShare = ({title, className}: Props): React.ReactElement => {
	const [os, setOs] = useState(null)
	const storefront = process.env.NEXT_PUBLIC_STOREFRONT

	useEffect(() => {
		setOs(getMobileOperatingSystem())
	}, [])
	const classes = `pointer ${className && className}`

	const handleOnClick = () => {
		if (navigator.share) {
			navigator
				.share({
					title: `${title} | ${storefront === "mybikeplan" ? "mybikeplan.ch" : storefront === "myhomeplan" ? "myhomeplan.ch" : null}`,
					text: `Check out ${title} on ${storefront === "mybikeplan" ? "mybikeplan.ch" : storefront === "myhomeplan" ? "myhomeplan.ch" : null}`,
					url: document.location.href
				})
				.then()
				.catch(error => {
					console.error("Something went wrong sharing the page", error)
				})
		}
	}

	return (
		<div className={classes} onClick={handleOnClick}>
			<img src="/assets/icons/share-btn.svg" alt="sharebtn" />
		</div>
	)
}

export default MobileShare
