import React from "react"
import {StockStatus} from "../BikeDetails"
import Link from "next/link"

type Props = {
    colorData: any,
    isLoadingColors?: boolean,
    small?: boolean,
}

const Colors: React.FC<Props> = ({colorData, small}) => {
	const circleBorder = "1px solid #DEE2E6"
	const circleBorderSelected = "1px solid #212529"

	return (
		<div className="d-flex justify-content-start align-items-center">
			{colorData.map(data => {
				const {color, slug, onStock, selected} = data

				const hexColor = color
				const trimmedSlug = slug?.trim()
				const linkColor = (trimmedSlug && trimmedSlug.charAt(0) === "/") ? trimmedSlug : trimmedSlug ? `/${trimmedSlug}` : undefined

				const outOfStock = onStock === StockStatus.outOfStock
				return (
					linkColor ? (
						<Link href={linkColor} key={color}>
							<a className={`col-auto ${small ? "me-1" : "me-3"}`} onClick={e => {
								if (outOfStock) {
									e.preventDefault()
								}
							}}>
								<div key={color}
									className={`${outOfStock ? "force-no-pointer" : "pointer"} d-flex justify-content-center align-items-center overflow-hidden position-relative border`}
									style={{
										height: small ? 17 : 40,
										width: small ? 17 : 40,
										borderRadius: "50%",
										border: circleBorder
									}}>
									<div style={{
										height: small ? 17 : 30,
										width: small ? 17 : 30,
										borderRadius: "50%",
										border: circleBorder,
										backgroundColor: hexColor
									}}/>
									{outOfStock ?
										<span
											style={{
												transform: "rotate(130deg)",
												position: "absolute",
												top: "50%",
												left: "-10%",
												width: "120%"
											}}
											className={"border-bottom border-dark border-2"}/> :
										null}
								</div>
							</a>
						</Link>
					) : (
						<div key={color}
							className={`${small ? "me-1" : "me-3"} d-flex cursor-pointer justify-content-center align-items-center`}
							style={{
								height: small ? 24 : 40,
								width: small ? 24 : 40,
								borderRadius: "50%",
								border: circleBorderSelected
							}}>
							<div style={{
								height: small ? 17 : 30,
								width: small ? 17 : 30,
								borderRadius: "50%",
								border: hexColor === "#FFFFFF" ? "1px solid #DEE2E6" : "",
								backgroundColor: hexColor
							}}/>
							{outOfStock ?
								<span
									className={"strike-through-diagonal border-bottom border-primary border-2"}/> :
								null}
						</div>
					)
				)
			})}
		</div>
	)
}

export default Colors

