import React, {useContext, useEffect, useRef, useState} from "react"
import DoubleClick from "../doubleClick/DoubleClick"
import {XLg} from "react-bootstrap-icons"
import StoryContext, {Info, StoryItem} from "../../context/StoryContext"
import PropTypes from "prop-types"
import Loading from "./Loading"
import ProgressBar from "./ProgressBar"
import {ModalContext} from "../../context/ModalContext"

export const placeholders: StoryItem[] = [
	{
		id: "1",
		imageUrl: "/assets/media/image/story/mbp_performance_testimonial3_Amira_hight.jpg",
		videoUrl: "/assets/media/video/story/performance_testimonial3_Amira_video1_169.mp4",
		label: "Amira, 32"
	},
	{
		id: "2",
		imageUrl: "/assets/media/image/story/mbp_performance_testimonial1_Manuel_hight.jpg",
		videoUrl: "/assets/media/video/story/performance_testimonial1_Manuel_video1_169.mp4",
		label: "Manuel, 25"
	},
	{
		id: "3",
		imageUrl: "/assets/media/image/story/mbp_performance_testimonial2_Fabio_hight.jpg",
		videoUrl: "/assets/media/video/story/performance_testimonial2_Fabio_video1_196.mp4",
		label: "Fabio, 42"
	}
]

type Props = {
	stories?: StoryItem[],
	currentStory?: StoryItem
}

const Story = ({stories = placeholders, currentStory = null}:Props) => {
	const playerRef = useRef(null)
	const player = playerRef?.current
	const [info, setInfo] = useState<Info>(null)
	const [story, setStory] = useState<StoryItem>(currentStory || stories[0])
	const [refCreated, setRefCreated] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const {closeStory} = useContext(ModalContext)

	const onLoadedMetaData = () => {
		setInfo({
			duration: player.duration,
			currentTime: 0
		})
	}

	const onTimeUpdate = () => {
		setInfo(prevState => {
			return {
				...prevState,
				currentTime: player.currentTime
			}
		})
	}

	const getCurrentIndex = () => stories.indexOf(story)
	const getNextIndex = () => getCurrentIndex() + 1
	const getPreviousIndex = () => getCurrentIndex() - 1

	const onEnded = () => {
		player.currentTime = 0
		next()
	}

	const next = () => {
		const nextIndex = getNextIndex()
		setStory(stories[nextIndex < stories.length ? nextIndex : 0])
	}

	const previous = () => {
		const previousIndex = getPreviousIndex()
		setStory(stories[previousIndex > 0 ? previousIndex : 0])
	}

	const playFromStart = () => {
		player.currentTime = 0
	}

	const onCanPlay = () => {
		setLoading(false)
	}

	useEffect(() => {
		setLoading(true)
	}, [story])

	useEffect(() => {
		if (player) {
			player.play()
		}
	}, [player, story, refCreated])

	useEffect(() => {
		setRefCreated(true)
	}, [])

	return (
		<StoryContext.Provider value={{stories, next, previous, playFromStart, getCurrentIndex, info}}>
			<div className={"position-fixed top-0 start-0 bottom-0 end-0 bg-dark-transparent"} style={{
				zIndex: 20002
			}} onClick={e => {
				closeStory()
			}}>
				<div className={"container"}>
					<div className={"row justify-content-center vh-100 align-items-center"}>
						<div className={"col-12"}>
							<div className={"row justify-content-center"}>
								<div className={"col-12 col-md-6 col-lg-3"}>
									<div className={"video-container px-4"}>
										<div className={"ratio ratio-9x16 overflow-hidden position-relative shadow"} style={{
											borderRadius: 15
										}}>
											<video playsInline ref={playerRef} src={story.videoUrl} onLoadedMetadata={onLoadedMetaData} onTimeUpdate={onTimeUpdate} onEnded={onEnded} onCanPlay={onCanPlay}/>
											{loading && <Loading/>}
											<DoubleClick className={"position-absolute top-0 start-0 bottom-0"} style={{
												width: "50%"
											}} onClick={playFromStart} onDoubleClick={previous}/>
											<DoubleClick className={"position-absolute top-0 bottom-0 end-0"} style={{
												left: "50%",
												width: "50%"
											}} onClick={next}/>
											<ProgressBar/>
										</div>
									</div>
								</div>
							</div>
							<div className={"row justify-content-center mt-4"}>
								<div className={"col-auto"}>
									<button className={"btn bg-light rounded-circle position-relative"} style={{
										width: 40,
										height: 40
									}} onClick={e => {
										e.stopPropagation()
										closeStory()
									}}>
										<div className={"row justify-content-center align-items-center"} style={{
											height: "100%"
										}}>
											<div className={"col-auto"}>
												<XLg/>
											</div>
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StoryContext.Provider>
	)
}

Story.propTypes = {
	videos: PropTypes.array
}

export default Story
