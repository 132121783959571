import React from "react"
import Typography from "../reusable/Typography/Typography"
import Price from "../bikesCollection/BikesCollectionItem/Price"
import TotalPrice from "../bikesCollection/BikesCollectionItem/TotalPrice"
import {useTranslation} from "next-i18next"
import Link from "next/link"
import Image from "next/image"

type Props = {
  product: any,
  regularPrice: string,
  secondHandPrice: number,
  isSecondHand: boolean,
  bike?: any,
  onHandleClosePopup?: ()=> void
}

const CompatibleAccessory: React.FC<Props> = ({product, regularPrice, secondHandPrice, isSecondHand, bike, onHandleClosePopup = null}) => {
	const {t} = useTranslation("common")
	const productUrl = `/${t("produkt")}/${product.slug}`

	return (
		<Link href={productUrl}>
			<a className={"link-reset"} onClick={onHandleClosePopup}>
				<div style={{
					minHeight: 120,
					backgroundColor: "#F8F8F8",
					borderRadius: 16,
					padding: 12
				}}>
					<div style={{
						maxWidth: "100%",
						height: 96,
						backgroundColor: "#fff",
						borderRadius: 8,
						padding: "12px 0"
					}} className="d-flex px-2">
						<Image src={product.images[0].src} alt="bike accessory" height={72} width={72}/>
						<div className="ps-3 text-start">
							<Typography style={{maxWidth: 160}} className="mb-2 d-inline-block "
								variant={"bodyXSmExtraBold"}> {product.name}</Typography>
							<div>
								<div className={"col-12 px-0"}>
									<Price
										isAccessoryPrice={true}
										price={parseFloat(product.price)}
										onSale={false} salePrice={parseFloat(product.price)} regularPrice={parseFloat(regularPrice)} isSecondHand={isSecondHand} secondHandPrice={secondHandPrice} priceFontSize={24} />
									<TotalPrice isAccessoryPrice={true} price={parseFloat(product.price)} onSale={false}
										regularPrice={regularPrice} isSecondHand={isSecondHand} priceVariant="bodyXSm"
										secondHandPrice={secondHandPrice}/>
								</div>
							</div>

						</div>
					</div>
				</div>
			</a>
		</Link>
	)
}

export default CompatibleAccessory
