import React from "react"
import Typography from "./Typography/Typography"
import {useTranslation} from "next-i18next"
import priceFormatter from "../../utility/priceFormatter"

type Props = {
    variant:any
    onSale:boolean
    isSecondHand:boolean
    downPayment:number
}
function YourDownPayment({variant, onSale, isSecondHand, downPayment}:Props) {
	const {t} = useTranslation("common")
	if (downPayment) {
		return (
			<div className="col-12 mt-1 justify-content-start align-items-end d-flex">
				<Typography variant={variant} semanticTag="p" className={"me-1"}
					style={{color: onSale || isSecondHand ? "#E61C45" : ""}}>
					{t("your_downpayment")}:
				</Typography>
				<Typography variant={variant} semanticTag="p" style={{color: onSale || isSecondHand ? "#E61C45" : ""}}>
					{`CHF ${priceFormatter(downPayment)}`}
				</Typography>
			</div>
		)
	}

	return <></>
}

export default YourDownPayment
