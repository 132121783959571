/* eslint-disable camelcase */

// Custom instances
export const stockTypes = {
	physical: "physical"
}

export enum PlanLength {
	fullPayment= "full-payment"
}

export const instanceDiscounts = {
	mbp: {
		sale_10: {
			"pay-one-time": {
				label: "-10%",
				percentage: 0.9
			},
			12: {
				label: "-7.5%",
				percentage: 0.925
			},
			24: {
				label: "-5%",
				percentage: 0.95
			},
			36: {
				label: null,
				percentage: 1
			},
			48: {
				label: null,
				percentage: 1
			}
		},
		sale_20: {
			"pay-one-time": {
				label: "-20%",
				percentage: 0.8
			},
			12: {
				label: "-10%",
				percentage: 0.9
			},
			24: {
				label: "-7.5%",
				percentage: 0.925
			},
			36: {
				label: "-5%",
				percentage: 0.95
			},
			48: {
				label: null,
				percentage: 1
			}
		},
		fixed: {
			"pay-one-time": {
				label: "-20%",
				percentage: 0.8
			},
			12: {
				label: "-10%",
				percentage: 0.9
			},
			24: {
				label: "-7.5%",
				percentage: 0.925
			},
			36: {
				label: "-5%",
				percentage: 0.95
			},
			48: {
				label: null,
				percentage: 1
			}
		}
	},
	mhp: {
		sale_10: {
			"pay-one-time": {
				label: "-10%",
				percentage: 0.9
			},
			12: {
				label: "-7.5%",
				percentage: 0.925
			},
			24: {
				label: "-5%",
				percentage: 0.95
			},
			36: {
				label: "-5%",
				percentage: 0.95
			},
			48: {
				label: null,
				percentage: 1
			}
		},
		sale_20: {
			"pay-one-time": {
				label: "-20%",
				percentage: 0.8
			},
			12: {
				label: "-10%",
				percentage: 0.9
			},
			24: {
				label: "-7.5%",
				percentage: 0.925
			},
			36: {
				label: "-5%",
				percentage: 0.95
			},
			48: {
				label: null,
				percentage: 1
			}
		},
		sale_30: {
			"pay-one-time": {
				label: "-30%",
				percentage: 0.7
			},
			12: {
				label: "-20%",
				percentage: 0.8
			},
			24: {
				label: "-15%",
				percentage: 0.85
			},
			36: {
				label: "-10%",
				percentage: 0.9
			},
			48: {
				label: null,
				percentage: 1
			}
		},
		fixed: {
			"pay-one-time": {
				label: "-20%",
				percentage: 0.8
			},
			12: {
				label: "-10%",
				percentage: 0.9
			},
			24: {
				label: "-7.5%",
				percentage: 0.925
			},
			36: {
				label: "-5%",
				percentage: 0.95
			},
			48: {
				label: null,
				percentage: 1
			}
		}
	},
	mbp_bfe: {
		sale_10: {
			"pay-one-time": {
				label: "-12%",
				percentage: 0.88
			},
			12: {
				label: "-7.5%",
				percentage: 0.925
			},
			24: {
				label: "-5%",
				percentage: 0.95
			},
			36: {
				label: null,
				percentage: 1
			},
			48: {
				label: null,
				percentage: 1
			}
		},
		sale_20: {
			"pay-one-time": {
				label: "-22%",
				percentage: 0.78
			},
			12: {
				label: "-10%",
				percentage: 0.9
			},
			24: {
				label: "-7.5%",
				percentage: 0.925
			},
			36: {
				label: "-5%",
				percentage: 0.95
			},
			48: {
				label: null,
				percentage: 1
			}
		},
		fixed: {
			"pay-one-time": {
				label: "-25%",
				percentage: 0.75
			},
			12: {
				label: "-20%",
				percentage: 0.80
			},
			24: {
				label: "-15%",
				percentage: 0.85
			},
			36: {
				label: "-10%",
				percentage: 0.9
			},
			48: {
				label: null,
				percentage: 1
			}
		}
	},
	mhp_bfe: {
		fixed: {
			"pay-one-time": {
				label: "-20%",
				percentage: 0.8
			},
			12: {
				label: "-15%",
				percentage: 0.85
			},
			24: {
				label: "-10%",
				percentage: 0.9
			},
			36: {
				label: "-7.5%",
				percentage: 0.925
			},
			48: {
				label: null,
				percentage: 1
			}
		}
	}
}

