import Image from "next/image"
import React from "react"
import Typography from "../reusable/Typography/Typography"

type Props = {
    count: number
    t: (text: string) => string
}

const BikeOrdersCount = ({count, t}: Props) => {
	return (
		<div className="col-12">
			{count >= 1 && <span className="d-flex align-items-start p-2 rounded-3" style={{border: "1px solid #DDDDE3"}}>
				<Image src="/assets/icons/fire.svg" width={20} height={20}/>

				<div className="ms-2">
					<Typography variant="bodySmBold" semanticTag="span">{count} </Typography>
					<Typography variant="bodySmBold" semanticTag="span"> {t("customers")} </Typography>
					<Typography variant="bodySm" semanticTag="span">{t("bought this e-bike this week")}</Typography>
				</div>
			</span>
			}
		</div>
	)
}

export default BikeOrdersCount
