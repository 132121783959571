import React from "react"

export type SearchContextProviderState = {
    isOn: boolean,
    toggle: (toggle: boolean) => void,
    isLoading: boolean,
    setLoading: (toggle: boolean) => void,
	results: any | null,
	setResults: (results: any | null)=>void,
	value: string | null,
	setValue: (value: string | null)=>void,
	handleSearch: (value:string)=>void,
	recentSearchTerms: string[],
	recentSearchTermsRefs: any[],
	setRecentSearchTermsRefs: (value:any)=>void,
	termValue: string,
	setTermValue: (value:string)=>void
}

const SearchContext = React.createContext<SearchContextProviderState>({
	isOn: false,
	toggle: toggle => {
		// Do nothing
	},
	isLoading: false,
	setLoading: toggle => {
		// Do nothing
	},
	results: null,
	setResults: results => {
		// Do nothing
	},
	value: null,
	setValue: value => {
		// Do nothing
	},
	handleSearch: value => {
		// Do nothing
	},
	recentSearchTerms: [],
	recentSearchTermsRefs: [],
	setRecentSearchTermsRefs: value => {
		// Do nothing
	},
	termValue: "",
	setTermValue: value => {
		// Do nothing
	}
})

export default SearchContext
