import React from "react"

export const devices = {
	phone: "phone",
	tablet: "tablet",
	desktop: "desktop",
	node: "node"
}

export type LayoutContextProviderState = {
  isMobile: boolean
  device: string
  isListView: boolean
  isGridView: boolean
  setDefaultViewCollectionPage: () => void,
  setListViewCollectionPage: () => void
  setGridViewCollectionPage: () => void

}

const LayoutContext = React.createContext<LayoutContextProviderState>({
	isMobile: false,
	device: devices.desktop,
	isListView: false,
	isGridView: false,
	setDefaultViewCollectionPage: () => {
	},
	setListViewCollectionPage: () => {
	},
	setGridViewCollectionPage: () => {
	}
})

export default LayoutContext
