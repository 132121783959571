import {useTranslation} from "next-i18next"
import React from "react"
import Typography from "../reusable/Typography/Typography"
import Link from "next/link"
import {useRouter} from "next/router"

interface Props {
  shortDescription: string
	isMobile?: boolean
}

const ShortDescriptionPDP: React.FC<Props> = ({
	shortDescription,
	isMobile
}) => {
	const {t} = useTranslation("common")
	const {asPath} = useRouter()
	const linkToDescription = isMobile ? asPath.split("#")[0] + "#next-description-section-mobile" : asPath.split("#")[0] + "#next-description-section"
	return (
		<div className="mb-4">
			<Typography variant="bodyLg" semanticTag="p" style={{marginBottom: 8}}>
				{shortDescription}
			</Typography>

			<Link href={linkToDescription}>
				<div className={"d-flex pointer"}>
					<Typography variant="bodyLgBold" style={{fontSize: 14}} semanticTag="span">
						{t("read-more")}
					</Typography>
					<img src="/assets/icons/arrow-down.svg" alt="arrowdown" style={{marginLeft: 13}}/>
				</div>
			</Link>
		</div>

	)
}

export default ShortDescriptionPDP
