import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {customPlanLengthMap} from "./customPlanLengthMap"

interface Props {
    checkoutData: any
    setCheckoutData: (arg0: any) => void
    t: (arg0: string) => string
    isMobile: boolean
    isOpen: boolean
    payOneTime: boolean
	durationInMonth: (string | number)[]
	hidePayOneTimeLabel?:boolean

}

const RegularDurationLabels: React.FC<Props> = ({
	checkoutData,
	setCheckoutData,
	t,
	isMobile,
	isOpen,
	payOneTime,
	durationInMonth,
	hidePayOneTimeLabel
}) => {
	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	return (
		<div
			className={`row g-2 mb-2 pb-1 justify-content-between ${isMobile && `accordion-collapse mt-3 collapse ${isOpen ? "show" : null}`}`}>
			{durationInMonth.map((month, i) => {
				if (month === "pay-one-time" && (!payOneTime || hidePayOneTimeLabel)) {
					return
				}

				return (<div key={i} cypress-slider-label-to-select={`label-${i}`} style={{
					borderRadius: 16,
					cursor: "pointer"
				}} className={"col-2 mt-2 plan-length-label-container text-center"}
				onClick={() => {
					setCheckoutData(prevData => {
						return ({
							...prevData,
							planLength: month
						})
					})
				}}>
					<Typography
						style={{color: checkoutData?.planLength === 1 ? "grey" : "#212529"}}
						variant={checkoutData?.planLength === month ? "bodySmBold" : "bodySm"}>
						{t(`${month === "pay-one-time" ? month : planLengthMap[month]}`)}
					</Typography>
				</div>)
			}
			)}
		</div>
	)
}

export default RegularDurationLabels
