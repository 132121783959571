/* eslint-disable complexity */
import React, {forwardRef, useContext, useState} from "react"
import Link from "next/link"
import BikeCollectionItemLoadingOverlay from "../BikeCollectionItemLoadingOverlay"
import Price from "./Price"
import TotalPrice from "./TotalPrice"
import Typography from "../../reusable/Typography/Typography"
import CollectionItemBadge from "./CollectionItemBadge"
import CustomSlider from "../../imageSlides/CustomSlider"
import {isOccasionBike} from "../../../utility/Helper"
import ComparisonCheckboxButton from "./ComparisonCheckboxButton"
import Image from "next/image"
import StockWarning from "./StockWarning"
import useHandleDownPayment from "../../../hooks/handleDownPayment"
import {useTranslation} from "next-i18next"
import ProductAvailableStatus from "./ProductAvailabelStatus"
import ProductColors from "./ProductColors"
import YourDownPayment from "../../reusable/YourDownPayment"
import DataContext from "../../../context/DataContext"

type BadgeProps = {
    is2023: boolean
    is2022: boolean
    isNew: boolean
    kilometers: boolean
    occasion: boolean
    onSale: boolean
    isBikeOfTheMonth: boolean
    isInStock: boolean
    isNonEBike: boolean
	discountKey: string
	isFeatured:boolean
}

type Props = {
  productUrl: string
  selected: boolean
  soldOut: boolean
  variations: any
  dImageSlides: boolean
  attachments: string[]
  bikeWeight: number
  brand: string
  stockInfo: string
  loadingVariations: boolean
  loading: boolean
  regularPrice: string
  bike: any
  badgeProps: BadgeProps
  setDImageSlides: React.Dispatch<React.SetStateAction<boolean>>
  handleMouseEnter: ()=> void
  handleMouseLeave: ()=> void
  handleClick?: React.MouseEventHandler<HTMLElement>
  secondHandPrice: number
  isRowForMobile?: boolean,
  cardWidth?: string,
  isTwoCardsView?: boolean,
  withArrows?: boolean,
  isCarouselElement?: boolean,
  withOneImage?: boolean,
  mainCategory?: string
}

const CollectionItemGridView = forwardRef<HTMLElement, Props>(({
	secondHandPrice,
	productUrl,
	brand,
	selected,
	soldOut,
	loading,
	regularPrice,
	bike,
	badgeProps,
	isCarouselElement,
	handleMouseEnter,
	handleMouseLeave,
	handleClick,
	isRowForMobile,
	cardWidth = "288px",
	isTwoCardsView,
	withArrows,
	withOneImage,
	mainCategory
}, ref) => {
	const {isBikeOfTheMonth, isFeatured, is2023, is2022, isNew, kilometers, occasion, onSale, isInStock, isNonEBike, discountKey} = badgeProps
	const isSecondHand = isOccasionBike(bike)
	const hasBadge = isFeatured || isBikeOfTheMonth || is2023 || is2022 || isNew || kilometers || occasion || onSale || isInStock || isNonEBike
	const isMyHomePlan = process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan"
	const [showArrows, setShowArrows] = useState<boolean>(false)
	const {comparisonProducts} = useContext(DataContext)
	const isActive = comparisonProducts.includes(bike.slug)
	const {totalPhysicalStock} = bike
	const {t} = useTranslation("common")
	const handleShowArrowsMouseEnter = () => {
		setShowArrows(true)
	}

	const handleHideArrowsMouseLeave = () => {
		setShowArrows(false)
	}

	const {downPayment} = useHandleDownPayment(parseFloat(bike.price))
	const afterDownPaymentPrice = bike.price - downPayment < 0 ? 0 : bike.price - downPayment
	const getPriceForPartners = () => {
		if (process.env.NEXT_PUBLIC_ORGANIZATION) {
			return parseFloat(bike.price) - parseFloat(bike?.customInstanceDiscount) - downPayment < 0 ? 0 : parseFloat(bike.price) - parseFloat(bike?.customInstanceDiscount) - downPayment || null
		}

		return null
	}

	return (
		<>{isRowForMobile ? <Link href={productUrl} passHref={true}>
			<a className={`link-reset ${isTwoCardsView ? "p-1" : "p-3"} card card-item ${selected ? "card-shadow" : null}`}
				onClick={handleClick} style={{
					zIndex: selected ? 9999 : null,
					opacity: soldOut ? 0.5 : null,
					transition: "transform 0.2s",
					transformOrigin: "center center",
					minHeight: isTwoCardsView ? "100%" : 400,
					pointerEvents: soldOut ? "none" : "auto",
					borderRadius: "24px",
					backgroundColor: "#fff",
					minWidth: isTwoCardsView ? cardWidth : 343,
					border: isFeatured ? "1px solid #B9DBAA" : "1px solid #DEE2E6",
					cursor: "pointer"
				}}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div>
					<div style={{
						borderRadius: isTwoCardsView ? 32 : 16,
						backgroundColor: "#fff",
						padding: "11px",
						marginBottom: "2px",
						width: "100%"
					}} className="d-flex flex-column position-relative text-start">
						{hasBadge && <div
							className="ps-0 d-flex align-items-start z-index"
							style={{marginTop: -35, marginLeft: -10}}>
							<CollectionItemBadge
								isBikeOfTheMonth={isBikeOfTheMonth}
								isNonEBike={isNonEBike}
								is2023={is2023}
								is2022={is2022}
								isNew={isNew}
								isFeatured={isFeatured}
								inStock={bike.stock_status === "instock"}
								kilometers={kilometers}
								occasion={occasion}
								onSale={onSale}
								soldOut={soldOut}
								discountKey={discountKey}
							/>

						</div>}
						<div style={{width: "100%", paddingTop: "8px"}} className="mx-auto">
							{withOneImage ? <Image style={{borderRadius: 8}} src={bike.images[0].src} alt={bike.name} width={288} height={288}
								objectFit={"cover"}/> :
								<CustomSlider dots={!withArrows} arrows={withArrows} productUrl={productUrl}
									slidesList={bike.images}/>
							}
							<StockWarning amount={totalPhysicalStock as number} />
						</div>
					</div>
				</div>
				<div
					className="mt-3 d-flex flex-column px-2 text-start justify-content-center">
					<div className={"my-2 "}>
						<div>
							<Typography variant={isTwoCardsView ? "bodyXSmBold" : "bodyLgBlack"} semanticTag="h4"
								className="mb-1"
								style={{fontSize: 12, width: "100%"}}>
								{bike.name}
							</Typography>
							<div
								className={` d-flex ${isTwoCardsView ? "flex-row mb-2" : "mb-4"} justify-content-between align-items-center`}>
								<Typography variant={isTwoCardsView ? "bodyXSm" : "bodySm"} semanticTag="span"
								>
									{mainCategory}
								</Typography>
							</div>
						</div>
						<div>
							<Price customInstanceDiscountPrice={getPriceForPartners()} price={afterDownPaymentPrice} salePrice={afterDownPaymentPrice}
								regularPrice={parseFloat(regularPrice)} onSale={onSale} isSecondHand={isSecondHand}
								secondHandPrice={secondHandPrice} isRowForMobile
								priceFontSize={24}/>
							<TotalPrice price={afterDownPaymentPrice} onSale={onSale}
								regularPrice={regularPrice} listView isSecondHand={isSecondHand}
								secondHandPrice={secondHandPrice} customInstanceDiscount={bike?.customInstanceDiscount || null}
								priceVariant={isTwoCardsView ? bike?.customInstanceDiscount || isSecondHand || onSale ? "bodyXSmBoldMdBodySmBold" : "bodySm" : "bodyXSm"} />
							{/* Show DownPayment in the product card: */}
							<YourDownPayment onSale={onSale} downPayment={downPayment} isSecondHand={isSecondHand}
								variant={ "bodyXSm" }/>
						</div>
						<div className="mt-3">
							<div className="d-flex justify-content-between align-items-center">
								<Typography variant="bodyXSm" semanticTag="p">{isMyHomePlan ? brand : mainCategory} { }</Typography><div
									className={` d-flex ${isTwoCardsView ? "flex-column mb-2" : "mb-4"} justify-content-between align-items-center`}>
									<Typography variant={isTwoCardsView ? "bodyXSm" : "bodySm"} semanticTag="span">
										{mainCategory}
									</Typography>
									<ProductAvailableStatus stockStatus={bike?.stock_status}
										withHoverEffect={true}/>
								</div></div>

							<ProductColors colors={bike.colorData}/>
						</div>
					</div>
					<div className={`col-12 ${!isTwoCardsView && "mt-3 mb-2"}`} style={{height: 1, background: "#DEE2E6"}}/>
					<div className="col-12">
						<ComparisonCheckboxButton slug={bike.slug}/>
					</div>
				</div>
				{loading ? <BikeCollectionItemLoadingOverlay/> : null}
			</a>
		</Link> :
			<Link href={productUrl} passHref={true}>
				<a className={"link-reset"} onClick={handleClick}>
					<article style={{
						position: !isCarouselElement && selected ? "absolute" : "relative",
						zIndex: selected ? 100 : null,
						opacity: soldOut ? 0.5 : null,
						transition: "all 0.2s",
						transformOrigin: "center center",
						pointerEvents: soldOut ? "none" : "auto",
						borderRadius: "24px",
						minHeight: bike?.customInstanceDiscount ? 532 : "474px",
						width: cardWidth,
						padding: isTwoCardsView ? "1rem" : " 8px",
						cursor: "pointer",
						border: isActive ? "1px solid #2CA58D" : isFeatured ? "1px solid #FEC04D" : "1px solid #DEE2E6"
					}}
					className={`${selected ? "collection-grid-view-item" : null}  card  card-item ${selected ? "card-shadow" : null}`}
					onMouseEnter={() => {
						handleMouseEnter()
						handleShowArrowsMouseEnter()
					}}
					onMouseLeave={() => {
						handleMouseLeave()
						handleHideArrowsMouseLeave()
					}}
					>
						<div className="p-0">
							<div style={{
								borderRadius: isTwoCardsView ? 32 : 16,
								backgroundColor: "#fff",
								padding: "5px",
								position: "relative"

							}} className="d-flex flex-column text-start">
								{hasBadge && <div
									className="position-absolute  d-flex align-items-start z-index"
								>
									<CollectionItemBadge
										isNonEBike={isNonEBike}
										isBikeOfTheMonth={isBikeOfTheMonth}
										is2023={is2023} is2022={is2022}
										isNew={isNew}
										isFeatured={isFeatured}
										inStock={bike.stock_status === "instock"}
										kilometers={kilometers} occasion={occasion}
										onSale={onSale}
										discountKey={discountKey}
										soldOut={soldOut}
										itemsLeft={totalPhysicalStock as number}
									/>
								</div>}
								<div style={{width: "96%", borderRadius: "1rem"}} className="mx-auto">
									{withOneImage ?
										<Image style={{borderRadius: 8}} src={bike.images[0].src} alt={bike.name} width={288} height={288}
											objectFit={"cover"}/> :
										<CustomSlider dots={selected} arrows={selected} productUrl={productUrl}
											slidesList={bike.images}/>
									}
									<StockWarning amount={totalPhysicalStock as number} />
								</div>
							</div>
						</div>
						<div style={{padding: "16px", minHeight: "50px"}} className="mt-3 d-flex flex-column text-start justify-content-center card-item-price-section">
							{process.env.NEXT_PUBLIC_ORGANIZATION && bike?.customInstanceDiscount && <div className="d-flex align-items-start gap-2"> <Image src={"/assets/icons/sbb_vector.png"} width={28.68} height={18}/><Typography style={{marginTop: -2}} variant={ "bodyXSm"} semanticTag="span">
								{t("sbb_exclusive")}
							</Typography></div>}
							<Typography variant={isTwoCardsView ? "bodySmBold" : "bodyXSmBold"}
								semanticTag="div"
								className="mb-1"
								style={{fontSize: isTwoCardsView ? 16 : 14, width: "100%", lineHeight: "20px"}}>
								{bike.name}
							</Typography>
							<div className={`${isTwoCardsView ? "mb-3" : "mt-2"} `}>
								<Price price={afterDownPaymentPrice} salePrice={afterDownPaymentPrice}
									regularPrice={parseFloat(regularPrice)} onSale={onSale}
									customInstanceDiscountPrice={getPriceForPartners()}
									isSecondHand={isSecondHand}
									secondHandPrice={secondHandPrice} isRowForMobile
									priceFontSize={24}/>
								<TotalPrice price={afterDownPaymentPrice} onSale={onSale}
									regularPrice={regularPrice} listView isSecondHand={isSecondHand}
									secondHandPrice={secondHandPrice} customInstanceDiscount={bike?.customInstanceDiscount || null}
									priceVariant={isTwoCardsView ? "bodyXSm" : bike?.customInstanceDiscount || isSecondHand || onSale ? "bodyXSmBoldMdBodySmBold" : "bodySm"}/>
								{/* Show DownPayment in the product card: */}
								<YourDownPayment onSale={onSale} downPayment={downPayment} isSecondHand={isSecondHand}
									variant={ "bodyXSm" }/>
							</div>
							<div className="mt-3">
								<div className="d-flex justify-content-between align-items-center">
									<Typography variant="bodyXSm" semanticTag="p">{isMyHomePlan ? brand : mainCategory} { }</Typography><div
										className={` d-flex ${isTwoCardsView ? "flex-column mb-2" : "mb-4"} justify-content-between align-items-center`}>
										<Typography variant={isTwoCardsView ? "bodyXSm" : "bodySm"} semanticTag="span">
											{mainCategory}
										</Typography>
										<ProductAvailableStatus stockStatus={bike?.stock_status}
											withHoverEffect={true}/>
									</div></div>

								<ProductColors colors={bike.colorData}/>
							</div>
							{selected && <div className={"d-none d-md-block "}>
								<div className={`col-12 ${!isTwoCardsView && "mt-3 mb-2"}`} style={{height: 1, background: "#DEE2E6"}}/>
								<div className="col-12 "><ComparisonCheckboxButton slug={bike.slug}/></div>
							</div>}
						</div>
						{loading ? <BikeCollectionItemLoadingOverlay/> : null}
					</article>
				</a>
			</Link>
		}
		</>
	)
})

export default CollectionItemGridView

CollectionItemGridView.displayName = "CollectionItemGridView"
