export enum BrandPages {
	"E-Urban" = 1,
	"E-Mountain",
	"E-Trekking",
	"Herren",
	"Damen",
	"Wave",
	"Diamant",
	"Trapez",
	"25 km",
	"45 km"
}
export enum CatCollectionPages {
	"Marke Focus" = 1,
	"Marke RuffCycle",
	"Marke Haibike",
	"Marke Kalkhoff",
	"Marke Ghost",
	"Marke Stromer",
	"Marke Allegro",
	"Marke Simpel",
	"Marke Fischer",
	"Marke Benno",
	"Marke Cilo",
}
export enum CollectionName {
	AllBikes= "AllBikes",
	Accessories= "Accessories",
	Categories="Categories",
	Brands="Brands",
	Custom="Custom"
}
