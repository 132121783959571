const priceFormatter = (price: number) => {
	const isFloat = Boolean(price?.toString().includes("."))

	if (isFloat) {
		const strPrice = price?.toString().split(".")
		const priceIntegers = formatIntegers(Number(strPrice[0]))
		const priceDecimals = strPrice[1]

		return `${priceIntegers}.${priceDecimals}`
	}

	return formatIntegers(price)
}

export default priceFormatter

const formatIntegers = (price: number) => {
	const priceArr = price?.toString().split("")
	const priceFormated = []

	if (!Array.isArray(priceArr)) {
		return null
	}

	if (priceArr.length <= 3) {
		return priceArr.join("")
	}

	for (let i = 0; i < priceArr.length; i++) {
		if (i < 1) {
			priceFormated.push(priceArr[i])
			priceFormated.push(",")
		} else {
			priceFormated.push(priceArr[i])
		}
	}

	return priceFormated.join("")
}
