import {JSONHeaders} from "./fetchConfig"

export const fetchGet = async (url:string, headers?:any, mode?:string) => {
	try {
		const _headers = headers ? {
			...JSONHeaders,
			...headers
		} : JSONHeaders

		const response = await fetch(url, {
			method: "get",
			// @ts-ignore
			mode: mode ? mode : "no-cors",
			headers: _headers
		})

		return response
	} catch (e) {
		console.error(e)
		return null
	}
}

