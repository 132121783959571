const urlParams = {
	category: "category",
	frameVariants: "frameVariants",
	speed: "speed",
	page: "page",
	brand: "brand",
	motorBrand: "motorBrand",
	sortBy: "sortBy",
	sortOrder: "sortOrder",
	bikerHeight: "bikerHeight",
	priceRange: "priceRange",
	availability: "availability",
	type: "type",
	accessoryBrand: "accessoryBrand",
	searchValue: "searchValue",
	batterieSize: "batterieSize",
	attachments: "attachments",
	maxGewicht: "maxGewicht",
	filtersFarbe: "filtersFarbe"
}

export const getAttributeNamebyUrlParam = (param: string) => {
	if (param === urlParams.frameVariants) {
		return "Rahmenvarianten"
	}

	if (param === urlParams.speed) {
		return "Geschwindigkeit"
	}

	if (param === urlParams.page) {
		return "page"
	}

	if (param === urlParams.brand) {
		return "Marke"
	}

	if (param === urlParams.motorBrand) {
		return "Motor Marke"
	}

	if (param === urlParams.bikerHeight) {
		return "Körpergrösse"
	}

	if (param === urlParams.type) {
		return "accessory_type"
	}

	if (param === urlParams.accessoryBrand) {
		return "accessory_brand"
	}

	if (param === urlParams.batterieSize) {
		return "batterie_size"
	}

	if (param === urlParams.attachments) {
		return "Attachments"
	}

	if (param === urlParams.maxGewicht) {
		return "Max. Gewicht"
	}

	if (param === urlParams.filtersFarbe) {
		return "Filters Farbe"
	}
}

export const getUrlParamByAttributeName = (name: string, t: any = null) => {
	if (name === "Rahmenvarianten") {
		return urlParams.frameVariants
	}

	if (name === "Geschwindigkeit") {
		return urlParams.speed
	}

	if (name === "Marke") {
		return urlParams.brand
	}

	if (name === urlParams.page) {
		return "page"
	}

	if (name === "Motor Marke") {
		return urlParams.motorBrand
	}

	if (name === "Körpergrösse") {
		return urlParams.bikerHeight
	}

	if (name === "categories") {
		return urlParams.category
	}

	if (name === "availability") {
		return urlParams.availability
	}

	if (name === "accessory_brand") {
		return urlParams.accessoryBrand
	}

	if (name === "accessory_type") {
		return urlParams.type
	}

	if (name === "batterie_size") {
		return urlParams.batterieSize
	}

	if (name === "Attachments") {
		return urlParams.attachments
	}

	if (name === "Max. Gewicht") {
		return urlParams.maxGewicht
	}

	if (name === "Filters Farbe") {
		return urlParams.filtersFarbe
	}
}

export default urlParams
