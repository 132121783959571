import React from "react"
import parse from "html-react-parser"
import {findBikeAttributeByName} from "../../utility/Helper"
import Typography from "../reusable/Typography/Typography"
import BrandInfo from "./BrandInfo"
import AboutThisBike from "./AboutThisBike"

const getAccordionContent = (bike: any, isAccessory:boolean, t:(_:string)=>string, contentfulPageData) => {
	const videoEmbed = findBikeAttributeByName(bike, "youtube_link", true)
	const {brandInfoItemContentful} = contentfulPageData

	return [
		{
			title: "Übersicht",
			titleContent: (<div>
				<Typography variant="heading3" semanticTag="h3">
					{t("Übersicht")}
				</Typography>
			</div>),
			content: (
				<div className={"bike-details-tab-panel"}>
					<div className={"row d-flex justify-content-between"} >
						<div className={`col-12 ${process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ? "col-lg-12" : "col-lg-6"}`}>
							{parse(bike.description)}
						</div>
						{process.env.NEXT_PUBLIC_STOREFRONT !== "myhomeplan" &&
						<div className={"col-12 col-lg-5 mt-4 mt-lg-0 d-none d-md-block"}>
							<div style={{maxWidth: 568}}>
								<AboutThisBike bike={bike} isForPDP/>
							</div>
						</div>
						}
					</div>
				</div>
			)
		},
		{
			title: "Beschreibung",
			titleContent: (<div>
				<Typography variant="heading3" semanticTag="h3">
					{t("Beschreibung")}
				</Typography>
			</div>),
			content: (
				<div className={"bike-details-tab-panel pt-4 pb-4"}>
					<div style={{fontSize: 20}}>{parse(bike.short_description)}</div>
					{brandInfoItemContentful && <BrandInfo brandInfoItemContentful={brandInfoItemContentful} />}
				</div>
			)
		},
		{
			title: "Einzelheiten",
			titleContent: (<div>
				<Typography variant="heading3" semanticTag="h3">
					{t("Einzelheiten")}
				</Typography>
			</div>),
			content: (
				<div className={"bike-details-tab-panel pt-4 pb-4"}>
					<div className={"row"}>
						<div className={"col-12 col-md-6"}>
							<table className="table">
								<tbody>
									{bike.attributes
										.slice(0, Math.ceil(bike.attributes.length / 2))
										.filter(attribute => attribute.name !== "youtube_link" && attribute.name !== "hex_color").map((attribute, index) => (
											<tr key={attribute.id + "-" + index}>
												<td>
													<Typography variant="bodyLgBold" semanticTag="span">
														{t(attribute.name)}
													</Typography>
												</td>
												<td>
													<Typography variant="bodyLg">{t(attribute.options[0])}</Typography>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
						<div className={"col-12 col-md-6"}>
							<table className="table">
								<tbody>
									{bike.attributes
										.slice(Math.ceil(bike.attributes.length / 2))
										.filter(attribute => attribute.name !== "youtube_link" && attribute.name !== "hex_color")
										.map((attribute, index) => (
											<tr key={attribute.id + "-" + index}>
												<td>
													<Typography variant="bodyLgBold" semanticTag="span">
														{t(attribute.name)}
													</Typography>
												</td>
												<td>
													<Typography variant="bodyLg">{t(attribute.options[0])}</Typography>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)
		},
		{
			title: "Youtube Videos",
			titleContent: (<div>
				<Typography variant="heading3" semanticTag="h3">
					Youtube Videos
				</Typography>
			</div>),
			content: (
				<div className={"bike-details-tab-panel"}>
					{
						videoEmbed ?
							<div className={"row"}>
								<div className={"col-12 col-lg-6 mt-4 mt-lg-0"}>
									<div className="ratio ratio-16x9">
										{parse(videoEmbed)}
									</div>
								</div>
							</div> :
							null
					}
				</div>
			)
		}

	].filter(item => isAccessory ? item.title !== "Beschreibung" : true)
}

export default getAccordionContent
