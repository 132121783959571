import React, {useContext} from "react"
import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {getProductUrl} from "../../../url/Url"
import Link from "next/link"
import slugify from "slugify"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"

type Props = {
  setAskAQuestion: (boolean) => void,
  executeScroll: () => void,
  slug: string,
  setActive: (string) => void
  questionsLength: number
  bikeName?: string
}

export const QAndA: React.FC<Props> = ({
	setAskAQuestion,
	executeScroll,
	slug,
	setActive,
	questionsLength,
	bikeName
}) => {
	const {t} = useTranslation(["common", "URL"])
	const urlBase = getProductUrl(t, slug)
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<>

			<div className="d-md-block mb-4 m-md-0">
				<div className="row pt-4">
					<div className={"col-12 mt-1"}>
						<div className={"border-top mb-4"}/>
					</div>
					<Typography variant={"bodyLgBold"} semanticTag={"span"}
						className=" mb-3 ms-0 me-0">Fragen und Antworten</Typography>

					<div className="d-flex col-auto mb-4">
						<div className="col-auto me-2">
							<Image src={"/assets/icons/question-in-box.svg"} layout={"intrinsic"} objectFit={"contain"} width={24}
								height={24}/>
						</div>
						<div>
							{questionsLength > 0 && <Typography variant={"bodyLg"} semanticTag={"span"} className="ms-0 me-0">
								{questionsLength}{" "}
							</Typography>}
							<Typography variant={"bodyLg"} semanticTag={"span"} className="ms-0 me-0">
								{t(`${questionsLength > 0 ? "Fragen zu diesem Produkt wurden bereits beantwortet." : "Stelle deine Frage zu diesem Produkt"}`)}
							</Typography>
							{" "}
							<br></br>
							<Link href={{pathname: urlBase, query: {tab: slugify(t("customers_questions").toLowerCase())}}}
								scroll={false} shallow={true}>
								<a
									className="text-decoration-none text-dark col-auto border-bottom border-2 border-primary pointer px-0"
									onClick={() => {
										reactGA.event({
											category: gaCategories.pdp,
											action: gaEvents.watchLiveQuestions,
											label: gaEvents.watchLiveQuestions,
											nonInteraction: true
										})
										executeScroll()
										setActive(slugify(t("customers_questions").toLowerCase()))
									}}>
									<Typography className="bg-primary-pale d-none d-md-block col-7 p-1 rounded-3"
										variant="bodyLgBold" semanticTag="span">
										{t(`${questionsLength > 0 ? "Live-Fragen ansehen" : "Zu den Live-Fragen"}`)}
									</Typography>
									<Typography className="bg-primary-pale  d-md-none  p-1 rounded-3"
										variant="bodySmBold" semanticTag="span">
										{t(`${questionsLength > 0 ? "Live-Fragen ansehen" : "Zu den Live-Fragen"}`)}
									</Typography>
								</a>
							</Link>
						</div>
					</div>

					<div className="d-flex col-auto">
						<div className="d-flex col-auto me-2">
							<Image src={"/assets/icons/question-raised-hand.svg"} layout={"intrinsic"} objectFit={"contain"}
								width={24} height={24}/>
						</div>
						<div>
							<Typography variant={"bodyLg"} semanticTag={"span"} className="col-auto ms-0 me-0">
								{t("Benötigst Du persönliche Unterstützung?")}
							</Typography>
							<br></br>
							<Typography className="col-auto bg-primary-pale d-none d-md-block  pointer p-1 rounded-3"
								variant="bodyLgBold" semanticTag="span"
								onClick={() => {
									setAskAQuestion(true)
								}}>
								{t("Stelle Deine Frage an unsere Berater*innen.")}
							</Typography>
							<Typography className="col-auto d-block d-md-none bg-primary-pale  pointer p-1 rounded-3"
								variant="bodySmBold" semanticTag="span"
								onClick={() => {
									setAskAQuestion(true)
								}}>
								{t("Stelle Deine Frage an unsere Berater*innen.")}
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
