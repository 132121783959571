import React, {useEffect} from "react"
import TabItem from "./TabItem"
import Tabs from "./Tabs"
import {useTranslation} from "next-i18next"
import slugify from "slugify"

type Props = {
    current: string
    urlBase: string
    tabsContent: any[]
	active: string
	setActive: (string)=> void
}

const TabsWrapper = ({current, urlBase, tabsContent, active, setActive}: Props): React.ReactElement => {
	const {t} = useTranslation()
	// Const [active, setActive] = useState<string>(current)
	// const handleActiveChange = tabName => setActive(tabName)
	const handleActiveChange = tabName => {
		setActive(slugify(t(tabName)).toLowerCase())
	}

	useEffect(() => {
		setActive(slugify(t(current)).toLowerCase())
	}, [])
	return (
		<>
			<Tabs className="nav nav-tabs nav-fill text-dark">
				{tabsContent.map(({title, slug}) =>
					<TabItem
						setActive={handleActiveChange}
						key={title}
						isActive={active === slugify(slug || t(title)).toLowerCase()}
						label={t(title)}
						slug={slug}
						urlBase={urlBase}
					/>
				)}

			</Tabs>
			{tabsContent.filter(({title, slug}) => (slugify(slug || t(title)).toLowerCase() === active))[0]?.content}
		</>
	)
}

export default TabsWrapper
