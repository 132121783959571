import React from "react"
import {useTranslation} from "next-i18next"
import {X} from "react-bootstrap-icons"
import PropTypes from "prop-types"
import SuggestionForm from "../forms/SuggestionForm"
import Typography from "../reusable/Typography/Typography"

type Props = {
    setBikeSuggest?: (onOff: boolean) => void
}

const SuggestBikeModal: React.FC<Props> = ({setBikeSuggest}) => {
	const {t} = useTranslation()
	return (
		<div className={"container p-0 vh-100"}>
			<div className={"row justify-content-center align-items-center vh-100 overflow-auto"}>
				<div className={"col-12 col-md-8 col-lg-6"}>
					<div className={"bg-white rounded"} onClick={e => {
						e.stopPropagation()
					}}>
						<div className={"row"}>
							<div className={"col-12"}>
								<div className={"row justify-content-end"}>
									<div className={"col-auto"}>
										<div className={"p-2"}>
											<button type={"button"} className={"btn"} onClick={() => {
												setBikeSuggest(false)
											}}>
												<X size={36} color={"#cccccc"}/>
											</button>
										</div>
									</div>
								</div>
								<div className={"ps-5 pe-5 pb-5"}>
									<Typography className="text-center" variant="heading2" semanticTag="h2">
										{t("E-Bike vorschlagen")}
									</Typography>
									<div className={"row mt-3 mb-4 justify-content-center"}>
										<div className={"col-12"}>
											<div className={"border-bottom border-primary"}/>
										</div>
									</div>
									<div className="row justify-content-center">
										<SuggestionForm/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

SuggestBikeModal.propTypes = {
	setBikeSuggest: PropTypes.func.isRequired
}

export default SuggestBikeModal
