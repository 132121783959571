import React, {useContext} from "react"
import RouteTransitionContext from "../../context/RouteTransitionContext"

const ProgressBar = () => {
	const {transitioning} = useContext(RouteTransitionContext)

	return (
		<div className={"position-fixed vw-100"}>
			<div className={"bg-primary"} style={{
				width: transitioning ? "50%" : "100%",
				opacity: transitioning ? "100%" : "0%",
				transition: "all 0.2s",
				height: 4,
				zIndex: 9999
			}}/>
		</div>
	)
}

export default ProgressBar
