/* eslint-disable complexity */
import React, {useContext, useEffect} from "react"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import CheckoutContext from "../../context/CheckoutContext"
import {customPlanLengthMap} from "./plan/customPlanLengthMap"

type Props = {
    initialPrice: number;
    price: number;
    small?: boolean;
    isSecondHand?: boolean;
    secondHandPrice?: number;
    regularDiscountBase?: number;
    bikeId: number
    isOnSale?: boolean
}

const BikePrice: React.FC<Props> = ({
	price,
	regularDiscountBase,
	small = false,
	isSecondHand = false,
	secondHandPrice,
	bikeId,
	isOnSale,
	initialPrice
}) => {
	const {t} = useTranslation()
	const {data, setData} = useContext(CheckoutContext)
	const checkoutData = data
	const resultingPrice = (isSecondHand ? secondHandPrice : price) - (checkoutData?.downPayment ? Number(checkoutData?.downPayment[bikeId] || 0) : 0)

	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = data?.planLength ? planLengthMap ? planLengthMap[data?.planLength] : data?.planLength : 48

	useEffect(() => {
		if (isSecondHand && planLength > 12) {
			setData(prevData => {
				return ({
					...prevData,
					planLength: 12
				})
			})
		}
	}, [])

	return (
		<div className={"col-auto"}>
			{((!isSecondHand && !regularDiscountBase && !isOnSale) || (data?.planLength === 48 && !isSecondHand)) && (<>
				<Typography variant={"bodyLgBold"} semanticTag="span">
                    CHF
				</Typography>
				<Typography variant={small ? "bodyLgBold" : "heading1"} className="ms-1" semanticTag="span"
					style={{fontSize: 36}}>
					{`${Math.ceil(resultingPrice / (planLength))}`}
				</Typography>
				{checkoutData?.planLength > 1 && <Typography variant={"bodyLgBold"} semanticTag="span" className="ms-1">
					{`${t("pro Monat")}`}
				</Typography>}
			</>)}

			{isSecondHand ? <>
				<Typography variant={"bodyLgBold"} semanticTag="span">
                    CHF
				</Typography>
				<Typography className={"text-decoration-line-through text-secondary"}
					variant={small ? "bodyLgBold" : "heading2"} semanticTag="span">
					{` ${Math.ceil(price / (planLength))}`}
				</Typography>
				{checkoutData?.planLength > 1 && <Typography variant={small ? "bodySm" : "bodyLg"} semanticTag="span" className={"text-secondary text-decoration-line-through"}>
					{`${t("pro Monat")}`}
				</Typography>}
				<br/>
				<Typography variant={"bodyLgBold"} semanticTag="span">
                    CHF
				</Typography>
				<Typography style={{color: "#FC6471"}} variant={small ? "bodyLgBold" : "heading1"} semanticTag="span">
					{`${Math.ceil(resultingPrice / (planLength))}`}
				</Typography>
				{checkoutData?.planLength > 1 && <Typography variant={small ? "bodySm" : "bodyLg"} semanticTag="span">
					{`${t("pro Monat")}`}
				</Typography>}
			</> : isOnSale && data?.planLength !== 48 && <>
				<Typography variant={"bodyLgBold"} semanticTag="span">
					CHF
				</Typography>
				<Typography className={"text-decoration-line-through text-secondary"}
					variant={small ? "bodyLgBold" : "heading2"} semanticTag="span">
					{` ${Math.ceil(initialPrice / (planLength))}`}
				</Typography>
				{checkoutData?.planLength > 1 && <Typography variant={small ? "bodySm" : "bodyLg"} semanticTag="span" className={"text-secondary text-decoration-line-through"}>
					{`${t("pro Monat")}`}
				</Typography>}
				<br/>
				<Typography variant={"bodyLgBold"} semanticTag="span">
					CHF
				</Typography>
				<Typography style={{color: "#FC6471"}} variant={small ? "bodyLgBold" : "heading1"} semanticTag="span">
					{`${Math.ceil(resultingPrice / (planLength))}`}
				</Typography>
				{checkoutData?.planLength > 1 && <Typography variant={small ? "bodySm" : "bodyLg"} semanticTag="span">
					{`${t("pro Monat")}`}
				</Typography>}
			</>}
		</div>
	)
}

BikePrice.propTypes = {
	price: PropTypes.number
}
export default BikePrice
