import React from "react"
import Typography, {Variants} from "../../components/reusable/Typography/Typography"
import RichTextParagraph from "./RichTextParagraph"
import PropTypes from "prop-types"
import RichTextUL from "./RichTextUL"

type Props = {
  content: any,
  style?: any,
  textAlign?: string,
  isMobile?: boolean
  paragraphVariant?: keyof typeof Variants
  className?: string,
}

const RichText = ({
	content,
	style,
	isMobile,
	textAlign = "center",
	paragraphVariant = "bodyLg",
	className = "mb-4"
}: Props) => {
	return (
		<>
			{content?.map(({nodeType, content}, index) => {
				switch (nodeType) {
					case "heading-1":
						return content.map((item, idx) => {
							const {value} = item
							const lines = (value || "").split("\n")
							const htmlValue = lines.join("<br/>")
							return (
								<Typography key={idx} semanticTag="h1" variant="heading1" htmlContent={htmlValue}/>
							)
						})
					case "heading-2":
						return content.map((item, idx) => {
							return (
								<Typography key={idx} semanticTag="h2" variant="heading2">
									{item.value}
								</Typography>
							)
						})
					case "heading-3":
						return content.map((item, idx) => {
							return (
								<Typography key={idx} semanticTag="h3" variant="heading3">
									{item.value}
								</Typography>
							)
						})
					case "heading-4":
						return content.map((item, idx) => {
							return (
								<Typography key={idx} semanticTag="h4" variant="heading3">
									{item.value}
								</Typography>
							)
						})
					case "heading-5":
						return content.map((item, idx) => {
							return (
								<Typography key={idx} semanticTag="h5" variant="heading3">
									{item.value}
								</Typography>
							)
						})
					case "heading-6":
						return content.map((item, idx) => {
							return (
								<Typography key={idx} semanticTag="h6" variant="heading6">
									{item.value}
								</Typography>
							)
						})
					case "paragraph":
						return <RichTextParagraph style={style} key={index} isMobile={isMobile} textAlign={textAlign}
							className={className}
							content={content}
							variant={paragraphVariant}/>

					case "unordered-list":
						return <RichTextUL style={style} key={index} isMobile={isMobile} textAlign={textAlign}
							className={className}
							content={content}
							variant={paragraphVariant}/>

					default:
						return null
				}
			})}
		</>
	)
}

RichText.propTypes = {
	content: PropTypes.any.isRequired
}

export default RichText
