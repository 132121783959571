import React from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import classes from "./NavLink.module.scss"
import {filterUrl} from "../../../filter/FilterUrl"

type Props = {
    label: string
    params?: any
	href?: string
    className?: string
    align?: string
    borderBottom?: boolean
    color?: "white" | "dark"
    uppercase?: boolean
    action?: () => void
	external?: string
	translate?: (str:string) => string
	btn?: boolean
	onClick?: () => void
}

const NavLink = ({label, params, className, onClick, borderBottom, color = "dark", uppercase, href, translate, external, btn, action}: Props): React.ReactElement => {
	const t = translate ? translate : useTranslation().t
	const url = params ? filterUrl(params, null, null, t) : external ? external : href ? href : null
	const classesString = `text-${color} pointer  ${uppercase ? "text-uppercase" : ""} text-decoration-none ${borderBottom ? classes.linkBorderBottom : classes.link}`

	return (
		<li onMouseEnter={action} onClick={onClick}
			className={`${classes.listItem} ${className ? className : ""} `}>
			{btn ?
				<span className={classesString}>{t(label)}</span> :
				external ?
					<a href={url} className={classesString}>{t(label)}</a> :
					<Link href={url}
						as={t(url)}>
						<a className={classesString}>{t(label)}</a>
					</Link>

			}
		</li>
	)
}

NavLink.propTypes = {
	label: PropTypes.string.isRequired,
	href: PropTypes.string,
	classGroup: PropTypes.string,
	align: PropTypes.string,
	external: PropTypes.string,
	translate: PropTypes.func

}

export default NavLink
