import {createContext} from "react"

export interface SliderContext {
	goToSlide: (_: number) => void,
	nextSlide: () => void,
	prevSlide: () => void,
	activeSlideIndex: number
}

export const sliderContext = createContext<SliderContext | null>(null)
