import React from "react"
import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"
import {CheckoutData} from "../../../context/CheckoutContext"
import {customPlanLengthMap} from "../plan/customPlanLengthMap"

interface Props {
    checkoutData: any | CheckoutData
    resultingPrice: number
    setIsOpen: (arg0: boolean) => void
    isOpen: boolean
    t: (arg0: string) => string
    bikePrice: number
	initialPrice: number
}

const OnSaleSlideHeader: React.FC<Props> = ({
	checkoutData,
	resultingPrice,
	setIsOpen,
	isOpen,
	bikePrice,
	t,
	initialPrice
}) => {
	const redColor = "#E61C45"
	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = checkoutData?.planLength ? planLengthMap ? planLengthMap[checkoutData?.planLength] : checkoutData?.planLength : 48

	return (
		<>
			<div className="row justify-content-between">
				{planLength > 1 && <>
					<div className="col-6">

						<Typography className="" variant="bodyLgBold"
							semanticTag="span">{t("Monatlicher Preis")}</Typography>

					</div>
					<div className={"col-auto "}>
						<Typography semanticTag="span" variant="bodyLgBold"
							className={`me-3 ${initialPrice === bikePrice ? "text-black" : ""}`}>
							CHF {Math.ceil(resultingPrice / planLength)}
						</Typography>
					</div>
				</>}

				<div className="col-auto">

					<Typography className="" variant="bodyLgBold"
						semanticTag="span">{t("Gesamtpreis")}</Typography>

				</div>
				<div className="col-auto">
					<Typography semanticTag="span" variant="bodyLgBold"
						className={`me-1 ${bikePrice === initialPrice ? "text-black" : ""}`} style={
							{
								color: redColor
							}
						}>CHF {Math.ceil(resultingPrice)}</Typography>
					{bikePrice !== initialPrice && <>
						<Typography semanticTag="span" variant="bodyXSm"
							className={"text-secondary text-decoration-line-through"}>
							CHF {Math.ceil(initialPrice)}
						</Typography>
						<Typography semanticTag="span" variant="bodyXSm" className={"text-secondary ms-1 me-3"}>
							{"(UVP)"}
						</Typography>
					</>}
				</div>

				{planLength > 1 && <>
					<div className="col-6">

						<Typography className="" variant="bodyLgBold"
							semanticTag="span">{t("interest")}</Typography>

					</div>
					<div className="col-auto">
						<Typography semanticTag="span" variant="bodyLgBold"
							className={"me-3 text-black"}> 0% </Typography>
					</div>

				</>}

			</div>
			<hr className={"my-3"}/>
			<div className="d-flex justify-content-between">
				<div className="d-flex mb-1">
					{/* new variant */}
					<Typography className="" variant="bodyLgBold"
						semanticTag="span">{t("Laufzeit")}</Typography>
					<Typography className="ms-1" variant="bodyLg"
						semanticTag="span" style={{color: "#6C7074"}}>({t("Monate")})</Typography>

				</div>
				{/* new variant */}
				<div className="d-flex align-items-center  mb-1 mb-md-3">
					{planLength !== 1 && <Typography className="me-3" semanticTag="span" variant="bodyLg">CHF {Math.ceil(resultingPrice / planLength)}/Mt</Typography>}
					<Image onClick={() => setIsOpen(!isOpen)}
						src={`${isOpen ? "/assets/icons/info-active.svg" : "/assets/icons/info-transparent.svg"}`}
						height={20}
						width={20} className="pointer"
					/>
				</div>
			</div>

		</>
	)
}

export default OnSaleSlideHeader
