/* eslint-disable no-negated-condition */
import React, {useContext, useEffect, useState} from "react"
import {findBikeAttributeByName} from "../../../utility/Helper"
import {StockStatus} from "../BikeDetails"
import {useTranslation} from "next-i18next"
import PropTypes from "prop-types"
import BoxBikeInfo from "../BoxBikeInfo"
import Typography from "../../reusable/Typography/Typography"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"
import {ModalContext} from "../../../context/ModalContext"
import Button from "../../reusable/components/Button"
import SizeNotificationModalContent from "../../reusable/ModalsContent/SizeNotificationModalContent"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {generateUUID} from "../../../utility/UUID"

type Props = {
  variations: any,
  scriptsRef: any,
  bike: any,
  setSelectedVariation: (variation: any) => void,
  setSelectedSize: (size: any) => void
  selectedSize?: any,
  selectedVariation?: any
  shake: boolean
}

export const EnvelopeIcon = () => {
	return (
		<svg width="20" height="16" viewBox="0 0 20 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.25 0C1.46403 0 0 1.46403 0 3.25V12.75C0 14.536 1.46403 16 3.25 16H16.75C18.536 16 20 14.536 20 12.75V3.25C20 1.46403 18.536 0 16.75 0H3.25ZM3.25 1.5H16.75C17.725 1.5 18.5 2.27497 18.5 3.25V3.80273L10 8.39746L1.5 3.80273V3.25C1.5 2.27497 2.27497 1.5 3.25 1.5ZM1.5 5.50781L9.64355 9.91016C9.75305 9.9693 9.87555 10.0003 10 10.0003C10.1245 10.0003 10.2469 9.9693 10.3564 9.91016L18.5 5.50781V12.75C18.5 13.725 17.725 14.5 16.75 14.5H3.25C2.27497 14.5 1.5 13.725 1.5 12.75V5.50781Z"
				fill="currentColor"/>
		</svg>
	)
}

const Sizes: React.FC<Props> = ({
	variations,
	scriptsRef,
	bike,
	shake,
	selectedSize,
	selectedVariation,
	setSelectedVariation,
	setSelectedSize
}) => {
	const {t} = useTranslation()
	const inStockSizes = variations?.filter(variation => variation.stock_status === StockStatus.inStock || variation.stock_status === StockStatus.onBackOrder)
	const isOneSize = Boolean(inStockSizes.length === 1)
	const {setModalContent, closeModal, openModal} = useContext(ModalContext)
	const [activeId, setActiveId] = useState()
	const [showSizeDescription, setShowSizeDescription] = useState(false)
	const [isUnavailableSizeChosen, setUnavailableSizeChosen] = useState(false)
	const {reactGA} = useContext(AnalyticsContext)
	const [isOutStockSelected, setIsOutStockSelected] = useState(false)

	useEffect(() => {
		if (inStockSizes.length === 1) {
			const [lastSizeBike] = inStockSizes
			const sizeAttribute = findBikeAttributeByName(lastSizeBike, "Grösse", false, true)
			const [size] = sizeAttribute.split(": ")
			handleSizeBtnClick(size, lastSizeBike)
		}
	}, [inStockSizes.length])

	useEffect(() => {
		setUnavailableSizeChosen(false)
	}, [bike?.name])

	const handleSizeBtnClick = (size, variation) => {
		if (variation.id === activeId) {
			setShowSizeDescription(prevState => !prevState)
		} else {
			setShowSizeDescription(true)
		}

		if (variation.stock_status === StockStatus.outOfStock) {
			setActiveId(variation.id)
			setIsOutStockSelected(true)
			setSelectedVariation(null)
			setSelectedSize(null)
			return
		}

		setIsOutStockSelected(false)
		setActiveId(variation.id)
		setUnavailableSizeChosen(false)
		setSelectedVariation(variation)
		setSelectedSize(size)
	}

	return (
		<div className={`row g-2 mt-2 mb-md-4 ${shake ? "shake" : ""}`}>
			<div className={"col-12"}>
				<AccordionAnimation duration={500} inProp={isUnavailableSizeChosen} transTimingFn="ease-out">
					<div className="col-12 col-lg-10 mb-3 border rounded pt-4 ps-4 pe-4 pb-4 pb-lg-3">
						<Typography variant="bodyLg" semanticTag="div" className={"col-auto"}>
							{t("unavailable_size_box_text")}
						</Typography>
						<Button label={t("newsletter_button_text")}
							textColor="#212529" color="#fff" hoverColor="#212529"
							outline
							fontSizeSmall
							className="w-100 mt-4"
							icon={<EnvelopeIcon/>}
							onClick={() => {
								setModalContent(<SizeNotificationModalContent closeModal={closeModal}/>)
								openModal()
								reactGA?.event({
									category: gaCategories.pdp,
									action: gaEvents.remindMeSizeUnavailable,
									label: gaEvents.remindMeSizeUnavailable,
									nonInteraction: true
								})
							}}
						/>
					</div>
				</AccordionAnimation>
				<div className={"row mb-1 justify-content-between text-underline"}>
				</div>
			</div>

			<div className="d-flex mb-2 align-items-center justify-content-between col-12">
				<Typography variant={"bodySm"}>{t("Size")}</Typography>
			</div>

			{variations.map(variation => {
				const sizeAttribute = findBikeAttributeByName(variation, "Grösse", false, true)
				const inStock = variation.stock_status === StockStatus.inStock
				const outOfStock = variation.stock_status === StockStatus.outOfStock
				const [size, sizeLabel] = sizeAttribute.split(": ")
				return (
					<React.Fragment key={generateUUID()}>
						<div
							className={`col-12 position-relative mb-1 ${activeId === variation.id && showSizeDescription && "bike-size-btn"}`}>
							<button
								style={{
									backgroundColor: activeId === variation.id && showSizeDescription ? "#212529" : "#F8F8F8",
									color: activeId === variation.id && showSizeDescription ? "#fff" : "#212529",
									borderRadius: 16,
									padding: 10
								}}
								onClick={() => {
									handleSizeBtnClick(size, variation)
								}} type={"button"}
								className={"px-3 d-flex justify-content-between align-items-center bike-details-size btn btn-block col-12 text-start overflow-hidden border-0 position-relative"}>
								<div>
									<Typography variant="bodySmBold"
										semanticTag="span">{size}</Typography>
									<Typography className="ms-2" variant="bodySm"
										semanticTag="span">{sizeLabel ? `${t(sizeLabel)} ` : ""}</Typography>
								</div>

								{inStock || outOfStock ? <div className="d-flex align-items-center">
									<Typography variant="bodySm" className={inStock ? "text-success text-bold bike-size-instock" : "bike-size-instock"}
									>{t(`${inStock ? "instock" : outOfStock ? "out-of-stock" : ""}`)}</Typography>
								</div> : <img src="/assets/icons/question-mark.svg" alt="question mark" style={{marginLeft: 10}}/>}

							</button>
						</div>
						{(activeId === variation.id && showSizeDescription) ?
							<AccordionAnimation customHeight={400} duration={1000} inProp={isOneSize || Boolean(selectedSize) || isOutStockSelected}
								transTimingFn="ease-out">
								<BoxBikeInfo
									isOutOfStock={outOfStock}
									selectedVariation={!outOfStock ? selectedVariation : variation}/>
							</AccordionAnimation> : null}
					</React.Fragment>
				)
			})}

		</div>
	)
}

Sizes.propTypes = {
	variations: PropTypes.any.isRequired,
	scriptsRef: PropTypes.any.isRequired,
	bike: PropTypes.any.isRequired,
	selectedSize: PropTypes.string,
	setSelectedVariation: PropTypes.func.isRequired,
	setSelectedSize: PropTypes.any.isRequired,
	selectedVariation: PropTypes.any
}

export default Sizes
