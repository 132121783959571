import React from "react"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"
import {useTranslation} from "next-i18next"

interface Props {
    question: any
}

const CustomerQuestionItem:React.FC<Props> = ({question}) => {
	const {t} = useTranslation("common")
	const {firstName, questionText, lastAdminAnswer} = question
	const {answerText} = lastAdminAnswer[0]
	const userName = `${firstName}`

	return (
		<div className="col-12 mb-4">
			<Typography className={"mb-0 text-secondary"} semanticTag="p" variant={"bodySmBold"}>{userName}</Typography>
			<Typography semanticTag="span" variant="bodySmBoldMdBodyLgBold">{questionText}</Typography>
			<div className="position-relative bg-grey rounded p-3 row align-items-center g-0 mt-3">
				<div className="col-12">
					<div className="d-flex align-items-center">
						<div className="d-flex d-md-none">
							<Image src={"/assets/icons/yellow-mbp-circle.svg"} width={30} height={30} />
						</div>
						<div className="d-none d-md-flex">
							<Image src={"/assets/icons/yellow-mbp-circle.svg"} width={38} height={38} />
						</div>
						<Typography semanticTag="span" className={"ms-2"} variant="bodySmBoldMdBodyLgBold">{t("experts_answer")}</Typography>
					</div>
				</div>
				<div className="col-12 mt-3">
					<Typography semanticTag="span" variant="bodySmMdBodyLg">{answerText}</Typography>
				</div>

				<div
					style={{
						marginTop: "-15px"
					}}
					className="position-absolute top-0">

					<Image

						src={"/assets/icons/vector-triangule.svg"}
						width={24}
						height={24}
					/>
				</div>
			</div>
		</div>
	)
}

export default CustomerQuestionItem
