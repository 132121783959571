import React from "react"
import Image from "next/image"

type props ={
    label:string
    amount: number
    className?:string
    theme?:string
}
function NumberOfPurchases({label, amount, className, theme}:props) {
	const StringToSHow = label.replace("x", `<b>${amount === 100 ? "100+" : amount}</b>`)
	if (amount && amount > 0) {
		return (
			<div className={`alert d-flex flex-column flex-sm-row align-items-center justify-content-center gap-2 ${theme ? "bg-" + theme : "alert-primary"} ${className}`} role="alert">
				<Image src="/assets/icons/fire.svg" width={20} height={20}/><div dangerouslySetInnerHTML={{__html: StringToSHow}} />{ }
			</div>
		)
	}

	return <></>
}

export default NumberOfPurchases
