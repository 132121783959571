import React from "react"
import slugify from "slugify"
import {getProductUrl} from "../../url/Url"
import Accordion from "../Accordion/Accordion"
import TabsWrapper from "../tabs/TabsWrapper"

interface Props {
  accordionContent: any,
  tab: string,
  slug: string
  t: (arg: string) => any,
  variant: string
  active: string
  setActive: (string) => void
	multiple?: boolean
	grey?: boolean
	noDividerBeforeBody?: boolean
	bike?: any
	isForPDP?: boolean
}

const BikeDescriptionSection: React.FC<Props> = ({bike, isForPDP, variant, accordionContent, slug, tab, t, active, setActive, multiple, grey}) => {
	const defaultActiveTitles = ["Beschreibung", "Übersicht", "Einzelheiten"]
	return (
		<div className="mb-3 mb-md-5">
			<div className={"d-md-none col-12 pb-4"}>
				<Accordion activeHighlighted accordionItemsContent={accordionContent}
					current={tab || slugify(t("ubersicht"))}
					defaultActiveTitles={defaultActiveTitles}
					urlBase={getProductUrl(t, slug)}/>
			</div>

			{variant === "B" ? <div className={"d-none d-md-block col-12"}>
				<Accordion noDividerBeforeBody multiple={multiple} activeHighlighted accordionItemsContent={accordionContent}
					current={tab || slugify(t("ubersicht"))} grey={grey}
					defaultActiveTitles={defaultActiveTitles}
					urlBase={getProductUrl(t, slug)} bike={bike} isForPDP/>
			</div> :
				<div className={"d-none d-md-block col-12"}>
					<TabsWrapper tabsContent={accordionContent} current={tab as string || slugify(t("ubersicht"))}
						urlBase={getProductUrl(t, slug)}
						active={active}
						setActive={setActive}
					/>
				</div>}
		</div>
	)
}

export default BikeDescriptionSection
