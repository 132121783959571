import React, {useState} from "react"
import PropTypes from "prop-types"

type Props = {
	children?: React.ReactNode,
	onClick?: ()=>void,
	onDoubleClick?: ()=>void,
	className?: string,
	style?: any
}

const MyComponent = ({children, onClick, onDoubleClick, className, style}) => {
	const [lastTap, setLastTap] = useState<number | null>(null)

	const handleClick = e => {
		e.stopPropagation()
		const now = Date.now()
		if (now - 300 > lastTap) {
			if (onClick) {
				onClick()
			}
		} else if (onDoubleClick) {
			onDoubleClick()
		}

		setLastTap(now)
	}

	return (
		<div onClick={handleClick} className={className} style={style}>
			{children}
		</div>
	)
}

MyComponent.propTypes = {
	children: PropTypes.node,
	onClick: PropTypes.func,
	onDoubleClick: PropTypes.func,
	className: PropTypes.string,
	style: PropTypes.any
}

export default MyComponent
