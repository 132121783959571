import * as Yup from "yup"

interface FormData {
    firstname: string|number
	lastname: string|number
	email: string|number
}

export const validationSchema = Yup.object().shape({
	firstname: Yup.string().required("Bitte geben Sie Ihre Voorname ein"),
	lastname: Yup.string().required("Bitte geben Sie Ihre Achternaam ein"),
	email: Yup.string().email("Bitte geben Sie eine gültige E-Mail-Adresse ein").required("Bitte geben Sie Ihre E-Mail-Adresse ein")
})

export const initialValues:FormData = {
	firstname: "",
	lastname: "",
	email: ""
}

export const fields = [
	{
		display: true,
		name: "firstname",
		label: "Vorname",
		type: "text",
		placeholder: "First Name",
		className: "col-6"
	},
	{
		display: true,
		name: "lastname",
		label: "Nachtname",
		type: "text",
		placeholder: "First Name",
		className: "col-6"
	},
	{
		display: true,
		name: "email",
		label: "E-Mail-Adresse",
		type: "text",
		placeholder: "E-Mail-Adresse",
		className: "col-12 col-md-14"
	}

]

