import {useTranslation} from "next-i18next"
import React, {useContext} from "react"
import LayoutContext from "../../../context/LayoutContext"
import Typography from "../../reusable/Typography/Typography"
import {instanceDiscounts} from "../../../config/instance"
import Image from "next/image"

type Props = {
	soldOut?: boolean
	onSale?: boolean
	occasion?: boolean
	kilometers?: boolean
	isNew?: boolean
	is2022?: boolean
	is2023?: boolean
	isBikeOfTheMonth?: boolean
	inStock?: boolean
	isNonEBike?: boolean
	discountKey?: string
	saleOfTheWeek?: boolean
	fontSizeXSm?: boolean,
	itemsLeft?: number
	isFeatured: boolean
}

const CollectionItemBadge = ({
	soldOut,
	onSale,
	occasion,
	kilometers,
	isNew,
	isFeatured,
	is2022,
	is2023,
	isBikeOfTheMonth,
	inStock, isNonEBike,
	discountKey,
	saleOfTheWeek,
	itemsLeft,
	fontSizeXSm
}: Props) => {
	const {t} = useTranslation("common")
	const {isListView} = useContext(LayoutContext)
	const discountRef = instanceDiscounts[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE][discountKey]
	const saleBadge = discountRef ? discountRef["pay-one-time"].label : "-15%"

	return (
		<div className={"flex-row flex-md-column gx-2"}>
			{/* Place Fast delivery */}
			{isFeatured ? <div style={{
				borderRadius: "12px",
				padding: "2px 8px",
				color: "#212529",
				backgroundColor: "#FEC04D"
			}} className="d-flex align-items-center justify-content-center">
				<Image src="/assets/icons/featured.svg" width={12} height={12} />
				<Typography style={{fontSize: "12px", color: "#212529"}} variant="bodyXSmBold" semanticTag="span"
					className={"badge py-1"}
				>{t("we-recommend")}</Typography> </div> : saleOfTheWeek ? <>
				<Typography variant="bodyXSmBold" semanticTag="span"
					className={"badge text-white py-1"}
					style={{
						borderRadius: "12px",
						fontSize: "11px",
						color: "#212529",
						backgroundColor: "#E61C45"
					}}>{t("week-sale")}</Typography>
			</> : <>
				{inStock ?
					<Typography variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"} semanticTag="span" className="badge my-1 me-2 border-radius-8" style={{backgroundColor: "#1FE184", color: "#212529", borderRadius: "12px", fontSize: "11px", padding: "2px 8px"}}>{t("instock")}
					</Typography> : null
				}
				{isNonEBike ?
					<Typography variant="bodyXSmBoldMdBodySmBold" semanticTag="span" className="badge bg-primary my-1 me-2" style={{color: "#212529", borderRadius: "12px", fontSize: "11px", padding: "2px 8px"}}>{t("non-electric")}
					</Typography> : null
				}
				{soldOut ?
					<Typography variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"} semanticTag="span" style={{borderRadius: "12px", fontSize: "11px", padding: "2px 8px"}} className="badge bg-secondary">{t("Ausverkauft")}
					</Typography> : null
				}
				{onSale ?
					<Typography variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"} semanticTag="span"
						className={"badge text-lg text-white "}
						style={{
							borderRadius: "12px",
							color: "#212529",
							fontSize: "11px",
							padding: "2px 8px",
							backgroundColor: "#E61C45"
						}}>{t(saleBadge)}</Typography> : null
				}
				{occasion ?
					<Typography variant="bodySmBold" semanticTag="span"
						style={{borderRadius: "12px", fontSize: "11px", padding: "2px 8px"}}
						className="badge bg-dark mb-1">
						{/* {kilometers} */}
						{t("second-hand")}
					</Typography> :
					isNew ?
						<div className="col d-inline-block me-2 my-1 mx-2">
							<Typography variant="bodySmBold" semanticTag="span"
								className={"badge bg-primary"}
								style={{borderRadius: "12px", color: "#212529", padding: "2px 8px"}}>
								{t("New")}
							</Typography></div> :
						null}
				{is2022 ?
					<>
						<Typography variant="bodyXSmBoldMdBodySmBold" semanticTag="span"
							className="badge bg-primary text-dark text-uppercase mb-1" style={{borderRadius: "12px", color: "#212529", padding: "2px 8px"}}>{t("2022")}</Typography>
					</> : null
				}
				{isListView ? null : isBikeOfTheMonth ? <Typography variant="bodySmBold" semanticTag="span"
					style={{backgroundColor: "#000", padding: "2px 8px"}}
					className="badge text-white text-uppercase mb-1">
					{t("bike-of-the-month")}
				</Typography> : null}
				{is2023 ?
					<div className="col d-inline-block">
						<Typography variant="bodySmBold" semanticTag="span"
							style={{backgroundColor: "#020887", borderRadius: 8, padding: "2px 8px"}}
							className={"badge  bg-primary d-inline-block text-uppercase mb-0 mb-md-1"}>{t("2023-model")}</Typography>
					</div> : null
				}
			</>}

		</div>

	)
}

export default CollectionItemBadge
