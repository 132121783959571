export const topCategoriesMegaMenuMBP = [
	{title: "Mountain E-Bikes", text: "top-categories-mega-menu-text-1", link: "mountain-e-bike", translateLink: false},
	{title: "City E-Bikes", text: "top-categories-mega-menu-text-2", link: "city-e-bike", translateLink: false},
	{title: "all-terrain", text: "top-categories-mega-menu-text-3", link: "trekking-e-bike", translateLink: false},
	{title: "fastest-e-bikes", text: "top-categories-mega-menu-text-4", link: "45-km", translateLink: false},
	{title: "normal-bikes-menu-title", text: "top-categories-mega-menu-text-5", link: "normale-bikes", translateLink: true},
	{title: "cargo-bikes-menu-title", text: "top-categories-mega-menu-text-cargo", link: "cargo-e-bikes", translateLink: true}
]

export const topCategoriesMegaMenuMHP = [
	{
		title: "Sofas & Sessel",
		link: "sofas,sessel", translateLink: false,
		subcategories: [
			{
				name: "Sofas",
				link: "sofas",
				items: [
					{title: "L-Form", link: "l-form"},
					{title: "Ecksofas", link: "ecksofas"},
					{title: "Ledersofas", link: "ledersofas"},
					{title: "Bettsofas", link: "bettsofas"},
					{title: "Modularsofas", link: "modularsofas"}
				]
			},
			{
				name: "Sessel",
				link: "sessel",
				items: [
					{title: "Sessel", link: "sessel"},
					{title: "Sofa-Set", link: "sofa-set"}
				]
			}
		]
	},
	{
		title: "Table & Chairs",
		link: "tische,stuhle", translateLink: false,
		subcategories: [
			{
				name: "Tische",
				link: "tische",
				items: [
					{title: "Esstische", link: "esstische"},
					{title: "Ausziehtische", link: "ausziehtische"},
					{title: "Couchtische", link: "couchtische"},
					{title: "Nachttische", link: "nachttische"},
					{title: "Beistelltische", link: "beistelltische"},
					{title: "Schreibtische", link: "schreibtische"},
					{title: "Tisch-Set", link: "tisch-set"}
				]
			},
			{
				name: "Stuhle",
				link: "stuhle",
				items: [
					{title: "Esszimmerstühle", link: "dinner-chair"},
					{title: "Bürostühle", link: "burostuhle"},
					{title: "Barhocker", link: "barhocker"},
					{title: "Hocker", link: "hocker"},
					{title: "Sessel", link: "sessel"}
				]
			}
		]
	},
	{
		title: "Betten",
		link: "betten", translateLink: false,
		subcategories: [
			{
				name: "Betten",
				link: "betten",
				items: [
					{title: "Doppelbetten", link: "doppelbetten"},
					{title: "Einzelbetten", link: "einzelbetten"},
					{title: "Matratzen", link: "mattress"},
					{title: "Bett-Set", link: "bett-set"}
				]
			}
		]
	},
	{
		title: "Aufbewahrung",
		link: "aufbewahrung", translateLink: false,
		subcategories: [
			{
				name: "Aufbewahrung",
				link: "aufbewahrung",
				items: [
					{title: "Schränke", link: "schranke"},
					{title: "Kommoden", link: "kommoden"},
					{title: "Regale", link: "shelf-2"},
					{title: "TV-Möbel ", link: "tv-moebel"}
				]
			}
		]
	},
	{
		title: "Dekoration",
		link: "dekoration", translateLink: false,
		subcategories: [
			{
				name: "Dekoration",
				link: "dekoration",
				items: [
					{title: "Lampen", link: "lampen"},
					{title: "Teppiche", link: "teppiche"},
					{title: "Spiegel", link: "spiegel"}
				]
			}
		]
	},
	{
		title: "Outdoor",
		link: "balkon-and-garten", translateLink: false,
		subcategories: [
			{
				name: "Outdoor",
				link: "balkon-and-garten",
				items: [
					{title: "Gartensofas", link: "gartensofas"},
					{title: "Gartensessel", link: "gartensessel"},
					{title: "Gartentische", link: "gartentische"},
					{title: "Outdoor-Set", link: "outdoor-set"}
				]
			}
		]
	},
	{
		title: "Bundles",
		link: "bundle", translateLink: false,
		subcategories: [
			{
				name: "Bundles",
				link: "bundle",
				items: [
					{title: "Alle Bundles", link: "bundle"}
				]
			}
		]
	}
]

export const filterVerticalBarCategories = [
	{
		title: "Sofas",
		link: "sofas", translateLink: false,
		subcategories: [
			{
				name: "Sofas",
				link: "sofas",
				items: [
					{title: "L-Form", link: "l-form"},
					{title: "Ecksofas", link: "ecksofas"},
					{title: "Ledersofas", link: "ledersofas"},
					{title: "Bettsofas", link: "bettsofas"},
					{title: "Modularsofas", link: "modularsofas"}
				]
			}
		]
	},
	{
		title: "Sessel",
		link: "sessel", translateLink: false,
		subcategories: [
			{
				link: "sessel",
				items: [
					{title: "Sessel", link: "sessel"},
					{title: "Sofa-Set", link: "sofa-set"}
				]
			}
		]
	},
	{
		title: "Table",
		link: "tische", translateLink: false,
		subcategories: [
			{
				link: "tische",
				items: [
					{title: "Esstische", link: "esstische"},
					{title: "Ausziehtische", link: "ausziehtische"},
					{title: "Couchtische", link: "couchtische"},
					{title: "Nachttische", link: "nachttische"},
					{title: "Beistelltische", link: "beistelltische"},
					{title: "Schreibtische", link: "schreibtische"},
					{title: "Tisch-Set", link: "tisch-set"}
				]
			}
		]
	},
	{
		title: "Chairs",
		link: "stuhle", translateLink: false,
		subcategories: [
			{
				link: "stuhle",
				items: [
					{title: "Esszimmerstühle", link: "dinner-chair"},
					{title: "Bürostühle", link: "burostuhle"},
					{title: "Barhocker", link: "barhocker"},
					{title: "Hocker", link: "hocker"},
					{title: "Sessel", link: "sessel"}
				]
			}
		]
	},
	{
		title: "Betten",
		link: "betten", translateLink: false,
		subcategories: [
			{
				link: "betten",
				items: [
					{title: "Doppelbetten", link: "doppelbetten"},
					{title: "Einzelbetten", link: "einzelbetten"},
					{title: "Matratzen", link: "mattress"},
					{title: "Bett-Set", link: "bett-set"}
				]
			}
		]
	},
	{
		title: "Aufbewahrung",
		link: "aufbewahrung", translateLink: false,
		subcategories: [
			{
				link: "aufbewahrung",
				items: [
					{title: "Schränke", link: "schranke"},
					{title: "Kommoden", link: "kommoden"},
					{title: "Regale", link: "shelf-2"},
					{title: "TV-Möbel ", link: "tv-moebel"}
				]
			}
		]
	},
	{
		title: "Dekoration",
		link: "dekoration", translateLink: false,
		subcategories: [
			{
				link: "dekoration",
				items: [
					{title: "Lampen", link: "lampen"},
					{title: "Teppiche", link: "teppiche"},
					{title: "Spiegel", link: "spiegel"}
				]
			}
		]
	},
	{
		title: "Outdoor",
		link: "balkon-and-garten", translateLink: false,
		subcategories: [
			{
				link: "balkon-and-garten",
				items: [
					{title: "Gartensofas", link: "gartensofas"},
					{title: "Gartensessel", link: "gartensessel"},
					{title: "Gartentische", link: "gartentische"},
					{title: "Outdoor-Set", link: "outdoor-set"}
				]
			}
		]
	}
]

