import React, {Dispatch, SetStateAction, useContext} from "react"
import {useTranslation} from "next-i18next"
import DownPayment from "../plan/Downpayment"
import Plan from "../plan/Plan"
import CheckoutContext from "../../../context/CheckoutContext"
import DurationSlideHeader from "./DurationSlideHeader"
import OnSaleSlideHeader from "./OnSaleSlideHeader"
import {SaleTypes} from "../plan/OnSaleDurationLabels"
import PriceTooltipWithCheckoutBtn from "./PriceTooltipWithCheckoutBtn"

type Props = {
	isSecondHand: boolean
	bikeId: number
	bikePrice: string
	isMobile: boolean
	secondHandPrice?: number
	price: number
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>,
	setBikePrice: Dispatch<SetStateAction<number>>,
	isOnSale?: boolean
	saleType?: keyof typeof SaleTypes
	disabled?: boolean
	isLoading?: boolean
	handleClick?: any
	isAccessory?: boolean
	handleSizeClick?: any
	planLength?: any
	initialPrice?: any
	formatedPrice?: any
	customInstanceDiscount?: number
	isHorizontalDownpayment?: boolean
	PriceInputBesidesTitle?: boolean
}

const DurationAndDownpayment: React.FC<Props> = ({saleType, customInstanceDiscount, PriceInputBesidesTitle, formatedPrice, isHorizontalDownpayment, initialPrice, isAccessory, planLength, disabled, handleClick, handleSizeClick, isLoading, isOnSale, setBikePrice, isSecondHand, bikeId, bikePrice, isMobile, secondHandPrice, price, isOpen, setIsOpen}) => {
	const {t} = useTranslation()
	const {data} = useContext(CheckoutContext)
	const checkoutData = data
	const resultingPrice = (isSecondHand ? secondHandPrice : Number(bikePrice)) - (checkoutData?.downPayment ? Number(checkoutData?.downPayment[bikeId] || 0) : 0)

	return (
		<div className={"pt-mb-3 px-3 py-4 bg-light"} style={{borderRadius: "16px"}}>
			{isOnSale ?
				<OnSaleSlideHeader
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					checkoutData={checkoutData}
					resultingPrice={resultingPrice}
					bikePrice={Number(bikePrice)}
					initialPrice={Number(price)}
					t={t}
				/> :
				<DurationSlideHeader
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					checkoutData={checkoutData}
					resultingPrice={resultingPrice}
					t={t}
				/>
			}
			<Plan saleType={saleType} isOnSale={isOnSale} setBikePrice={setBikePrice} payOneTime isMobile={isMobile} isSecondHand={isSecondHand} initialPrice={price}/>

			<div className={`${checkoutData?.planLength === 1 && "d-none"}`}>
				<hr/>
				<DownPayment bikeId={bikeId} min={0} max={parseFloat(bikePrice)} PriceInputBesidesTitle={PriceInputBesidesTitle} isHorizontalDownpayment={isHorizontalDownpayment}/>
			</div>
			{process.env.NEXT_PUBLIC_ORGANIZATION && customInstanceDiscount ?
				<PriceTooltipWithCheckoutBtn bikeId={bikeId}
					bikePrice={bikePrice}
					isSecondHand={isSecondHand}
					secondHandPrice={secondHandPrice}
					disabled={disabled}
					isLoading={isLoading}
					handleClick={handleClick}
					isAccessory={isAccessory}
					handleSizeClick={handleSizeClick}
					planLength={planLength}
					initialPrice={initialPrice}
					formatedPrice={formatedPrice}
					checkoutData={checkoutData} customInstanceDiscount={customInstanceDiscount}
				/> :
				null}

		</div>
	)
}

export default DurationAndDownpayment
