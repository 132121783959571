export type GAEvent = {
    category: string
    action: string
    label: string
    value: number
    nonInteraction: boolean
    transport: string
}

export const gaEvents = {
	headers: "Header's",
	plp: "PLP",
	pdp: "PDP",
	sent: "Sent",
	checkoutFormSubmitted: "Checkout Form Submitted",
	checkoutSuccess: "Successful Checkout",
	checkoutFailed: "Failed Checkout",
	openStory: "Open Story",
	checkoutStepCompleted: "Checkout Step Completed",
	checkoutStep1Completed: "Checkout Step 1 Completed - Cart",
	checkoutStep2Completed: "Checkout Step 2 Completed - Contact Data Entry",
	checkoutStep3Completed: "Checkout Step 3 Completed - Confirmation",
	filteredProducts: "Filtered Products Collection",
	bookAppointment: "Book Appointment",
	askAQuestion: "Ask a Question",
	suggestProduct: "Suggest Product",
	setPlanLengthProductListingsPage: "Set Plan Length - Product Listing Page",
	setPlanLength: "Set Plan Length",
	setPlanLengthPLP: "Set Plan Length - Listing Page",
	setPlanLengthPDP: "Set Plan Length - Product Page",
	setPlanLengthCart: "Set Plan Length - Cart Page",
	planLength12: "Set Plan Length 12 Months - Product Page",
	planLength24: "Set Plan Length 24 Months - Product Page",
	planLength36: "Set Plan Length 36 Months - Product Page",
	planLength48: "Set Plan Length 48 Months - Product Page",
	planLengthCart12: "Set Plan Length 12 Months - Cart Page",
	planLengthCart24: "Set Plan Length 24 Months - Cart Page",
	planLengthCart36: "Set Plan Length 36 Months - Cart Page",
	planLengthCart48: "Set Plan Length 48 Months - Cart Page",
	planLengthProductListingsPage12: "Set Plan Length 12 Months - Product Listing Page",
	planLengthProductListingsPage24: "Set Plan Length 24 Months - Product Listing Page",
	planLengthProductListingsPage36: "Set Plan Length 36 Months - Product Listing Page",
	planLengthProductListingsPage48: "Set Plan Length 48 Months - Product Listing Page",
	planLength3: "Set Plan Length 3 Months - Product Page",
	planLength6: "Set Plan Length 6 Months - Product Page",
	planLength9: "Set Plan Length 9 Months - Product Page",
	planLength12ForUsed: "Set Plan Length 12 Months - Product Page",
	planLengthProductListingsPage3: "Set Plan Length 3 Months - Product Listing Page",
	planLengthProductListingsPage6: "Set Plan Length 6 Months - Product Listing Page",
	planLengthProductListingsPage9: "Set Plan Length 9 Months - Product Listing Page",
	planLengthProductListingsPage12ForUsed: "Set Plan Length 12 Months - Product Listing Page",
	newsletterFormSubmitted: "Newsletter Form Submitted",
	newsletterFormSubmittedBlog: "Newsletter Form Submitted Blog",
	search: "Search",
	searchTool: "Search Tool",
	brandsBellowSearch: "Brands Bellow Search",
	categoryBellowSearch: "Category Bellow Search",
	plpLayout: "plpLayout",
	addPlusOneToCart: "Add +1 To Cart",
	deleteFromCart: "Delete from Cart",
	seeCart: "See Cart",
	discountCode: "Discount Code",
	showTechnicalDetails: "Show Technical Details",
	showDetails: "Show Details",
	brandLogo: "Brand Logo",
	collectionView: "Collection View",
	blog: "Blog",
	quickAccessFilterCategories: "Section Categories",
	quickAccessFilterBrands: "Section Brands",
	quickAccessFilterCheckedItem: "Checked Item",
	quickAccessFilterAllCheckedItems: "All Checked Items",
	allProductsButton: "All Products Button Pressed",
	allEBrandsButton: "All Brands Button Pressed",
	recommendByCustomers: "Recommend By Customers",
	letsGoButton: "Lets's Go Button",
	googleReviews: "Google Reviews",
	homeCatBoxes: "Products Categories Boxes",
	topModels: "Top Models",
	partnersLogosSection: "Partners Logos Section",
	faq: "FAQ",
	priceRange: "Price Range",
	sortOut: "Sort Out",
	seeNextCustomerReview: "See Next Customer Review",
	deliveryAndFinancing: "Delivery and Financing",
	descriptionSection: "Description Section",
	askYourQuestion: "Ask Your Question Button Clicked",
	consultationPopUp: "Consultation Pop Up",
	newsletterPopUp: "Newsletter Pop Up",
	discountNewsletterPopup: "Discount Newsletter Pop Up",
	remindMeFiltersNoResults: "Remind me: Filter no results (PLP)",
	remindMeFiltersFewResults: "Remind me: Filter few results (PLP)",
	remindMeSizeUnavailable: "Remind me: Size unavailable (PDP)",
	unavailableSize: "Unavailable Size",
	addToCart: "Add To Cart",
	watchLiveQuestions: "Watch Live Questions",
	chooseYourGift: "Choose Your Gift",
	addProductToComparison: "Add Product To Comparison",
	removeProductFromComparison: "Remove Product From Comparison",
	removeAllProductsFromComparison: "Remove All Products From Comparison",
	goToComparisonPage: "Go To Comparison Page",
	startQuiz: "Start Quiz",
	category: "Category",
	topBrands: "Top Brands",
	failedToCreateCheckoutDocument: "Failed to create checkout document",
	downPaymentValue: "Down Payment Value",
	addInsurance: "Add Insurance",
	payOneTime: "Pay One Time",
	readMoreAboutCalingo: "Read More About Calingo",
	pdpExpertReviewFeature: "PDP_Expert Review Feature",
	whatsAppButtonTextUs: "WhatsApp Button TextUs"
}

export const gaCategories = {
	checkout: "Checkout",
	checkoutDocument: "Checkout Document",
	cart: "Cart",
	pageVisit: "Page Visit",
	productFilters: "Product Filters",
	support: "Support",
	consultation: "consultation",
	feedback: "Feedback",
	newsletter: "Newsletter",
	planLength: "Plan Length",
	search: "Search",
	plp: "plp",
	brandLogo: "Brand Logo",
	collectionView: "Collection View",
	quickAccessFilter: "Quick Access Filter",
	megaMenu: "Mega Menu",
	homePage: "Home Page",
	pdp: "pdp",
	comparisonPage: "Comparison Page"
}

export const ecommerceEvents = {
	event: "event",
	addToCart: "add_to_cart",
	removeFromCart: "remove_from_cart",
	beginCheckout: "begin_checkout",
	purchase: "purchase",
	CHF: "CHF",
	viewItem: "view_item"
}
