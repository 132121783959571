import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import React from "react"
import WhatsAppButton from "../../navigation/WhatsAppButton"
import {useTranslation} from "next-i18next"

type Props = {
  lastImageOfSlider: string
}

const WhatsappSlide: React.FC<Props> = ({lastImageOfSlider}) => {
	const {t} = useTranslation()
	return (
		<div className="whatsapp-slide">
			<div className="backdrop-whatsapp-slide" />
			<div className="whatsapp-slide-text w-100 d-flex flex-column justify-content-between">
				<div className="d-flex flex-column align-items-center justify-content-center">
					<div className="d-none d-md-flex" >
						<Image src={"/assets/icons/whats-app-button-white-icon.svg"} alt={"whatsapp"} width={40} height={40} />
						<Typography variant="heading1Bold" semanticTag="h4" className="d-flex align-items-center mb-0" style={{marginLeft: 14}}>+41 43 505 13 18</Typography>
					</div>
					<div className="d-flex d-md-none" >
						<Image src={"/assets/icons/whats-app-button-white-icon.svg"} alt={"whatsapp"} width={24} height={24} />
						<Typography variant="heading2MdHeading3" semanticTag="h4" className="mb-0" style={{marginLeft: 14}}>+41 43 505 13 18</Typography>
					</div>

					<div className="d-flex mt-3 flex-column align-items-center w-100">
						<Typography variant="bodyLgBold" semanticTag="h4" className="mb-1 text-center">{t("get-answers-to-questions.")}</Typography>
						<Image src={"/assets/icons/doodle-border-2.svg"} alt={"whatsapp"} width={62} height={6} />
						<div className="mt-4 d-none d-md-block">
							<WhatsAppButton onlyText textVariant={"bodySmBlack"} />
						</div>
						<div className="d-block d-md-none container col-12 px-3" style={{marginTop: 32}}>
							<WhatsAppButton onlyText textVariant={"bodySmBlack"} classes="w-100"/>
						</div>
					</div>
				</div>
				<div className="d-none d-md-flex justify-content-center align-items-end icons-div" style={{columnGap: 40}}>
					<div>
						<Typography variant="heading3MdBodyLgBlack" semanticTag="span">MyHomePlan</Typography>
						<Typography variant="heading3MdBodyLgBlack" semanticTag="span" className="text-primary">.ch</Typography>
					</div>
					<Image src={"/assets/icons/WhatsApp.svg"} width="112px" height={"26px"} />
				</div>
			</div>
			<div style={{width: "100%", height: "100%", position: "relative"}}>
				<Image className="blur" src={lastImageOfSlider} alt={"whatsapp"} layout="responsive" objectFit="cover" width={288} height={288} style={{borderRadius: 32}} />
			</div>
		</div>

	)
}

export default WhatsappSlide
