import React from "react"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import Image from "next/image"

const ComparisonSection = () => {
	const {t} = useTranslation()

	return (
		<div
			className="container pb-lg-4 mb-5 mt-4 text-start text-md-start d-md-flex flex-column align-items-center">
			<Typography className="mb-4 ps-0 ms-0 text-align-start d-block d-md-none" variant="bodyLgBlack" semanticTag="h3" style={{fontSize: 28}}>{t("comparison-section-title")}</Typography>
			<Typography className="mb-4 ps-0 ms-0 text-align-start d-none d-md-block" variant="heading2Black" semanticTag="h2">{t("comparison-section-title")}</Typography>
			<div className="d-flex justify-content-end col-12">
				<Typography className="col-2 text-center" variant="bodyLgBold">{t("MHP")}</Typography>
				<Typography className="col-2 text-center text-secondary mx-1 mx-md-0" variant="bodyLg">{t("Mieten")}</Typography>
				<Typography className="col-2 text-center text-secondary" variant="bodyLg">{t("Kaufen")}</Typography>
			</div>
			<div className="d-flex col-12">
				<div className="col-12">
					{
						questionsList.map(({idx, label, MHPIcon, MietenIcon, KaufenIcon}) => {
							return (
								<div className="d-flex text-start mt-4 col-12" key={idx}>
									<Typography className="col-6" variant="bodyLg">{t(label)}</Typography>

									<div className="d-flex justify-content-center align-items-start col-2">
										<Image src={MHPIcon} layout={"intrinsic"} objectFit={"contain"} width={24} height={24}/>
									</div>
									<div className=" d-flex justify-content-center align-items-start col-2">
										<Image src={MietenIcon} layout={"intrinsic"} objectFit={"contain"} width={24} height={24}/>
									</div>
									<div className=" d-flex justify-content-center align-items-start col-2">
										<Image src={KaufenIcon} layout={"intrinsic"} objectFit={"contain"} width={24} height={24}/>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</div>
	)
}

export default ComparisonSection

const questionsList = [

	{
		idx: 1,
		label: "comparison-section-text-2",
		MHPIcon: "/assets/icons/comparison-checked-icon-brown.svg",
		MietenIcon: "/assets/icons/comparison-cross-icon.png",
		KaufenIcon: "/assets/icons/comparison-checked-icon-brown.svg"
	},
	{
		idx: 2,
		label: "comparison-section-text-3",
		MHPIcon: "/assets/icons/comparison-checked-icon-brown.svg",
		MietenIcon: "/assets/icons/comparison-checked-icon-brown.svg",
		KaufenIcon: "/assets/icons/faq-dash-icon.svg"
	},
	{
		idx: 3,
		label: "comparison-section-text-7",
		MHPIcon: "/assets/icons/comparison-checked-icon-brown.svg",
		MietenIcon: "/assets/icons/comparison-checked-icon-brown.svg",
		KaufenIcon: "/assets/icons/comparison-checked-icon-brown.svg"
	},
	{
		idx: 4,
		label: "comparison-section-text-6",
		MHPIcon: "/assets/icons/comparison-checked-icon-brown.svg",
		MietenIcon: "/assets/icons/comparison-cross-icon.png",
		KaufenIcon: "/assets/icons/faq-dash-icon.svg"
	},
	{
		idx: 5,
		label: "comparison-section-text-1",
		MHPIcon: "/assets/icons/x-icon-bg-green.svg",
		MietenIcon: "/assets/icons/comparison-cross-icon.png",
		KaufenIcon: "/assets/icons/comparison-checked-icon-brown.svg"
	},
	{
		idx: 6,
		label: "comparison-section-text-4",
		MHPIcon: "/assets/icons/x-icon-bg-green.svg",
		MietenIcon: "/assets/icons/comparison-cross-icon.png",
		KaufenIcon: "/assets/icons/comparison-cross-icon.png"
	},
	{
		idx: 7,
		label: "comparison-section-text-5",
		MHPIcon: "/assets/icons/x-icon-bg-green.svg",
		MietenIcon: "/assets/icons/comparison-checked-icon-brown.svg",
		KaufenIcon: "/assets/icons/faq-dash-icon.svg"
	}
]
