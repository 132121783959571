import React from "react"
import PropTypes from "prop-types"

type Props = {
	className?: string
	children: React.ReactNode
}

const Tabs:React.FC<Props> = ({children, className}) => {
	return (
		<ul className={className}>
			{children}
		</ul>
	)
}

Tabs.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string
}

export default Tabs
