import React from "react"
import PropTypes from "prop-types"
import Typography from "../../reusable/Typography/Typography"
import {findBikeMetaFieldByName} from "../../../utility/Helper"

const BikeName = ({bike}) => {
	let batterySize = bike.attributes.find(attribute => attribute.name === "batterie_size")?.options[0]

	if (batterySize === "814 wh") {
		batterySize = "814 Wh"
	}

	const splitAtBatterySize = bike.name.includes(batterySize)

	const highlightedText = findBikeMetaFieldByName(bike, "highlight_text")
	let highlightedBikeNameParts = []
	if (highlightedText) {
		highlightedBikeNameParts = bike.name.split(highlightedText)
		if (highlightedBikeNameParts.length) {
			highlightedBikeNameParts.splice(1, 0, highlightedText)
		}
	}

	return (
		splitAtBatterySize && !highlightedText ? <>
			<Typography variant="bodyXSmExtraBoldMdHeading3" semanticTag={"h3"}>
				{bike.name.replace(batterySize, "")}
				<Typography variant="heading3" semanticTag="span"
					className="badge bg-primary text-dark pt-1 py-1 px-2">
					{batterySize}
				</Typography>
			</Typography>
		</> : highlightedText && highlightedBikeNameParts.length === 3 ?
			<Typography variant="bodyXSmExtraBoldMdHeading3" semanticTag={"h3"}>
				{highlightedBikeNameParts.map((item, index) => index === 1 ?
					<Typography variant="heading3" semanticTag="span" className="badge bg-primary text-dark pt-1 py-1 px-2">
						{item}
					</Typography> : item)}
			</Typography> :
			<Typography variant="bodyXSmExtraBoldMdHeading3" semanticTag="h3">
				{bike.name}
			</Typography>
	)
}

BikeName.propTypes = {
	bike: PropTypes.any.isRequired
}

export default BikeName
