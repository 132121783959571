import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import RouteTransitionContext from "../context/RouteTransitionContext"
import {useRouter} from "next/router"
import LoadingOverlay from "../components/routeTransition/LoadingOverlay"

type Props = {
    children: React.ReactNode
}

enum OverlayIgnorePages {
	"alle-modelle-e-bike-schweiz" = 1,
	produit,
	produkt,
	beratung,
	faq,
	zubehor
}

const RouteTransitionContextProvider: React.FC<Props> = ({children}) => {
	const [transitioning, setTransitioning] = useState<boolean>(false)
	const router = useRouter()
	const pathName = router.pathname.split("/")[1] ? router.pathname.split("/")[1] : ""
	const showOverlay = !OverlayIgnorePages[pathName]

	useEffect(() => {
		const handleStart = () => {
			return setTransitioning(true)
		}

		const handleComplete = () => {
			return setTransitioning(false)
		}

		router.events.on("routeChangeStart", handleStart)
		router.events.on("routeChangeComplete", handleComplete)
		router.events.on("routeChangeError", handleComplete)

		return () => {
			router.events.off("routeChangeStart", handleStart)
			router.events.off("routeChangeComplete", handleComplete)
			router.events.off("routeChangeError", handleComplete)
		}
	})

	return (
		<RouteTransitionContext.Provider value={{transitioning, setTransitioning}}>
			{showOverlay && <LoadingOverlay isVisible={transitioning} color="light"/>}
			{children}
		</RouteTransitionContext.Provider>
	)
}

RouteTransitionContextProvider.propTypes =
    {children: PropTypes.node}

export default RouteTransitionContextProvider
