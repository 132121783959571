import React from "react"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import getImageUrl from "../../contentful/image/image"
import Image from "next/image"
import RichText from "../../contentful/render/RichText"
import Link from "next/link"

type Props = {
    brandInfoItemContentful: any
}

const BrandInfo: React.FC<Props> = ({brandInfoItemContentful}) => {
	const {t} = useTranslation()
	const {fields} = brandInfoItemContentful
	const {brandName, brandIcon, brandDescription, slug} = fields
	const _href = `/${t("brands")}/${slug}-ebikes`
	return (
		<div className="col-12 p-0 p-lg-3 bg-inherit-grey">
			<Typography className="mb-3" variant="heading2MdLgBold">{`${t("about-brand")} ${brandName}`}</Typography>
			<div className="position-relative mb-3" style={{minHeight: 40}}>
				<Image src={getImageUrl(brandIcon)} objectPosition="left" objectFit="contain" layout={"fill"}/>
			</div>
			<RichText content={brandDescription.content}/>
			<Link href={_href}>
				<a className="text-decoration-none text-dark col-auto border-bottom border-2 border-primary pointer px-0" >
					<Typography className=""
						variant="bodyLgBold" semanticTag="span">
						{`${t("check-more-brand-bikes")} ${brandName}`}
					</Typography>
				</a>
			</Link>
		</div>

	)
}

export default BrandInfo
