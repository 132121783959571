import {authStateChangedCallback} from "../auth/authStateChangedCallback"
import {firebaseConfig} from "../config"

export const handleGetFirestore = async app => {
	const {getFirestore} = await import("@firebase/firestore/lite")
	return getFirestore(app)
}

export const handleOnAuthStateChanged = async (auth, setUserLoading, setUser) => {
	const {onAuthStateChanged} = await import("@firebase/auth")
	onAuthStateChanged(auth, user => {
		setUserLoading(true)
		authStateChangedCallback(auth, user).then(() => {
			setUser(user)
		}).catch(error => console.error("Firebase | Auth state changed callback", error))
	})
}

export const handleInitializeApp = async () => {
	const {initializeApp} = await import("@firebase/app")
	return initializeApp(firebaseConfig)
}

export const handleGetAuth = async () => {
	const {getAuth} = await import("@firebase/auth")
	return getAuth()
}
