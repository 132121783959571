/* eslint-disable complexity */
import React, {useContext} from "react"
import CheckoutContext from "../../../context/CheckoutContext"
import {useTranslation} from "next-i18next"
import priceFormatter from "../../../utility/priceFormatter"
import Typography from "../../reusable/Typography/Typography"
import PropTypes from "prop-types"

type Props = {
	price: number,
	onSale?: boolean,
	regularPrice?: number | string
	listView?: boolean
	isSecondHand?: boolean
	secondHandPrice?: number
	isAccessoryPrice?: boolean
	priceVariant?: any
	customInstanceDiscount?:number
}

const TotalPrice: React.FC<Props> = ({
	price,
	onSale,
	isAccessoryPrice,
	regularPrice,
	listView,
	isSecondHand,
	secondHandPrice,
	priceVariant = "bodyLg",
	customInstanceDiscount
}) => {
	const {data} = useContext(CheckoutContext)
	const {t} = useTranslation()
	const _price = priceFormatter(price)
	const _secondHandPrice = priceFormatter(secondHandPrice)
	const numberWithCommasRemoved = _price.replace(/,/g, "")
	const roundedNumber = Math.ceil(parseFloat(numberWithCommasRemoved))
	const formattedNumber = new Intl.NumberFormat().format(roundedNumber)
	const formattedSbbPrice = new Intl.NumberFormat().format(roundedNumber - customInstanceDiscount > 0 ? roundedNumber - customInstanceDiscount : 0)

	return (
		listView && (data?.planLength !== 1 || !data?.planLength) ?
			<div className={"row text-secondary"}>
				<div className={"col-12 justify-content-start align-items-end d-flex"}>
					<Typography variant={priceVariant} semanticTag="div" className={"me-1"}
						style={{color: onSale || isSecondHand || customInstanceDiscount ? "#E61C45" : "", fontSize: 12}}>
						{t("Gesamtpreis")}:
					</Typography>

					{
						process.env.NEXT_PUBLIC_ORGANIZATION && customInstanceDiscount ?
							<>
								<div className={"col-12 gap-1 text-start justify-content-start align-items-center d-none d-md-flex"}>
									<Typography variant={"bodyXSmBold"} semanticTag="div" style={{color: "#E61C45"}}>
										{`${t("CHF")} ${formattedSbbPrice}`}
									</Typography>
									<Typography variant={"bodyXSm"} semanticTag="div"
										className={"text-decoration-line-through "}>
										{`CHF ${priceFormatter(price as number)}`}
									</Typography>
								</div>
								<div className={"col-auto text-end d-md-none "}>

									<Typography variant={"bodyXSm"} semanticTag="div"
										className={"text-decoration-line-through ms-2"}>
										{`CHF ${priceFormatter(price as number)}`}
									</Typography>
									<Typography variant={priceVariant} semanticTag="div" style={{color: "#E61C45", fontSize: 12}}>
										{`${t(" CHF")} ${formattedSbbPrice}`}
									</Typography>
								</div>
							</> : onSale ? <>
								<div className={"col-auto text-end  d-none d-md-flex flex-wrap"}>
									<Typography variant={priceVariant} semanticTag="div" style={{color: "#E61C45", fontSize: 12}}>
										{`${t(" CHF")} ${formattedNumber}`}
									</Typography>
									{regularPrice ?
										<Typography variant={"bodyXSm"} semanticTag="div"
											className={"text-decoration-line-through "}>
											{`CHF ${priceFormatter(regularPrice as number)}`}
										</Typography> : null}
								</div>
								<div className={"col-auto text-end d-md-none"}>
									{regularPrice ?
										<Typography variant={"bodyXSm"} semanticTag="div"
											className={"text-decoration-line-through "}>
											{`CHF ${priceFormatter(regularPrice as number)}`}
										</Typography> : null}
									<Typography variant={priceVariant} semanticTag="div" style={{color: "#E61C45", fontSize: 12}}>
										{`${t(" CHF")} ${formattedNumber}`}
									</Typography>
								</div>
							</> :
								isSecondHand ? <div className={"col-auto text-end d-md-flex"}>
									<Typography variant={"bodyXSm"} semanticTag="div" className={"d-md-none text-decoration-line-through ms-2"}>
										{`CHF ${_price}`}
									</Typography>
									<Typography variant={priceVariant} semanticTag="div" style={{color: "#E61C45", fontSize: 12}}>
										{`${t(" CHF")} ${_secondHandPrice}`}
									</Typography>
									<Typography variant={"bodyXSm"} semanticTag="div" className={"d-none d-md-block text-decoration-line-through ms-2"}>
										{`CHF ${_price}`}
									</Typography>
								</div> :
									<Typography variant={priceVariant} style={{fontSize: 12}} semanticTag="div" className={"col-auto"}>
										{`${t("CHF")} ${_price}`}
									</Typography>}
				</div>
			</div> :
			(data?.planLength !== 1 || !data?.planLength) && <><div
				className={`row justify-content-between align-items-end ${isAccessoryPrice ? "mt-0" : "mt-2"} mt-md-1  d-flex`} style={{color: "#6C7074"}}>
				{!isSecondHand && !isAccessoryPrice && <Typography variant={priceVariant} semanticTag="div" className={"col"}>
					{`${(data?.planLength && data?.planLength >= 12) ? data.planLength : 48} ${t("Monate")}`}
				</Typography>}

				{isSecondHand && !isAccessoryPrice && <Typography variant={priceVariant} semanticTag="div" className={"col"}>
					{`${(data?.planLength && data?.planLength <= 12) ? data.planLength : 12} ${t("Monate")}`}
				</Typography>}

				{onSale ? <div className={"col-auto text-end"}>
					{regularPrice ? <Typography variant={"bodyXSm"} semanticTag="div" className={"text-decoration-line-through"}>
						{`CHF ${priceFormatter(regularPrice as number)}`}
					</Typography> : null}
					<Typography variant={isAccessoryPrice ? "bodyXSm" : priceVariant} semanticTag="div">
						{`${t("Gesamtpreis CHF")} ${_price}`}
					</Typography>
				</div> :
					isSecondHand ? <div className={"col-auto text-end"}>
						<Typography variant={"bodyXSm"} semanticTag="div" className={"text-decoration-line-through"}>
							{`CHF ${_price}`}
						</Typography>
						<Typography variant={isAccessoryPrice ? "bodyXSm" : priceVariant} semanticTag="div">
							{`${t("Gesamtpreis CHF")} ${_secondHandPrice}`}
						</Typography>
					</div> :
						<Typography variant={isAccessoryPrice ? "bodyXSm" : priceVariant} semanticTag="span" className={"col-auto"}>
							{t("Gesamtpreis")}:	{`${t("CHF")} ${_price}`}
						</Typography>
				}

			</div></>
	)
}

TotalPrice.propTypes = {
	price: PropTypes.number.isRequired,
	onSale: PropTypes.bool,
	regularPrice: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	])
}

export default TotalPrice
