import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {DashLg, PlusLg} from "react-bootstrap-icons"

type Props = {
    defaultValue: number,
    min: number,
    max: number | null,
    onChange: (value: number) => void | null,
	step?: number,
	size?: string
}
const NumberButton = ({defaultValue, min = 0, max = null, onChange, step = 1, size = "lg"}: Props) => {
	const [currentValue, setCurrentValue] = useState(defaultValue)

	useEffect(() => {
		if (onChange) {
			onChange(currentValue)
		}
	}, [currentValue])

	return (
		<div className={"border rounded-pill"}>
			<div className={"row g-0"}>
				<div className={"col"}>
					<button onClick={() => setCurrentValue(prevState => {
						const value = prevState - step
						return value >= min ? value : min
					})} type="button" className={`btn btn-block ${size === "lg" ? "py-4" : "py-2"}`} style={{
						width: "100%"
					}}>
						<DashLg/>
					</button>
				</div>
				<div className={"col-auto"}>
					<div className={"row align-items-center justify-content-center"} style={{
						height: "100%"
					}}>
						<div className={"col-auto"}>
							{currentValue}
						</div>
					</div>
				</div>
				<div className={"col"}>
					<button onClick={() => setCurrentValue(prevState => {
						const value = prevState + step
						return value >= min ? value : min
					})} type="button" className={`btn btn-block ${size === "lg" ? "py-4" : "py-2"}`} style={{
						width: "100%"
					}}>
						<PlusLg/>
					</button>
				</div>
			</div>
		</div>
	)
}

NumberButton.propTypes = {
	defaultValue: PropTypes.number.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	step: PropTypes.number,
	size: PropTypes.string
}

export default NumberButton
