import {useRouter} from "next/router"
import React from "react"
import PropTypes from "prop-types"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"

type Props = {
  name: string;
  brand: string;
  stockInfo: string;
  isSecondHand?: boolean;
  is2023?: boolean;
  isBikeOfTheMonth?: boolean;
  showBikeInfo?: boolean
	withText?: boolean
	onClickFallback?: ()=>void
};

const GoBack: React.FC<Props> = ({
	name,
	brand,
	stockInfo,
	isSecondHand = false,
	is2023,
	isBikeOfTheMonth,
	showBikeInfo = true,
	withText = false,
	onClickFallback = () => null
}) => {
	const {t} = useTranslation()

	const router = useRouter()
	const onClickBack = () => {
		onClickFallback()
		router.back()
	}

	return (
		<div className="row cursor-pointer mt-md-2 mb-md-1">
			<div onClick={onClickBack} className="go-back col-auto rounded-3 position-relative d-flex align-items-center p-2">
				<div className="d-flex justify-content-center flex-column">
					<img src="/assets/icons/arrow-left.svg" alt="arrowleft"/>
					<i style={{top: "22px"}} className="bi bi-arrow-left fs-5 position-relative d-none d-md-block"/>
				</div>
				{withText &&
					<Typography className="ms-2" variant={"bodySmBold"}>{t("Back")}</Typography>
				}
			</div>
			<div className="col-10">
				<div className="row">
					{showBikeInfo && <>
						<Typography variant="bodyLg" semanticTag="div"
							className={"col-auto text-secondary text-uppercase"}>
							{brand}
						</Typography>
						<Typography variant="bodyLg" semanticTag="div"
							className={"col-auto text-uppercase text-end text-success text-bold "}>
							{stockInfo}
						</Typography>
					</>}
					{isSecondHand && (
						<Typography variant="bodyLg" semanticTag="div"
							className={"col-auto text-uppercase text-end text-bold"}>
							{t("second-hand")}
						</Typography>
					)}
				</div>
				{/* <Typography variant="bodyLg" semanticTag="div" className="col text-secondary">{t(brand?.toUpperCase())}</Typography> */}
				{showBikeInfo && <Typography variant="heading3" semanticTag="h3" className="col fs-3">{t(name)}</Typography>}
				{isBikeOfTheMonth && <Typography variant="bodySmBold" semanticTag="span" style={{backgroundColor: "#000"}}
					className="badge text-white text-uppercase py-1 px-3 mt-1 me-2">
					{t("bike-of-the-month")}
				</Typography>}
				{is2023 && <Typography variant="bodySmBold" semanticTag="span" style={{backgroundColor: "#020887"}}
					className="badge text-white text-uppercase py-1 px-3 mt-1 d-none d-md-block">{t("2023-model")}</Typography>}
			</div>
		</div>
	)
}

GoBack.propTypes = {
	name: PropTypes.string,
	brand: PropTypes.string,
	stockInfo: PropTypes.string
}
export default GoBack
