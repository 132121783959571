import React, {useRef, useState} from "react"
import {AnimationType} from "../components/reusable/components/AnimationComponent/AnimationComponent"
import {ModalContext, ModalContextState} from "../context/ModalContext"
import dynamic from "next/dynamic"

const DynamicModal = dynamic(() => import("../components/reusable/components/Modal/Modal"), {
	loading: () => <></>
})

const localStorageItemName = "modal-last-time--trigger"
// Const _MS_PER_DAY = 1000 * 60
const _MS_PER_DAY = 1000 * 60 * 60 * 24

type Props = {
    children: React.ReactNode
}

const saveToLocalStorage = (time:Date) => {
	if (typeof window !== "undefined") {
		localStorage.setItem(localStorageItemName, JSON.stringify(time))
	}
}

function dateDiffInDays(a:Date, b:Date): number {
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes())
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes())

	return (utc2 - utc1) / _MS_PER_DAY
	// Return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

const getTimeFromLastOpen = (currentTime: Date): number => {
	// eslint-disable-next-line no-negated-condition
	let lastTimeOpen
	if (typeof window !== "undefined") {
		lastTimeOpen = JSON.parse(localStorage.getItem(localStorageItemName))
	}

	if (lastTimeOpen) {
		const difference = dateDiffInDays(new Date(lastTimeOpen), currentTime)
		return difference
	}

	return -1
}

const ModalContextProvider: React.FC<Props> = ({children}) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const [isStoryOpen, setIsStoryOpen] = useState<boolean>(false)
	const [content, setContent] = useState<React.ReactNode>(null)
	const [storyContent, setStoryContent] = useState<React.ReactNode>(null)
	const [animationType, setAnimationType] = useState<keyof typeof AnimationType>("fade")
	const timer = useRef<ReturnType<typeof setTimeout>>()

	const closeModal = () => {
		setIsModalOpen(false)
		setContent(null)
	}

	const closeStory = () => {
		setIsStoryOpen(false)
		setContent(null)
	}

	const openModal = () => setIsModalOpen(true)
	const openStory = () => setIsStoryOpen(true)
	const toggleModal = () => setIsModalOpen(isOpen => !isOpen)

	const setModalContent = (content: React.ReactNode) => {
		setContent(content)
	}

	const setModalModalAnimationType = (type: keyof typeof AnimationType) => {
		setAnimationType(type)
	}

	const startTimeout = (duration: number = 7000) => {
		const timeFromLastOpen = getTimeFromLastOpen(new Date())

		if (timeFromLastOpen === -1 || timeFromLastOpen >= 1) {
			timer.current = setTimeout(() => {
				saveToLocalStorage(new Date())
				setIsModalOpen(true)
			}, duration)
		}
	}

	const cancelTimeout = () => {
		if (timer.current) {
			clearTimeout(timer.current)
		}
	}

	const value:ModalContextState = {
		openStory,
		closeStory,
		isModalOpen,
		isStoryOpen,
		toggleModal,
		closeModal,
		setModalContent,
		setStoryContent,
		openModal,
		startTimeout,
		cancelTimeout,
		setModalModalAnimationType
	}

	return (
		<ModalContext.Provider value={value}>
			{isStoryOpen ? content : <DynamicModal type={animationType} closeOnTouch isOpen={isModalOpen} closeModal={closeModal}>
				{content}
			</DynamicModal>}
			{children}
		</ModalContext.Provider>
	)
}

export default ModalContextProvider
