import React from "react"
import PropTypes from "prop-types"

type colorObject = {
color: string
inStock: string
slug: string
}

type props = {
    colors?: colorObject[]
}
function ProductColors({colors}: props) {
	if (colors?.length) {
		return (
			<div className="d-flex  col-12 justify-content-start gap-2 flex-wrap align-items-center">{colors.map((item, index) => <div style={{borderRadius: "4px", width: "20px", border: "1px solid #edebeb", height: "20px", backgroundColor: item.color}} key={index}>

			</div>)}</div>
		)
	}

	return null
}

ProductColors.propTypes = {
	colors: PropTypes.any.isRequired
}

export default ProductColors
