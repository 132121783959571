import React from "react"
import {FirebaseApp} from "@firebase/app"
import {Auth, User} from "@firebase/auth"
import {Firestore} from "@firebase/firestore/lite"

export type FirebaseContextProviderState = {
    app: FirebaseApp | null,
    auth: Auth | null,
    db: Firestore,
    user: User | null,
    getProductQuestions: (bikeSlug: string) => Promise<any>,
    getProductQuestionsAdmin: () => Promise<any>,
    postProductQuestion: (bikeSlug: string, bikeName: string, sku: string, firstName: string, lastName: string, questionText: string, email: string) => Promise<any>,
    login: (providerName: string) => void,
    logout: () => void,
    userLoading: boolean,
	getCheckoutsAdmin: () => Promise<any>
}

const FirebaseContext = React.createContext<FirebaseContextProviderState>({
	app: null,
	auth: null,
	db: null,
	user: null,
	login: async () => {
		// Do nothing
	},
	logout: async () => {
		// Do nothing
	},
	getProductQuestions: async () => {
		// Do nothing
	},
	getProductQuestionsAdmin: async () => {
		// Do nothing
	},
	postProductQuestion: async () => {
		// Do nothing
	},
	userLoading: false,
	getCheckoutsAdmin: async () => {
		// Do nothing
	}
})

export default FirebaseContext
