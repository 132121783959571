import React, {useState} from "react"
import PropTypes from "prop-types"
import {useRouter} from "next/router"
import Typography from "../reusable/Typography/Typography"
import FaqItemList from "./FaqItemList"
import {useTranslation} from "next-i18next"
import AccordionItem from "../Accordion/AccordionItem"
import slugify from "slugify"

type Props = {
    tabs: any,
    faqData: any
}

const FaqTabs: React.FC<Props> = ({tabs, faqData}) => {
	const {t} = useTranslation()
	const router = useRouter()
	const {tab} = router.query
	const [active, setActive] = useState(tab)

	const onToggle = element => {
		router.push(`/faq/${element}`, undefined, {shallow: true, scroll: false}).then()
		setActive(element)
	}

	return (
		<div className={"container"}>
			<div className="d-none d-md-block">
				<div className="row justify-content-center">
					{tabs.map((element, i) => {
						const tabElement = slugify(element).toLowerCase()
						let className = "col-auto pointer"
						if (tab === tabElement) {
							className = "col-auto nav-link-active pointer"
						}

						return <span onClick={() => onToggle(t(tabElement))} key={++i} className={className}>
							<Typography variant={"bodyLgBold"} semanticTag={"h3"}>
								{t(element)}
							</Typography>
						</span>
					})}
				</div>
				<FaqItemList faqData={faqData}/>
			</div>
			<div className="d-md-none accordion accordion-flush d-grid gap-2" id="accordionFlush">
				{tabs.map(title => (
					<AccordionItem
						key={title}
						active={active === t(slugify(title).toLowerCase())}
						title={t(title)}
						onToggle={onToggle}
						multiple={true}
						noShadow
					>
						<FaqItemList faqData={faqData}/>
					</AccordionItem>
				))}
			</div>
		</div>
	)
}

FaqTabs.propTypes = {
	tabs: PropTypes.any,
	faqData: PropTypes.any
}

export default FaqTabs
