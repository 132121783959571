/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React, {Children, useRef, useState} from "react"
import {ArrowLeft, ArrowRight} from "react-bootstrap-icons"
import Slider, {CustomArrowProps, Settings} from "react-slick"
import {sliderContext} from "./sliderContext"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

type CenterModePadding = {
	left: number
	right: number
	bottom: number
}

export type SlidesProps = {
	arrows?: boolean
	arrowColor?: string
	dots?: boolean
	autoplay?: boolean
	dotsPositionY?: number
	arrowsPositionX?: number
	slidesToShow?: number
	slidesToScroll?: number
	className?: string
	centerMode?: boolean
	centerModePadding?: CenterModePadding
	galery?: () => React.ReactElement
	draggable?: boolean
}

const Slides: React.FC<SlidesProps> = ({
	arrows,
	arrowColor,
	dots,
	autoplay,
	dotsPositionY = 0,
	arrowsPositionX = 1,
	slidesToShow = 1,
	slidesToScroll = 1,
	children,
	className,
	draggable = true,
	centerMode = false,
	centerModePadding = {
		bottom: 0,
		left: 20,
		right: 20
	},
	galery

}) => {
	const sliderRef = useRef<Slider>()
	const [activeSlideIndex, setActiveSlideIndex] = useState(0)

	const goToSlide = (slideNumber: number) => sliderRef.current?.slickGoTo(slideNumber)
	const nextSlide = () => sliderRef.current?.slickNext()
	const prevSlide = () => sliderRef.current?.slickPrev()

interface ArrowProps extends CustomArrowProps{
	direction : "left" | "right"
}
function CustomArrow({onClick, direction}:ArrowProps) {
	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
		onClick(e)
	}

	const styles = direction === "right" ? {right: arrowsPositionX + "%"} : {left: arrowsPositionX + "%"}

	return (
		<div
			className={"bg-white rounded-circle text-dark p-2 position-absolute shadow-sm pointer"}
			style={{top: "50%", transform: "translateY(-50%)", zIndex: 1, ...styles}}
			onClick={handleClick}
		>
			{direction === "right" ? <ArrowRight size={20} color={arrowColor ? arrowColor : ""}/> :
				<ArrowLeft size={20} color={arrowColor ? arrowColor : ""}/>}
		</div>
	)
}

const sliderConfig: Settings = {
	infinite: true,
	autoplay,
	speed: 500,
	autoplaySpeed: 5000,
	slidesToShow,
	slidesToScroll,
	centerMode,
	centerPadding: `${centerModePadding.right}px ${centerModePadding.bottom}px ${centerModePadding.left}px`,
	dots,
	arrows,
	draggable,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: slidesToShow > 3 ? 3 : slidesToShow
			}
		},
		{
			breakpoint: 843,
			settings: {
				slidesToShow: slidesToShow > 2 ? 2 : slidesToShow
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: slidesToShow > 1 ? 1 : slidesToShow
			}
		}
	],
	appendDots: dots => (
		<div
			style={{
				padding: "10px",
				bottom: dotsPositionY
			}}>
			<ul> {dots} </ul>
		</div>
	),
	focusOnSelect: true,
	beforeChange: (_, next) => setActiveSlideIndex(next),
	prevArrow: <CustomArrow direction="left"/>,
	nextArrow: <CustomArrow direction="right"/>
}

const value = {
	goToSlide,
	nextSlide,
	prevSlide,
	activeSlideIndex
}
return (
	<sliderContext.Provider value={value}>
		<div className={className}>
			<Slider ref={sliderRef} {...sliderConfig}>
				{Children.toArray(children).filter((child: React.ReactElement) => !child?.props?._noSlide)}
			</Slider>
			{galery && galery()}
			{Children.toArray(children).filter((child: React.ReactElement) => child?.props?._noSlide)}

		</div>
	</sliderContext.Provider>
)
}

export default Slides
