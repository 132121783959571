import React from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
	amount: number
	islistView?: boolean
}

const StockWarning = ({amount, islistView} : Props) => {
	const {t} = useTranslation()
	return (<>
		{amount ?
			<Typography variant={ "bodySmBold"} semanticTag="span"
				className={"badge text-white mt-2 position-absolute"}
				style={{
					borderRadius: "13px",
					color: "#212529",
					fontSize: "11px",
					padding: "2px 8px",
					left: "10px",
					opacity: 0.7,
					bottom: islistView ? 0 : -27,
					backgroundColor: "#E61C45"
				}}>{amount === 1 ? `${t("last-piece")}!` : (amount > 0 && amount < 5) ? `${t("only-yet")} ${amount} ${t("available")}!` : null}</Typography> : null
		}
	</>)
}

export default StockWarning
