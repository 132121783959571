/* eslint-disable complexity */
import {Field, useFormikContext} from "formik"
import React, {useContext, useEffect} from "react"
import CheckoutContext from "../../../context/CheckoutContext"
import LayoutContext from "../../../context/LayoutContext"
import Typography from "../Typography/Typography"

export enum Color {
  white = "white",
  dark = "dark",
  danger = "danger",
  light = "light",
}

type Props = {
  name: string
  label: string
  errors: object
  touched: object
  color?: keyof typeof Color
  bgColor?: keyof typeof Color
  errColor?: keyof typeof Color
  className?: string
  required?: boolean,
  getInputProps?: any,
  placeholder?: string,
  type?: string,
  pattern?: string,
  as?: string,
  checkout?: boolean,
  onBlur?: () => void,
  onFocus?: () => void,
  onKeyUp?: () => void,
  onKeyDown?: (e: any) => void,
  onChange?: (event) => void,
  disabled?: boolean,
  textarea?: boolean
  min?: string
  max?: string
  errorDisplay?: boolean
  isNewDesign?: boolean
}

const FormInput = ({
	getInputProps,
	name,
	label,
	color = Color.dark,
	bgColor = Color.white,
	errColor = Color.white,
	errors,
	touched,
	required,
	className,
	placeholder,
	type = "text",
	pattern = null,
	as = "",
	checkout,
	onBlur,
	onFocus,
	onKeyDown,
	onKeyUp,
	onChange,
	disabled,
	textarea,
	min,
	max,
	errorDisplay = true,
	isNewDesign
}: Props): React.ReactElement => {
	const {values} = useFormikContext()
	const {data, setData} = useContext(CheckoutContext)
	const {isMobile} = useContext(LayoutContext)

	const items = data?.deals.map(deal => deal.e_bike_choice)
	let eBikeChoice = ""

	items?.forEach(item => {
		eBikeChoice += `${item}, `
	})
	useEffect(() => {
		if (checkout) {
			localStorage.setItem("userCartData", JSON.stringify({submited: false, data: values, eBikeChoice}))
		}
	}, [])

	return (
		<>
			<label htmlFor={name} className={"text-left mb-2"}>
				<Typography variant={"bodySmBoldMdBodyLgBold"} semanticTag="span">{label}</Typography>{required &&
					<span className="text-danger">*</span>}
			</label>
			<div
				className={`position-relative border rounded mb-4 py-2 ${className ? className : "pb-2"} ${errors && (errors[name] && touched[name]) && "border-danger"}`}>
				{onBlur ? onFocus ?
					<Field min={min && min} max={max && max} className={`col-12 ps-2 py-1 transparent-input text-${color} id={name}`} name={name} type={type} pattern={pattern} onBlur={onBlur} onFocus={onFocus} textarea={textarea}/> :
					<Field min={min && min} max={max && max} onKeyDown={onKeyDown ? (e: any) => onKeyDown(e) : null} className={`col-12 ps-2 py-1 transparent-input text-${color} id={name}`} name={name} type={type} pattern={pattern} onBlur={onBlur} disabled={disabled} textarea={textarea}/> :
					getInputProps ?
						<Field {...getInputProps({
							placeholder,
							name,
							type,
							pattern,
							as
							// ClassName: `col-12 transparent-input text-${color} id={name} location-search-input`
						})} min={min && min} max={max && max} textarea={textarea}
						/> :
						<Field min={min && min} max={max && max} className={`col-12 ps-2 py-1 transparent-input text-${color} id={name}`} name={name} type={type} pattern={pattern} textarea={textarea}/>
				}

				{(errorDisplay && errors && errors[name] && touched[name]) ? (
					<Typography variant="bodyLgBold" semanticTag="div"
						className={"text-danger ps-2"}>{errors[name]}</Typography>
				) : null}
			</div>
		</>
	)
}

export default FormInput
