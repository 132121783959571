/* eslint-disable */
import React from "react"
import {CSSTransition} from "react-transition-group"
import slideUpClasses from "./slideUp.module.scss"
import slideDownClasses from "./slideDown.module.scss"
import accordionDownClasses from "./accordionDown.module.scss"
import fadeClasses from "./fade.module.scss"
import slideRightClasses from "./slideRight.module.scss"

export enum AnimationType {
    fade = "fadeClasses",
    slideUp = "slideUpClasses",
    slideDown = "slideDownClasses",
    slideRight = "slideRightClasses",
    accordionDown = "accordionDownClasses",
}

type Props = {
    inProp: boolean,
    duration?: number,
    children: React.ReactChildren | React.ReactChild
    type: keyof typeof AnimationType
    onExited?: () => void
}

const classes = {
    slideUpClasses, slideDownClasses, accordionDownClasses, fadeClasses, slideRightClasses
}

const AnimationComponent = ({inProp, duration, type, children, onExited}: Props) => {

    return (
        <CSSTransition
            unmountOnExit
            mountOnEnter
            in={inProp}
            timeout={duration ? duration : 400}
            classNames={{...classes[AnimationType[type]]}}
            onExited={onExited}>
            {children}
        </CSSTransition>

    )
}

export default AnimationComponent
/* eslint-enable */
