import React, {useState} from "react"
import PropTypes from "prop-types"
import SuggestBikeModal from "../bikeDetails/SuggestBikeModal"
import BookAppointmentModal from "../bikeDetails/BookAppointmentModal"
import {getCategoryBySlug} from "../../utility/Helper"
import Link from "next/link"
import Typography from "./Typography/Typography"
import Modal from "./components/Modal/Modal"
import {filterUrl} from "../filter/FilterUrl"
import urlParams from "../../utility/UrlParams"

type Props = {
    bike: any,
    t: any
}

const SameCategoryBikes: React.FC<Props> = ({t, bike}) => {
	const [bikeSuggest, setBikeSuggest] = useState(false)
	const [bookAppointment, setBookAppointment] = useState(false)
	return (<>
		<div className={"position-relative d-block d-md-none vh-40"}>
			<div>
				<div className={"row justify-content-end g-0 vh-40"}>
					<div className={"col-12 col-md-6"} style={{
						backgroundImage: "url('/assets/images/galerie-5.jpg')",
						backgroundPosition: "center center",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat"
					}}/>
				</div>
			</div>
		</div>
		<div className={"bg-light position-relative vh-50"}>
			<div className={"position-absolute top-0 start-0 bottom-0 end-0 vh-50 d-none d-md-block"}>
				<div className={"vh-50"}>
					<div className={"row justify-content-end g-0 vh-50"}>
						<div className={"col-12 col-md-6 vh-50"} style={{
							backgroundImage: "url('/assets/images/galerie-5.jpg')",
							backgroundPosition: "center center",
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat"
						}}/>
					</div>
				</div>
			</div>
			<div className={"position-absolute top-0 start-0 bottom-0 end-0 vh-50"}>
				<div className={"container"}>
					<div className={"row g-0 vh-50 align-items-center"}>
						<div className={"col-12 col-md-6"}>
							<div className={"row"}>
								<div className={"col-12 col-lg-10"}>
									<div className={"me-4"}>
										<Typography variant="heading2" semanticTag="h2" className={"text-center text-md-start"}>
											{getCategoryBySlug(bike, "e-mountain") ?
												t("Hast dein perfektes E-Bike nicht gefunden? Sie dir hier alle unsere Mountain E-Bikes an.") :
												getCategoryBySlug(bike, "e-urban") ?
													t("Hast dein perfektes E-Bike nicht gefunden? Sie dir hier alle unsere Urban E-Bikes an.") :
													getCategoryBySlug(bike, "e-trekking") ?
														t("Hast dein perfektes E-Bike nicht gefunden? Sie dir hier alle unsere Urban E-Trekking an.") :
														null

											}
										</Typography>
									</div>
								</div>
							</div>
							<div className={"row mt-4"}>
								<div className={"col-12 col-md-auto"}>
									{process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" ?
										<div className={"d-grid"}>
											{getCategoryBySlug(bike, "sofa") ?
												<Link href={filterUrl({[urlParams.category]: "sofa"}, null, null, t)}>
													<a className={"btn btn-primary btn-lg d-grid"}>
														Sofa
													</a>
												</Link> :
												getCategoryBySlug(bike, "table") ?
													<Link href={filterUrl({[urlParams.category]: "table"}, null, null, t)}>
														<a className={"btn btn-primary btn-lg d-grid"}>
															Table
														</a>
													</Link> :
													getCategoryBySlug(bike, "bed") ?
														<Link href={filterUrl({[urlParams.category]: "bed"}, null, null, t)}>
															<a className={"btn btn-primary btn-lg d-grid"}>
																Bed
															</a>
														</Link> :
														getCategoryBySlug(bike, "add_ons") ?
															<Link href={filterUrl({[urlParams.category]: "add_ons"}, null, null, t)}>
																<a className={"btn btn-primary btn-lg d-grid"}>
																	Add Ons
																</a>
															</Link> :
															null
											}
										</div> :
										<div className={"d-grid"}>
											{getCategoryBySlug(bike, "e-mountain") ?
												<Link href={`/${t("categories")}${t("/mountain-e-bikes")}`}>
													<a className={"btn btn-primary btn-lg d-grid"}>
														{t("Alle Modelle Mountain E-Bike")}
													</a>
												</Link> :
												getCategoryBySlug(bike, "e-urban") ?
													<Link href={`/${t("categories")}${t("/city-e-bikes")}`}>
														<a className={"btn btn-primary btn-lg d-grid"}>
															{t("Alle Modelle Urban E-Bike")}
														</a>
													</Link> :
													getCategoryBySlug(bike, "e-trekking") ?
														<Link href={`/${t("categories")}${t("/trekking-e-bikes")}`}>
															<a className={"btn btn-primary btn-lg d-grid"}>
																{t("Alle Modelle Trekking E-Bike")}
															</a>
														</Link> :
														getCategoryBySlug(bike, "accessories") ?
															<Link href={`/${t("categories")}${t("/zubehor")}`}>
																<a className={"btn btn-primary btn-lg d-grid"}>
																	{t("Zubehor")}
																</a>
															</Link> :
															null
											}
										</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Modal isOpen={bookAppointment} closeModal={setBookAppointment.bind(null, false)}>
			<BookAppointmentModal />
		</Modal>
		<Modal isOpen={bikeSuggest} closeModal={setBikeSuggest.bind(null, false)}>
			<SuggestBikeModal setBikeSuggest={setBikeSuggest}/>
		</Modal>
	</>
	)
}

SameCategoryBikes.propTypes = {
	t: PropTypes.any,
	bike: PropTypes.any
}

export default SameCategoryBikes
