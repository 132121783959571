import React from "react"
import PropTypes from "prop-types"
import FaqItem from "./FaqItem"

type Props = {
    faqData: any,
	referral?: boolean
	translation?: Function
}
const FaqItemList: React.FC<Props> = ({faqData, referral, translation}) => {
	return (
		<>
			{referral ? (
				<section className="">
					<div className="accordion accordion-flush row g-3" id="accordionFlush">
						{faqData.map(element => {
							const {id, title, text} = element
							return <FaqItem key={id} title={translation(title)} text={translation(text)} referral/>
						})}
					</div>
				</section>
			) : (
				<section className="container py-5">
					<div className="accordion accordion-flush row g-3" id="accordionFlush">
						{faqData.map(element => {
							const {id, title, text} = element
							return <FaqItem key={id} id={id} title={title} text={text} />
						})}
					</div>
				</section>

			)}
		</>
	)
}

FaqItemList.propTypes = {
	faqData: PropTypes.any,
	referral: PropTypes.bool,
	translation: PropTypes.func
}

export default FaqItemList

