import {brands} from "./TopBrands"
import {topCategoriesMegaMenuMBP} from "./TopCategories"
import {fields, initialValues, validationSchema} from "../components/forms/OtherInstancesNewsLetter"
import {filterUrl} from "../components/filter/FilterUrl"

const myBabyPlanInstanceId = process.env.NEXT_PUBLIC_FORM_MYBABYPLAN
const myMusicPlanInstanceId = process.env.NEXT_PUBLIC_FORM_MYMUSICPLAN

export const heights = [
	{title: "145-150cm", slug: "145-150-cm"},
	{title: "150-155cm", slug: "150-155-cm"},
	{title: "155-160cm", slug: "155-160-cm"},
	{title: "160-165cm", slug: "160-165-cm"},
	{title: "165-170cm", slug: "165-170-cm"},
	{title: "170-175cm", slug: "170-175-cm"},
	{title: "175-180cm", slug: "175-180-cm"},
	{title: "180-185cm", slug: "180-185-cm"},
	{title: "185-190cm", slug: "185-190-cm"},
	{title: "190-195cm", slug: "190-195-cm"},
	{title: "195-200cm", slug: "195-200-cm"}
]

export const tabItems = [
	{
		href: process.env.NEXT_PUBLIC_ORGANIZATION ? "https://sbb.mybikeplan.ch/" : "https://www.mybikeplan.ch/",
		iconSrc: "/assets/icons/mbp.svg",
		mobileIconSrc: "/assets/icons/bike-nobg.svg",
		title: "MyBikePlan",
		primaryColor: "#FEC04D",
		bgColor: "#f9f3ec",
		checkmarkSrc: "/assets/icons/checkmark.svg",
		// When we provide a Null megaTitle it means that we will now show a dropDown when hovering this element
		megaTitle: "Alle E-Bikes", // Always required if you want a dropdown
		megaMenuTopBrands: brands, // Only required when StoreFront main megamenu has top brands in it
		megaMenuTopCategories: topCategoriesMegaMenuMBP,
		heights
	},
	{
		href: process.env.NEXT_PUBLIC_ORGANIZATION ? "https://sbb.myhomeplan.ch/" : "https://www.myhomeplan.ch/",
		iconSrc: "/assets/icons/mhp.svg",
		mobileIconSrc: "/assets/icons/sofa.svg",
		title: "MyHomePlan",
		primaryColor: "#6E9060",
		bgColor: "#e9f5e4",
		checkmarkSrc: "/assets/icons/checkmark.svg",
		megaTitle: null,
		megaMenuTopBrands: null,
		megaMenuTopCategories: null,
		heights: null
	},
	{
		href: null,
		iconSrc: "/assets/icons/MyBabyPlan.svg",
		mobileIconSrc: "/assets/icons/baby_bottle.png",
		title: "MyBabyPlan",
		primaryColor: "#fac1bb",
		bgColor: "#f8f8f8",
		ComingSoon: true,
		checkmarkSrc: "/assets/icons/iconCategories/mail-add.svg",
		newsletterFormData: {
			title: "MyBabyPlan",
			fields,
			validationSchema,
			initialValues,
			config: {
				formId: process.env.NEXT_PUBLIC_NEWSLETTER_FORM_ID_MYBABYPLAN,
				instanceId: {
					de: myBabyPlanInstanceId,
					fr: myBabyPlanInstanceId
				},

				oploadFile: false
			},
			type: "newCustomer",
			newInstance: true
		},
		description: "Bald findest Du hier unseren neuen Shop mit tollen Produkten für Babys und Kleinkinder. Melde Dich für die E-Mail-Liste an, um benachrichtigt zu werden, sobald wir online gehen!"
	},
	{
		href: null,
		iconSrc: "/assets/icons/MyMusicPlan.svg",
		mobileIconSrc: "/assets/icons/baby_bottle.png",
		title: "MyMusicPlan",
		primaryColor: "#8A6552",
		bgColor: "#f8f8f8",
		ComingSoon: true,
		checkmarkSrc: "/assets/icons/mail-add.svg",
		newsletterFormData: {
			title: "MyMusicPlan",
			fields,
			validationSchema,
			initialValues,
			config: {
				formId: process.env.NEXT_PUBLIC_NEWSLETTER_FORM_ID_MYBABYPLAN,
				instanceId: {
					de: myMusicPlanInstanceId,
					fr: myMusicPlanInstanceId
				},
				uploadFile: false
			},
			type: "newCustomer",
			newInstance: true
		},
		description: "Bald findest Du hier unseren neuen Shop mit tollen Musikinstrumenten. Melde Dich für die E-Mail-Liste an, um benachrichtigt zu werden, sobald wir online gehen!"
	}

]

export const content = {
	sbb: {
		companyName: "SBB",
		companyIcon: "/assets/icons/SBB-logo.svg",
		loginPopup: {
			logo: "/assets/icons/sbb.svg",
			title: "log_benefits",
			subTitle: "login_popup_subtitle",
			bulletPoints: ["login_popup_bulltepoint1", "login_popup_bulltepoint2", "login_popup_bulltepoint3"]
		},
		banner: {
			bgColor: "#ed0200",
			textColor: "white",
			logo: "/assets/icons/sbb_logo.svg",
			header: "for_sbb_employees",
			subHeader: "for_sbb_employees_subheader"
		},
		stickyBar: {
			text: "Herzlich willkommen, SBB-Mitarbeiter/in!",
			buttonCTA: "Möbel entdecken!",
			buttonLink: t => filterUrl([], null, null, t)
		}
	}
}

export const otherInstancesCategoryTitles = [
	"Alle E-Bikes", "All E-Bikes"
]

export const getMyBikePlanUrl = (height:string = "-", category:string = "-", brand:string = "-") => {
	let baseUrl
	switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
		case "development":
		case "preview":
		case "local":
			baseUrl = "https://staging.mybikeplan.ch"
			break
		case "production":
			if (process.env.NEXT_PUBLIC_ORGANIZATION) {
				baseUrl = "https://sbb.mybikeplan.ch"
				break
			}

			baseUrl = "https://www.mybikeplan.ch"
			break
		default:
			baseUrl = "https://www.mybikeplan.ch"
			break
	}

	return `${baseUrl}/alle-modelle-e-bike-schweiz/kategorie/${category}/rahmenvarianten/-/batterie-kapazitat/-/max-gewicht/-/zubehor/-/geschwindigkeit/-/marke/-/motor-marke/${brand}/farbe/-/koerpergroesse/${height}/preisbereich/-/verfugbar/-/suchen/-/sortieren/preis/relevanz/-`
}

