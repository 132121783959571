import React from "react"
import PropTypes from "prop-types"
import Typography, {Variants} from "../../components/reusable/Typography/Typography"
import RichTextHyperlink from "./RichTextHyperlink"

type Props = {
  content: any,
  textAlign?: string,
  style?: any,
  isMobile?: boolean,
  variant?: keyof typeof Variants
  className?: string,
}

const RichTextParagraph = ({content, style, isMobile, textAlign, variant = "bodyLg", className = "mb-4"}: Props) => {
	return (
		<div style={style} className={className}>
			{content.map((item, idx) => {
				const {marks, value} = item
				const lines = (value || "").split("\n")
				const htmlValue = lines.join("<br/>")
				switch (item.nodeType) {
					case "text":
						return (
						// Marks.length === 0 ? <Typography key={idx} variant="bodySm" semanticTag="span" htmlContent={htmlValue}/> :

							marks.find(mark => mark.type === "bold") ?
								<Typography key={idx} semanticTag="span" variant={`${variant}Bold` as keyof typeof Variants}
									htmlContent={htmlValue}/> :
								<Typography key={idx} semanticTag={`${isMobile ? "p" : "span"}`} style={{textAlign}} variant={variant}
									htmlContent={htmlValue}/>
						)
					case "hyperlink":
						return <RichTextHyperlink key={idx} item={item}/>
					default:
						return value
				}
			})
			}
		</div>
	)
}

RichTextParagraph.propTypes = {
	content: PropTypes.any.isRequired

}

export default RichTextParagraph
