import React, {useContext} from "react"
import Button from "../../reusable/components/Button"
import {useTranslation} from "next-i18next"
import {ArrowRight, ExclamationCircle} from "react-bootstrap-icons"
import {StockStatus} from "../BikeDetails"
import Typography from "../../reusable/Typography/Typography"
import CheckoutContext from "../../../context/CheckoutContext"
import {colors} from "../../../colors/Colors"
import {customPlanLengthMap} from "../plan/customPlanLengthMap"

interface Props {
  disabled: boolean
  isLoading: boolean
  handleClick: () => void
  handleSizeClick: () => void
  stockStatus?: string
  isSecondHand?: boolean
  secondHandPrice?: number
  price?: number
  bikeId?: number
  formatedPrice?: string
}

export const CheckoutBtn = ({
	disabled,
	isLoading,
	handleSizeClick,
	handleClick,
	stockStatus = StockStatus.inStock,
	isSecondHand,
	secondHandPrice,
	price,
	bikeId,
	formatedPrice
}: Props) => {
	const {t} = useTranslation(["common", "productDescrPage"])
	const {data} = useContext(CheckoutContext)
	const checkoutData = data
	const label = stockStatus === StockStatus.inStock ? t("In den Warenkorb") :
		stockStatus === StockStatus.onBackOrder ? t("checkout-btn-pre-order") :
			t("In den Warenkorb")
	const resultingPrice = (isSecondHand ? secondHandPrice : price) - (checkoutData?.downPayment ? Number(checkoutData?.downPayment[bikeId] || 0) : 0)
	const {primary, white} = colors

	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const correctPlanLength = data?.planLength ? planLengthMap ? planLengthMap[data?.planLength] : data?.planLength : 48

	return (
		<div className="row py-2">
			<div className="col-12">
				{disabled ?
					<a onClick={handleSizeClick} href="#size" style={{backgroundColor: "#777777", height: 64}}
						className="text-decoration-none text-white d-flex justify-content-center align-items-center rounded p-3">
						<ExclamationCircle size={22}/>
						<span style={{fontSize: 20}} className="text-bold text-uppercase ms-3">
							{t("fixed-btn-select-size", {ns: "productDescrPage"})}
						</span>
					</a> :
					<Button bgColor={primary} textColor={white} color={white} paddingY="0" className="w-100 data-cypress-checkout-btn"
						spinnerColor={white}
						label={label}
						high
						disabled={disabled} isLoading={isLoading}
						onClick={handleClick}
					>
						<div className="row align-items-center justify-content-between text-start">
							{checkoutData?.planLength > 1 ?
								<div className="col-auto">
									<Typography variant={"heading1Black"} semanticTag="span">CHF</Typography>
									<Typography variant={"heading1Black"} className="ms-1" semanticTag="span" style={{fontSize: 36}}>
										{`${Math.ceil(resultingPrice / (correctPlanLength))}`}
									</Typography>
									<Typography variant={"heading1Black"} semanticTag="span" className="ms-1">
										{`${t("/m")}`}
									</Typography>
									{!isSecondHand && (<Typography variant="bodySm" semanticTag="div" className={"text-start col-12 text-capitalize mb-2"}>
										{`${t("Gesamtpreis")} - ${formatedPrice}`}
									</Typography>)}
								</div> :
								<div className="col-auto">
									<Typography variant={"heading1Black"} semanticTag="span">CHF</Typography>
									<Typography variant={"heading1Black"} className="ms-1" semanticTag="span" style={{fontSize: 36}}>
										{price}
									</Typography>
								</div>}
							<div className={"col-auto"}>
								<ArrowRight/>
							</div>
						</div>
					</Button>
				}
			</div>
		</div>
	)
}
