import React, {useContext} from "react"
import PropTypes from "prop-types"
import CheckoutContext from "../../../context/CheckoutContext"
import {useTranslation} from "next-i18next"
import Typography from "../../reusable/Typography/Typography"
import {customPlanLengthMap} from "../../bikeDetails/plan/customPlanLengthMap"

type Props = {
  price: number,
  onSale: boolean,
  regularPrice: number,
  salePrice: number,
  accessorie?: boolean,
  badgeOnly?: boolean,
  isSecondHand?: boolean,
  secondHandPrice?: number
  isRowForMobile?: boolean
  isAccessoryPrice?: boolean
  priceFontSize?: number
  customInstanceDiscountPrice?:number
}

const Price: React.FC<Props> = ({
	price,
	onSale,
	salePrice,
	badgeOnly,
	isSecondHand,
	secondHandPrice,
	isRowForMobile,
	isAccessoryPrice,
	priceFontSize = 36,
	customInstanceDiscountPrice
}) => {
	const {data} = useContext(CheckoutContext)
	const {t} = useTranslation()

	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = data?.planLength ? planLengthMap ? planLengthMap[data?.planLength] : data?.planLength : 48

	return (
		<>
			<div className={"row g-2 align-items-center"}>
				<div className={"col-12 col-md-12 mt-0"}>
					<div className={"row align-items-center g-0"}>
						<div className={"col-12"}>
							{customInstanceDiscountPrice ?
								<>
									<div className={"col-12 d-block d-md-none"} style={{color: "#E61C45"}}>
										<Typography variant="bodyXSmBold" semanticTag="span">
                      CHF
										</Typography>
										<Typography variant="heading1Black" semanticTag="h1"
											className={"mx-1 mb-0 d-inline-block"}
											style={{fontSize: isAccessoryPrice ? 20 : priceFontSize}}>
											{Math.ceil((customInstanceDiscountPrice / planLength))}
										</Typography>
										{planLength !== 1 && <Typography variant="bodyXSmBold" semanticTag="span">
											{`${t("pro Monat")}`} x {planLength}
										</Typography>}
									</div>
									<div className={"col-12 d-none d-md-block"} style={{color: "#E61C45"}}>
										<Typography variant="bodyXSmBold" semanticTag="span">
                      CHF
										</Typography>
										<Typography variant="heading1Black" semanticTag="h1"
											className={`d-inline-block  mb-0 p-2 pb-0 px-md-1 ${isRowForMobile ? "" : "ps-3 pe-3"} text-size-12`}>
											{`${Math.ceil(customInstanceDiscountPrice / planLength)}`}
										</Typography>
										{planLength !== 1 && <Typography variant="bodyXSmBold" semanticTag="span">
											{`${t("pro Monat")}`} x {planLength}
										</Typography>}
									</div>
								</> : onSale ?
									<>
										<div className={"col-12 d-block d-md-none"} style={{color: "#E61C45"}}>
											<Typography variant="bodyXSmBold" semanticTag="span">
                      CHF
											</Typography>
											<Typography variant="heading1Black" semanticTag="h1"
												className={"mx-1 mb-0 d-inline-block"}
												style={{fontSize: isAccessoryPrice ? 20 : priceFontSize}}>
												{Math.ceil(data && planLength ? (salePrice / planLength) : price / 36)}
											</Typography>
											{planLength !== 1 && <Typography variant="bodyXSmBold" semanticTag="span">
												{`${t("pro Monat")}`} x {planLength}
											</Typography>}
										</div>
										<div className={"col-12 d-none d-md-block"} style={{color: "#E61C45"}}>
											<Typography variant="bodyXSmBold" semanticTag="span">
                      CHF
											</Typography>
											<Typography variant="heading1Black" semanticTag="h1"
												className={`d-inline-block  mb-0 p-2 pb-0 px-md-1 ${isRowForMobile ? "" : "ps-3 pe-3"} text-size-12`}>
												{`${Math.ceil(data && planLength ? (salePrice / planLength) : price / planLength)}`}
											</Typography>
											{planLength !== 1 && <Typography variant="bodyXSmBold" semanticTag="span">
												{`${t("pro Monat")}`} x {planLength}
											</Typography>}
										</div>
									</> :
									isSecondHand ?
										<div className={"col-12"}>
											<Typography variant="bodyXSmBold" semanticTag="span">
                      CHF
											</Typography>
											<Typography variant="heading1Black" semanticTag="h1"
												className={`d-block d-md-flex badge border border-2 border-danger text-danger p-2 p-md-2 ${isRowForMobile ? "" : "ps-3 pe-3"} text-size-12`}>
												{Math.ceil(data && planLength <= 12 ? (secondHandPrice / planLength) : secondHandPrice / planLength)}
											</Typography>
											{planLength !== 1 && <Typography variant="bodyXSmBold" semanticTag="span">
												{`${t("pro Monat")}`} x {planLength}
											</Typography>}
										</div> :
										<div className={"col-12 px-0 "}>
											<Typography variant="bodyXSmBold" semanticTag="span">
                      CHF
											</Typography>
											<Typography variant="heading1Black" semanticTag="h1"
												className={"mx-1 mb-0 d-inline-block"}
												style={{fontSize: isAccessoryPrice ? 20 : priceFontSize}}>
												{Math.ceil(data && planLength >= 12 ? (price / planLength) : price / planLength)}
											</Typography>
											{planLength !== 1 && <Typography variant="bodyXSmBold" semanticTag="span">
												{`${t("pro Monat")}`} x {planLength}
											</Typography>}
										</div>
							}
						</div>
						{badgeOnly && <div className={"col-auto"}>
							{planLength !== 1 && <small className={"d-inline d-md-none text-xs ms-2"}>
								{` / ${t("pro Monat")}`}
							</small>}
						</div>}
					</div>

				</div>
			</div>
		</>
	)
}

Price.propTypes = {
	price: PropTypes.number,
	onSale: PropTypes.bool,
	regularPrice: PropTypes.number,
	salePrice: PropTypes.number
}

export default Price
