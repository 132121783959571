/* eslint-disable complexity */
import React, {useContext, useEffect} from "react"
import slugify from "slugify"
import Typography from "../reusable/Typography/Typography"
import AccordionAnimation from "../reusable/components/AnimationComponent/AccordionAnimation"
import AccordionHeaderWithPlusIcon from "./AccordionHeaderWithPlusIcon"
import {useRouter} from "next/router"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import LayoutContext from "../../context/LayoutContext"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import ComparisonCheckboxButton from "../bikesCollection/BikesCollectionItem/ComparisonCheckboxButton"
import {downloadBikePDFfromAWS, findBikeMetaFieldByName} from "../../utility/Helper"
import {s3BucketNames} from "../../utility/aws/s3BucketNames"

type CustomHeaderProps = {
  title: string
  isActive: boolean
  onClick: () => void
}

type Props = {
    title: string
    slug?: string
    active?: boolean
    onToggle?: (title: string) => void
    multiple?: boolean,
    children: React.ReactNode
    grey?: boolean
    plusIcon?: boolean
    accItemsClasses?: string
    customHeader?: (props: CustomHeaderProps) => React.ReactElement
    titleContent?: React.ReactElement | null
    bodyClasses?: string
    activeHighlighted?: boolean
    noShadow?: boolean
    noDividerBeforeBody?: boolean
		bike?: any
		isForPDP?: boolean
}

const AccordionCustomItem = ({
	title,
	slug,
	active,
	onToggle,
	multiple,
	children,
	grey,
	plusIcon,
	accItemsClasses,
	customHeader,
	bodyClasses,
	activeHighlighted,
	titleContent,
	noShadow,
	noDividerBeforeBody,
	bike,
	isForPDP
}: Props): React.ReactElement => {
	const [visibility, setVisibility] = React.useState(active)
	const isActive = multiple ? visibility : active
	const {isMobile} = useContext(LayoutContext)
	const router = useRouter()
	const {pathname} = router
	const {reactGA} = useContext(AnalyticsContext)
	const {t} = useTranslation(["common"])
	const pdfKey = findBikeMetaFieldByName(bike, "pdf-key") || null
	const [bikeSlugForCompare, setBikeSlugForCompare] = React.useState("")

	useEffect(() => {
		if (bike?.slug) {
			setBikeSlugForCompare(bike?.slug)
		}
	}, [bike?.slug])

	const toggleVisibility = () => {
		setVisibility(visibility => !visibility)
		onToggle(slugify(slug || title).toLowerCase())

		if (pathname.includes("produkt") && !visibility) {
			reactGA?.event({
				category: gaCategories.pdp,
				action: gaEvents.descriptionSection,
				label: title,
				nonInteraction: false
			})
		}
	}

	const handleClick = () => {
		downloadBikePDFfromAWS(s3BucketNames.pdfBucket, pdfKey)
	}

	const className = `aaccordion-flush shadow-none  ${!isActive && "collapsed"}`
	const wrapClasses = `accordion-item ${noShadow ? "shadow-0" : "shadow-sm"} border-bottom overflow-hidden border-0 ${grey ? "bg-grey" : ""} ${accItemsClasses ? accItemsClasses : ""}`

	const isActiveItem = router.query.tab === slugify(title).toLocaleLowerCase()

	useEffect(() => {
		if (isActiveItem) {
			setVisibility(true)
		}
	}, [isActiveItem])

	return (
		<>
			{process.env.NEXT_PUBLIC_STOREFRONT === "myhomeplan" && title === "Youtube Videos" ?
				null :
				<div className={wrapClasses}>
					{customHeader && customHeader({title, isActive, onClick: toggleVisibility})}
					<div className="d-md-none">
						{customHeader ? null : plusIcon ?
							<AccordionHeaderWithPlusIcon isActive={isActive} title={title} onClick={toggleVisibility}/> :
							<div onClick={toggleVisibility} className={`accordion-header pointer py-3 px-3 d-flex justify-content-between bg-white ${className}`}>
								{titleContent && !isMobile ? titleContent :
									<Typography variant="bodyLgBold" semanticTag="span" style={{color: "#212529"}}>
										{title}
									</Typography>}

								{isActive ? <Image style={{transform: "rotate(180deg)"}} src={"/assets/icons/chevron-down.svg"} height={15} width={15}/> :
									<Image src={"/assets/icons/chevron-down.svg"} height={15} width={15}/>}
							</div>}
					</div>
					<div className="d-none d-md-block">
						{customHeader ? null :
							<div onClick={toggleVisibility} className={`accordion-header pointer py-3 ${title === "Einzelheiten" ? "ps-3" : "px-3"}  d-flex align-items-center justify-content-between bg-white ${className}`}>
								<div className={`${title === "Einzelheiten" ? "col-5" : "col-12"}  d-flex align-items-center`}>
									<div className="bg-primary rounded-circle d-flex justify-content-center align-items-center col-12 me-3" style={{width: 24, height: 24}}>
										{isActive ? <Image style={{transform: "rotate(180deg)"}} src={"/assets/icons/chevron-down-white.svg"} height={6.5} width={12}/> :
											<Image src={"/assets/icons/chevron-down-white.svg"} height={6.5} width={12}/>}
									</div>
									{titleContent ? titleContent :
										<Typography variant="bodyLgBold" semanticTag="span">
											{title}
										</Typography>}
								</div>
								{title === "Einzelheiten" ? <div className="d-flex">
									{pdfKey && <div className="d-flex py-2 px-3 me-4 justify-content-center align-items-center" onClick={handleClick} style={{background: "#F8F8F8", borderRadius: 16}}>
										<Image src={"/assets/icons/file-pdf.svg"} height="24" width="24"/>
										<Typography className="ms-3" variant="bodyLgBold">{t("Download overview")}</Typography>
									</div>}

									<ComparisonCheckboxButton bgColor={"#F8F8F8"} textBold borderRadius="16px" slug={bikeSlugForCompare}/>
								</div> : ""}
							</div>}
					</div>
					<div className="overflow-hidden py-0 d-none d-md-block">
						<AccordionAnimation inProp={isActive} customHeight={1200} transTimingFn={"ease-in-out"} duration={600}>
							<div>
								<div
									className={bodyClasses ? bodyClasses : `${title === "Übersicht" ? "p-0" : "p-0 px-3"} accordion-body  ${noDividerBeforeBody ? "" : `${!isMobile && "border-top"}`} my-2 mx-2`}
									style={{backgroundColor: "#fff", borderRadius: 16}}>{children}</div>
							</div>
						</AccordionAnimation>
					</div>
					<div className="overflow-hidden py-0 d-md-none">
						<AccordionAnimation inProp={isActive} customHeight={1200} transTimingFn={"ease-in-out"} duration={600}>
							<div
								className={bodyClasses ? bodyClasses : `accordion-body p-0 px-3  ${noDividerBeforeBody ? "" : `${!isMobile && "border-top"}`} my-2 mx-2`}
								style={{backgroundColor: "#fff", borderRadius: 16, maxWidth: title === "Übersicht" ? 568 : ""}}>{children}</div>
						</AccordionAnimation>
					</div>
				</div>
			}
		</>
	)
}

export default AccordionCustomItem
