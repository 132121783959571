export enum FacebookPixelEvents {
    AddToCart = "AddToCart",
    Purchase = "Purchase",
    ViewContent = "ViewContent",
    InitiateCheckout = "InitiateCheckout"
}

export enum Currencies {
    CHF = "CHF"
}

export enum ContentTypes {
    product = "product"
}

export const fbEvents = {
	addToCart: "AddToCart",
	addToWishlist: "AddToWishlist",
	completeRegistration: "CompleteRegistration",
	initiateCheckout: "InitiateCheckout",
	pageView: "PageView",
	purchase: "Purchase",
	search: "Search",
	subscription: "Subscription",
	lead: "Lead",
	contact: "Contact"
}
