import {useTranslation} from "next-i18next"
import React, {useContext, useState} from "react"
import Button from "../reusable/components/Button"
import CustomerQuestionModal from "../reusable/modals/CustomerQuestionModal"
import Typography from "../reusable/Typography/Typography"
import CustomerQuestionItem from "./CustomermerQuestionItem"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import Image from "next/image"

interface Props {
  bike: any
  selectedVariation: any,
  questions: any[],
  isButtonDesign?: boolean
}

const CustomerQuestionsSection: React.FC<Props> = ({bike, selectedVariation, questions, isButtonDesign = true}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const sku = selectedVariation ? selectedVariation?.sku : bike?.sku ? bike?.sku : "0000000"
	const name = bike?.name
	const {reactGA} = useContext(AnalyticsContext)

	const closeModal = (): void => {
		setIsModalOpen(false)
	}

	const openModal = (): void => {
		setIsModalOpen(true)
	}

	const {t} = useTranslation()

	return (
		<>
			{isButtonDesign ?
				<div className="my-3">
					<div className="row mt-4 mt-lg-5 mb-4">
						{questions ?
							questions.length < 1 ?
								<div className="col-12 py-3">
									<Typography className="mb-2" semanticTag="p"
										variant={"bodyLgBold"}>{`${t("do-you-have-a-question?")} ${bike.name}?`}</Typography>
									<Typography semanticTag="p" variant={"bodyLg"}>{t("ask-our-experts")}</Typography>
								</div> :
								questions.map((question, i) => {
									const {firstName, lastName} = question
									return <CustomerQuestionItem key={firstName + lastName + i} question={question}/>
								}) :
							<div className="col-12 text-danger">
								<Typography semanticTag="span" variant={"bodyLg"}>{"Error Fetching questions"}</Typography>
							</div>}
					</div>
					<Button
						className="col-12 col-md-auto mb-3"
						high
						outline
						textColor="#212529"
						hoverColor="#212529"
						color="#fff"
						label={t("ask_your_question")}
						onClick={() => {
							openModal()

							reactGA?.event({
								category: gaCategories.pdp,
								action: gaEvents.askYourQuestion,
								label: gaEvents.askYourQuestion,
								nonInteraction: false
							})
						}}
					/>
				</div> :
				<div className="pb-2 px-3 d-flex col-12 flex-column align-items-center text-center" style={{
					background: "#FFFFFF",
					border: "4px solid #F8F8F8",
					borderRadius: "16px",
					paddingTop: 12,
					minHeight: 100

				}} onClick={() => {
					openModal()

					reactGA?.event({
						category: gaCategories.pdp,
						action: gaEvents.askYourQuestion,
						label: gaEvents.askYourQuestion,
						nonInteraction: false
					})
				}}>
					<Image className="mt-1" src="/assets/icons/person.svg" height={20} width={18}/>
					<Typography className="mt-2" variant="bodySm">{t("ask_your_question")}</Typography>
				</div>
			}
			<CustomerQuestionModal name={name} sku={sku} isModalOpen={isModalOpen} closeModal={closeModal}/>
		</>
	)
}

export default CustomerQuestionsSection
