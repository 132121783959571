import Typography from "../../reusable/Typography/Typography"
import React from "react"
import {useTranslation} from "next-i18next"
import Button from "../../reusable/components/Button"
import Image from "next/image"
import BookAppointmentButton from "../../reusable/ModalButtons/BookAppointmentButton"
import {colors} from "../../../colors/Colors"

interface Props {
	imageSrc: string
	title: string
	description: string
	btnLabel?: string
	btnIconSrc?: string
	btnHref?: string
	isConsultationBtn?: boolean
	titleWidth?: string
}

const FindPerfectFurnitureCard: React.FC<Props> = ({imageSrc, title, description, btnLabel, btnIconSrc, isConsultationBtn, btnHref, titleWidth}) => {
	const {t} = useTranslation()
	const {white, primary} = colors
	return (
		<div className="col-12 info-card p-3 pb-4" style={{backgroundColor: "#F8F8F8"}}>
			<div className="col-12 info-card-image position-relative">
				<Image src={imageSrc} layout={"fill"} objectFit={"cover"} />
				<Typography variant="heading1Black" className="position-absolute text-white info-card-title pe-2" style={{width: titleWidth}}>{title}</Typography>
			</div>
			<div className="info-card-text d-flex flex-column align-items-baseline align-items-center mt-4">
				<Typography variant={"bodyLg"} semanticTag="span" style={{minHeight: 70}}>{description}</Typography>

				<div className="col-12 col-md-auto mt-3">
					{isConsultationBtn ?
						<BookAppointmentButton
							high
							fontSizeSmall
							textColor={white}
							bgColor={primary}
							icon={<Image src={"/assets/icons/phone.svg"} width={20} height={20} />}
							color={white}
							padding={"2px 0px"}
							label={t("Beratung Buchen")} iconFirst/> :
						<Button
							target="_blank"
							href={btnHref}
							fontSizeSmall
							bgColor={primary}
							textColor={white} color={white} className="w-100">
							<Image src={btnIconSrc} width={20} height={20} objectFit="contain"/>
							<Typography semanticTag="span" variant={"bodySmBlack"} className="ms-2" style={{padding: "2px 0"}}>{btnLabel}</Typography>
						</Button> }
				</div>
			</div>
		</div>
	)
}

export default FindPerfectFurnitureCard
