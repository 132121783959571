import React from "react"
import PropTypes from "prop-types"
import {findBikeMetaFieldByName} from "../../utility/Helper"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import {StockStatus} from "./BikeDetails"
import {useRouter} from "next/router"
import {toContentfulLocales} from "../../contentful/toContentfulLocales"
import Button from "../reusable/components/Button"
import urlParams from "../../utility/UrlParams"
import {filterUrl} from "../filter/FilterUrl"
import styles from "./bikeDetails.module.scss"
import Image from "next/image"

type Props = {
  selectedVariation: any
  isOutOfStock: boolean
}

const BoxBikeInfo: React.FC<Props> = ({selectedVariation, isOutOfStock}) => {
	const {t} = useTranslation()
	const bikerHeight = findBikeMetaFieldByName(selectedVariation, "biker_height")
	const router = useRouter()

	const bikerHeightValues = (bikerHeight && bikerHeight.length) ? bikerHeight?.split("-").map(item => item.split("").filter(item => !isNaN(Math.floor(item)))).map(item => item.join("").trim())
		.map(item => {
			// If bikerHeight end not 0 or 5 like height filters
			const arrOfNumbers = item.split("")
			const lastElement = arrOfNumbers[arrOfNumbers.length - 1]
			if (lastElement !== ("0" || "5")) {
				arrOfNumbers.splice(arrOfNumbers.length - 1, 1, "5")
			}

			return arrOfNumbers.join("")
		}) : []

	const bikerHeightForFilterUrl = bikerHeightValues?.reduce((acc, heightValues) => {
		const heightValuesToNumber = Math.floor(heightValues)
		if (heightValuesToNumber < 190 && heightValuesToNumber >= 155) {
			acc.push(`${heightValuesToNumber}-${heightValuesToNumber + 5}-cm`)
		} else if (heightValuesToNumber >= 190) {
			acc.push("190-195-cm")
			return acc
		} else if (heightValuesToNumber < 155) {
			acc.push("155-160-cm")
		}

		return acc
	}, [])

	const url = filterUrl({[urlParams.bikerHeight]: (bikerHeightForFilterUrl.length ? [...bikerHeightForFilterUrl] : ["/-"])}, null, null, t)

	const getMonth = dateString => {
		const date = new Date(dateString)

		// Get next month
		const month = date.getDate() > 15 ? date.getMonth() === 11 ? 0 : date.getMonth() + 1 : date.getMonth()
		date.setMonth(month)

		return date.toLocaleString(toContentfulLocales[router.locale], {month: "long"})
	}

	return (
		<>
			{selectedVariation ? (
				<div className="px-3 mt-2 pb-3">
					<div className="row">
						{bikerHeight ? (
							<>
								<div className="col-12">
									<div className="row g-3">
										<div className="col-auto">
											<img src={"/assets/icons/biker-height-icon.svg"} alt="person icon"/>
											<img src={"/assets/icons/tape-line-icon.svg"} alt="arrow icon"/>
										</div>
										<div className="col-auto">
											<Typography variant="bodySmMdBodyLg" semanticTag="span">
												{bikerHeight}
											</Typography>
										</div>
									</div>
								</div>
							</>
						) : null}
						{!isOutOfStock && <> <div className="col-12 mt-2">
							<div className="row g-3 d-flex">
								<div className="col-auto">
									<img src={"/assets/icons/calendar.svg"} alt="calendar icon" width={20} height={20}/>
								</div>
								<div className="col-10">
									{selectedVariation?.stock_status === StockStatus.inStock ?
										<>
											<Typography variant="bodySmMdBodyLg" semanticTag="span">
												{t("10-days-delivery")}
											</Typography>
											<Typography className="mt-1" variant="bodyXSmMdBodyLgBold"
												semanticTag="div"
												style={{color: "#6C7074"}}>{t("Die Lieferfrist kann sich ändern")}</Typography>
										</> : selectedVariation?.stock_status === StockStatus.onBackOrder ?
											<>
												{selectedVariation?.erpStockInfo.isDropshipping ?
													<Typography variant="bodySmMdBodyLg" semanticTag="span">
														{t(selectedVariation?.erpStockInfo.deliveryTime)}
													</Typography> : selectedVariation?.erpStockInfo.deliveryUntil ? <Typography variant="bodySmMdBodyLg" semanticTag="span">
														{t(getMonth(selectedVariation?.erpStockInfo.deliveryUntil))}
													</Typography> : null}
												<Typography className="mt-1" variant="bodyXSmMdBodyLgBold"
													semanticTag="div"
													style={{color: "#6C7074"}}>{t("Die Lieferfrist kann sich ändern")}</Typography>
											</> : null}
								</div>
							</div>
						</div>
						<div className="col-12 mt-2">
							<div className="row g-3 d-flex">
								<div className="col-auto">
									<Image src={"/assets/icons/number-icon.svg"} alt="number icon" width={20} height={20}/>
								</div>
								<div className="col-10">
									<Typography variant={"bodySm"} semanticTag={"span"} className={"text-secondary"}>
										Art. {selectedVariation.sku}
									</Typography>
								</div>
							</div>
						</div> </>}

						{isOutOfStock && <>
							<Typography className="mt-2" variant="bodySmMdBodyLg" semanticTag="span">
								{t("currently-unavailable")}
							</Typography>

							<Button
								paddingX="0"
								isNoUppercase
								icon={<img src="/assets/icons/chevron-right-bg-grey.svg"/>}
								iconOnHover={<img src="/assets/icons/chevron-right-bg-grey.svg"/>}
								color=""
								spinnerColor="#000"
								href={url}
								className={`rounded-3 d-flex ps-2 ms-1 mt-3 reset col-auto ${styles.similarSizeButton}`}
								opacity={1}
							>
								<Typography className="me-2" style={{fontSize: "16px"}} variant="bodyLgBold">Verfügbare Möbel in dieser Grösse
								</Typography>
							</Button>
						</>}

					</div>
				</div>
			) : null}
		</>
	)
}

BoxBikeInfo.propTypes = {
	selectedVariation: PropTypes.any
}

export default BoxBikeInfo
