import React, {useContext} from "react"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import Button from "../reusable/components/Button"
import {colors} from "../../colors/Colors"
import Typography from "../reusable/Typography/Typography"

type Props = {
 onlyIcon?: boolean
	onlyText?: boolean
	textVariant?: any
	classes?: string
}

const WhatsAppButton: React.FC<Props> = ({onlyIcon, onlyText, textVariant, classes}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	const {white, primary} = colors
	const link = "http://api.whatsapp.com/send?phone=41435051318"

	const handleGAEvent = () => {
		reactGA?.event({
			category: gaCategories.consultation,
			action: gaEvents.whatsAppButtonTextUs,
			label: gaEvents.whatsAppButtonTextUs,
			nonInteraction: false
		})
	}

	return (
		<React.Fragment>
			{onlyIcon ? <Link href={link}>
				<a target={"_blank"} className="btn shadow-none" onClick={() => handleGAEvent()}>
					<img src={"/assets/icons/whats-app-button-black-icon.svg"} />
				</a>
			</Link> :
				<Button onClick={() => handleGAEvent()}
					className={`shadow-none ${classes}`}
					target="_blank"
					href={link}
					fontSizeSmall color={white}
					textColor={white}
					bgColor={primary}
				>
					{!onlyText &&
					<img className="mb-1" src="/assets/icons/whats-app-button-white-icon.svg"/>}
					<Typography className="ms-2" semanticTag="span" variant={textVariant ? textVariant : "bodyLgBold"}>Schreib uns</Typography>
				</Button>
			}
		</React.Fragment>
	)
}

export default WhatsAppButton

