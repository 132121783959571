/* eslint-disable camelcase */
import React, {useContext} from "react"
import Typography from "../../reusable/Typography/Typography"
import {getResultingOnSalePrice} from "../../../utility/helpers/getResultingOnSalePrice"
import CartContext from "../../../context/CartContext"
import CheckoutContext from "../../../context/CheckoutContext"
import {instanceDiscounts} from "../../../config/instance"
import {customPlanLengthMap} from "./customPlanLengthMap"

export enum SaleTypes {
	sale_10 = "sale_10",
	sale_20 = "sale_20",
	fixed = "fixed"
}

interface Props {
	setBikePrice?: (arg0: number) => void
    t: (arg0: string) => string
    isMobile: boolean
    isOpen: boolean
    payOneTime: boolean
    durationInMonth: (string|number)[]
	isOnSale?: boolean
	isSecondHand?: boolean
	initialPrice?: number
	isCheckoutPage?: boolean
	saleType?: keyof typeof SaleTypes
	hidePayOneTimeLabel?:boolean

}

const OnSaleDurationLabels:React.FC<Props> = ({
	t,
	isMobile,
	isOpen,
	payOneTime,
	durationInMonth,
	isOnSale,
	isSecondHand,
	initialPrice,
	isCheckoutPage,
	setBikePrice,
	hidePayOneTimeLabel,
	saleType
}) => {
	const redColor = "#E61C45"
	const {items, updateItem} = useContext(CartContext)
	const {data, setData} = useContext(CheckoutContext)
	const checkoutData = data
	const setCheckoutData = setData
	// Labels for sale that can be change using category Sale_10 or Sale_20
	const saleLabels = saleType => {
		if (saleType === SaleTypes.sale_10) {
			return {
				"pay-one-time": "-20%",
				12: "-10%",
				24: "-7.5%",
				36: "-5%",
				48: null
			}
		}

		if (saleType === SaleTypes.sale_20) {
			return {
				"pay-one-time": "-20%",
				12: "-10%",
				24: "-7.5%",
				36: "-5%",
				48: null
			}
		}

		return {"pay-one-time": "-20%",
			12: "-10%",
			24: "-7.5%",
			36: "-5%",
			48: null}
	}

	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]

	return (
		<>

			<div
				className={`row g-0 g-2 mt-2 my-md-0 pb-1 justify-content-between ${isMobile && `accordion-collapse collapse ${isOpen ? "show" : null}`}`}>
				{durationInMonth.map((month, i) => {
					if (month === "pay-one-time" && (!payOneTime || hidePayOneTimeLabel)) {
						return
					}

					return (<div
						cypress-slider-label-to-select={`label-${i}`}
						key={i} style={{
							borderRadius: 16,
							cursor: "pointer"
						}} className={"col-2 mt-2 plan-length-label-container text-center"}
						onClick={() => {
							setCheckoutData(prevData => {
								return ({
									...prevData,
									deals: prevData.deals.map((deal, i) => {
										if ((isOnSale && !isSecondHand) ||
										(deal.isOnSale && !isSecondHand)) {
											const item = items.find(item => item.uniqueId === deal.uniqueId)

											if (item) {
												updateItem(item, {
													...item,
													product: {
														...item.product,
														price: getResultingOnSalePrice(isOnSale ? initialPrice ? initialPrice : deal.initialPrice : deal.initialPrice, month === "pay-one-time" ? 0 : month, saleType)
													}
												})
											}

											return ({
												...deal,
												e_bike_price: getResultingOnSalePrice(isOnSale ? initialPrice ? initialPrice : deal.initialPrice : deal.initialPrice, month === "pay-one-time" ? 0 : month, saleType)
											})
										}

										return deal
									}),
									planLength: month === "pay-one-time" ? 1 : month
								})
							})

							if (isOnSale && !isSecondHand && !isCheckoutPage && setBikePrice) {
								setBikePrice(getResultingOnSalePrice(initialPrice, month === "pay-one-time" ? 0 : month, saleType))
							}
						}}>
						<Typography style={{color: checkoutData?.planLength === 1 ? "grey" : ""}} variant={checkoutData?.planLength === month ? "bodySmBold" : "bodySm"}>
							{t(`${month === "pay-one-time" ? month : planLengthMap[month]}`)}
						</Typography>
					</div>)
				}
				)}
			</div>
			<div
				className={`row g-0 my-0  pb-1 justify-content-between ${isMobile && `accordion-collapse collapse ${isOpen ? "show" : null}`}`}>
				{durationInMonth.map((month, i) => {
					if (month === "pay-one-time" && (!payOneTime || hidePayOneTimeLabel)) {
						return
					}

					return (<div key={i} style={{
						borderRadius: 16,
						cursor: "pointer"
					}} className={"col-2 mt-2 plan-length-label-container text-center"}
					onClick={() => {
						setCheckoutData(prevData => {
							return ({
								...prevData,
								deals: prevData.deals.map((deal, i) => {
									if ((isOnSale && !isSecondHand) ||
										(deal.isOnSale && !isSecondHand)) {
										const item = items.find(item => item.uniqueId === deal.uniqueId)

										updateItem(item, {
											...item,
											product: {
												...item.product,
												price: getResultingOnSalePrice(isOnSale ? initialPrice : deal.initialPrice, month, saleType)
											}
										})
										return ({
											...deal,
											e_bike_price: getResultingOnSalePrice(isOnSale ? initialPrice : deal.initialPrice, month, saleType)
										})
									}

									return deal
								}),
								planLength: month === "pay-one-time" ? 1 : month
							})
						})

						if (isOnSale && !isSecondHand && !isCheckoutPage && setBikePrice) {
							setBikePrice(getResultingOnSalePrice(initialPrice, month === "pay-one-time" ? 0 : month, saleType))
						}
					}}>
						{process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE &&
						<Typography
							variant={(checkoutData?.planLength === month) ||
								(checkoutData?.planLength === 1 && month === "pay-one-time") ? "bodySmBold" : "bodySm"}
							className={`${(checkoutData?.planLength === month) ||
								(checkoutData?.planLength === 1 && month === "pay-one-time") ?
								"text-white badge border-radius-8" :
								""} `}
							style={{
								backgroundColor: (checkoutData?.planLength === month) ||
								(checkoutData?.planLength === 1 && month === "pay-one-time") ? redColor : null,
								color: checkoutData?.planLength === 1 ? "grey" : redColor
							}}
						>
							{t(`${instanceDiscounts[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE][saleType][month]?.label ? instanceDiscounts[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE][saleType][month]?.label : ""}`)}
						</Typography>}
					</div>)
				}
				)}
			</div>
		</>
	)
}

export default OnSaleDurationLabels
