import React, {useContext, useEffect} from "react"
import {useTranslation} from "next-i18next"
import {CheckLg} from "react-bootstrap-icons"
import {useRouter} from "next/router"
import Typography from "../reusable/Typography/Typography"
import LayoutContext from "../../context/LayoutContext"

const BookAppointmentModal: React.FC = () => {
	const {t} = useTranslation()
	const {locale} = useRouter()
	const {isMobile} = useContext(LayoutContext)
	useEffect(() => {
		// Define config
		// @ts-ignore
		const scriptTag = document.createElement("script")
		scriptTag.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
		document.body.appendChild(scriptTag)
	}, [])
	return (
		<div className={`container p-0 ${isMobile ? "w-100" : "overflow-hidden"}`}>
			<div style={{maxHeight: "80vh"}} className={"row justify-content-center align-items-center"}>
				<div className={"col-12"}>
					<div className={"bg-white"} onClick={e => {
						e.stopPropagation()
					}}>
						<div className={`row bg-white position-relative ${isMobile ? "rounded" : ""}`}>
							<div className="col-12 col-lg-7 d-flex flex-column justify-content-center mt-1 ms-1 ms-md-0 ps-4 ps-md-5">
								<Typography className="mb-0 mt-3" variant="heading2" semanticTag="h2">
									{t("Kostenlose Beratung buchen")}fgh
								</Typography>
								<ul className={"row mt-2 mb-3 pb-3 justify-content-start list-unstyled"}>
									<li className="mb-1 d-flex"><CheckLg className="mt-1" size={16}/><Typography className="ms-2" variant="bodyLg" semanticTag="span">{t("Free consultation")}</Typography></li>
									<li className="mb-1 d-flex"><CheckLg className="mt-1" size={16}/><Typography className="ms-2" variant="bodyLg" semanticTag="span">{t("It will take 15-30 minutes")}</Typography></li>
									<li className="d-flex"><CheckLg className="mt-1" size={16}/><Typography className="ms-2" variant="bodyLg" semanticTag="span">{t("You will find which e-bike is perfect for you!")}</Typography></li>
								</ul>
							</div>
							{!isMobile && <Typography style={{top: "90%", left: 40}} className="position-absolute text-uppercase" variant="bodyLgBold" semanticTag="div">{t("Abbrechen")}</Typography>}
							<div style={{maxHeight: isMobile ? 620 : "100%", transform: isMobile ? "scale(1)" : "scale(1) translate(0px, 12px)"}} className="col-12 col-lg-5 overflow-hidden">

								<div className="row meetings-iframe-container d-flex justify-content-center"
									data-src={t("link-meeting-consultation")}/>
							</div>
							{isMobile && <Typography className="pt-1 mt-1 pb-3 text-uppercase text-center" variant="bodyLgBold" semanticTag="div">{t("Abbrechen")}</Typography>}
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

export default BookAppointmentModal
