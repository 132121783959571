import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../Typography/Typography"
import {X} from "react-bootstrap-icons"
import LayoutContext from "../../../context/LayoutContext"
import SizeNotificationForm from "../../forms/SizeNotificationForm"

type Props = { closeModal: () => void }

const SizeNotificationModalContent: React.FC<Props> = ({closeModal}) => {
	const {t} = useTranslation()
	const {isMobile} = useContext(LayoutContext)
	return (
		<div
			style={{maxWidth: isMobile ? "91vw" : 520}} className="container-md bg-white pt-0 px-0 position-relative rounded">

			<div onClick={() => closeModal()}
				className={"position-absolute bg-white rounded-circle top-0 end-0"} style={{
					marginTop: "4%",
					marginRight: "3%",
					zIndex: 1
				}}>
				<X size={30} color={"black"}/>
			</div>

			<div className="row position-relative mt-1 mt-md-3 mx-0 justify-content-between">

				<div
					className="col-12 col-md-12 bg-white pt-4 ps-lg-5 py-md-5 d-flex justify-content-center align-items-center">
					<div className="container px-0 pe-md-5 pb-4 pb-lg-0">

						<div className="row mb-md-2">
							<Typography className="col-12 mb-2 mb-lg-4" variant={`${isMobile ? "heading3" : "heading3"}`}
								semanticTag="h2">{t("size_notification_modal_title")}</Typography>
							<Typography className="col-12 mb-4" variant="bodyLg"
								semanticTag="h3">{t("unavailable_size_box_text")}</Typography>
						</div>
						<SizeNotificationForm/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SizeNotificationModalContent
