import React from "react"
import PropTypes from "prop-types"

type Props = {
    children: React.ReactNode,
    next: boolean
    action: () => void
	className?: string
}

const NavButton: React.FC<Props> = ({children, next = true, action, className}) => {
	return (
		<button style={{zIndex: 1}} type={"button"} className={`btn position-absolute ${next ? "end-0" : "start-0"} ${className ? className : ""}`}
			onClick={e => {
				e.preventDefault()
				e.stopPropagation()
				action()
			}}>
			{children}
		</button>
	)
}

NavButton.propTypes = {
	children: PropTypes.node.isRequired,
	next: PropTypes.bool.isRequired,
	action: PropTypes.func.isRequired
}

export default NavButton
